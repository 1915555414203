import {
  useQuery,
  hashQueryKey,
  QueryClient,
  QueryClientProvider as QueryClientProviderBase,
  useInfiniteQuery,
  useMutation,
} from "react-query";
import { persistQueryClient } from 'react-query/persistQueryClient-experimental'
import { createWebStoragePersistor } from 'react-query/createWebStoragePersistor-experimental'
import { ReactQueryDevtools } from "react-query/devtools";
import {
  getFirestore,
  onSnapshot,
  doc,
  collection,
  query,
  where,
  orderBy,
  startAfter,
  limit,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  increment,
  decrement,
  addDoc,
  deleteDoc,
  Timestamp,
  serverTimestamp,
  runTransaction,
  arrayRemove,
  arrayUnion,
  documentId
} from "firebase/firestore";
import {getFunctions, httpsCallable} from 'firebase/functions';
import { firebaseApp } from "./firebase";
import { apiRequest } from "./util";
import featuredImages from "./featuredImages";
import { getStorage, ref, uploadBytes, uploadBytesResumable, getDownloadURL  } from "firebase/storage";
import { POST_TYPE } from "./enums";
import chunk from "lodash/chunk";
import { toast } from "react-toastify";
import { StreakIcon } from "./icons";

// Initialize Firestore
const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);
const functions = getFunctions(firebaseApp, 'us-central1');

const searchCallable = httpsCallable(
  functions,
  'ext-firestore-vector-search-queryCallable'
);


// React Query client
const localStoragePersistor = createWebStoragePersistor({storage: window.localStorage})

const client = new QueryClient()
// persistQueryClient({
//   queryClient: client,
//   persistor: localStoragePersistor,
//   dehydrateOptions: {
//     shouldDehydrate: query => { 
//       const persist = ['ideas'].includes(query.queryKey) 
//       console.log('Persist ', query.queryKey, persist)
//       return persist
//     }
//   }
// })

/**** USERS ****/

// Subscribe to user data
// Note: This is called automatically in `auth.js` and data is merged into `auth.user`
export function useUser(uid) {
  // Manage data fetching with React Query: https://react-query.tanstack.com/overview
  return useQuery(
    // Unique query key: https://react-query.tanstack.com/guides/query-keys
    ["user", { uid }],
    // Query function that subscribes to data and auto-updates the query cache
    createQuery(() => doc(db, "users", uid)),
    // Only call query function if we have a `uid`
    { enabled: !!uid }
  );
}

// Fetch user data once (non-hook)
// Useful if you need to fetch data from outside of a component
export function getUser(uid) {
  return getDoc(doc(db, "users", uid)).then(format);
}

// Create a new user
export function createUser(uid, data) {
  return setDoc(doc(db, "users", uid), data, { merge: true });
}

// Update an existing user
export function updateUser(uid, data) {
  return updateDoc(doc(db, "users", uid), data);
}

export function getRoast(id) {
  return getDoc(doc(db, "roasts", id)).then(format);
}

export function updateSaveNoteStat(user, count=1, streak=true) {
  let newData = {
    "stats.savedNotesCount": increment(count),
    "stats.lastNoteDate": serverTimestamp()
  }
  if(streak){

    const today = new Date();
    const lastNoteDate = user?.stats?.lastNoteDate?.toDate?.() ?? new Date('01/01/2024');
    const noteStreakCount = user?.stats?.notesStreakCount ?? 0
    const bestNotesStreakCount = user?.stats?.bestNotesStreakCount ?? 0

    // if user has not increase the streak yet today, increase streakCount
    if(today.getDate() !== lastNoteDate.getDate()){
      newData["stats.notesStreakCount"] = increment(1)

      toast(`${(user?.stats?.notesStreakCount ?? 0) + 1} day streak of saving your thoughts!`, {
        icon: <StreakIcon color='primary'/>,
      })
      
      // if streakCount is greater than bestStreakCount, update bestStreakCount
      if((noteStreakCount + 1) > bestNotesStreakCount){
        newData["stats.bestNotesStreakCount"] = noteStreakCount + 1
      }
    }
  }

  return updateDoc(doc(db, "users", user.uid), newData);
}

export function updateGenPostStat(user, count=1, streak=false) {
  let newData = {
    "stats.generatedPostsCount": increment(count),
    "stats.lastPostDate": serverTimestamp()
  }
  // if(streak){

  //   const today = new Date();
  //   const lastPostDate = user?.stats?.lastNoteDate?.toDate() ?? new Date('01/01/2024');
  //   const postStreakCount = user?.stats?.notesStreakCount ?? 0
  //   const bestpostsStreakCount = user?.stats?.bestPostsStreakCount ?? 0

  //   // if user has not increase the streak yet today, increase streakCount
  //   if(today.getDate() !== lastPostDate.getDate()){
  //     newData["stats.postsStreakCount"] = increment(1)

  //     toast(`${(user?.stats?.postsStreakCount ?? 0) + 1} day streak of posting!`, {
  //       icon: <StreakIcon color='primary'/>,
  //     })
      
  //     // if streakCount is greater than bestStreakCount, update bestStreakCount
  //     if((postStreakCount + 1) > bestNotesStreakCount){
  //       newData["stats.bestPostsStreakCount"] = postStreakCount + 1
  //     }
  //   }
  // }

  return updateDoc(doc(db, "users", user.uid), newData);
}
/**** STORAGE ****/
export async function uploadImageFromURL (imageUrl, storagePath) {
  try {
    // Fetch the image from the URL
    const response = await fetch(imageUrl);
    const blob = await response.blob();

    // Upload the blob to Firebase Storage
    const storageRef = ref(storage, storagePath);
    const uploadTask = uploadBytes(storageRef, blob);

    // Wait for the upload to complete
    await uploadTask;

    // Get the download URL of the uploaded image
    const downloadURL = await getDownloadURL(storageRef );

    console.log('Image uploaded and URL obtained:', downloadURL);
    return downloadURL;
  } catch (error) {
    console.error('Error fetching, uploading, or obtaining URL:', error);
    throw error;
  }
}

/**** MESSAGES ****/
export function useMessage(threadId, messageId) {
  return useQuery(
    ["message", threadId, messageId],
    () => getDoc(doc(db, "threads", threadId, "messages", messageId)).then(doc => doc.data()),
    { enabled: !!threadId && !!messageId }
  );
}

export function useMessageOnce(threadId, messageId) {
  return useQuery(
    ["message", threadId, messageId],
    () => getDoc(doc(db, "threads", threadId, "messages", messageId)).then(doc => doc.data()),
    { enabled: !!threadId && !!messageId }
  );
}

export function useMessagesByThread(threadId, limit=50) {
  return useQuery(
    ["messages", threadId],
    () => query(
      collection(db, "threads", threadId, "messages"),
      orderBy("createdAt", "asc"),
      limit(limit)
    ),
    { enabled: !!threadId }
  );
}

export function createPost(data) {
  // Create the message in the specified (or new) thread
  return addDoc(collection(db, "posts"), {
    ...data,
    createdAt: serverTimestamp(),
  });
}

export function updatePost(data) {
  return updateDoc(doc(db, "posts", data.id), {
    ...data,
    updatedAt: serverTimestamp(),
  });
}

export function likePost(postId){
  return updatePost({id: postId, liked: true})
}

export function unlikePost(postId){
  return updatePost({id: postId, liked: false})
}


export function deletePost (postId) {
  return deleteDoc(doc(db, "posts", postId));
}

//fetch liked posts of users where the posts has liked = true, no need to page it, just limit it, use query

// fetch posts of user where liked = true. No need to page it. Just limit it
function fetchLikedPosts(ownerId, types) {
  return async () => {
    const querySnapshots = await Promise.all(types.map(type => {
      const q = query(collection(db, "posts"), where("owner", "==", ownerId), where("type", "==", type), where("liked", "==", true), orderBy("createdAt", "desc"), limit(6))
      return getDocs(q).then(snapshot => ({ type: type, snapshot: snapshot }));
    }))


   const items = {}
   querySnapshots.forEach(({type, snapshot}) => {
     items[type] = []
     snapshot.forEach((doc) => {
       items[type].push({
         id: doc.id,
         ...doc.data(),
       });
     });
    });

    return items

    // return {
    //   items,
    //   nextCursor: querySnapshot.docs.length === 0 ? null : querySnapshot.docs[querySnapshot.docs.length - 1],
    // };
  };
}


//fetch liked posts of each type
export function useLikedPostsByType(ownerId, types = Object.keys(POST_TYPE)) {
  return useQuery(["likedPosts", ownerId, types], fetchLikedPosts(ownerId, types), { enabled: !!ownerId });
}



// use liked posts once
export function useLikedPosts(ownerId) {
  return useQuery(["likedPosts", ownerId], fetchLikedPosts(ownerId), { enabled: !!ownerId });
}



function fetchPagedPosts(owner, noteId, filters = {}, sortBy = 'createdAt') {
  return async ({ pageParam = new Date() }) => {
    let baseQuery = query(collection(db, "posts"), 
                          where("owner", "==", owner), 
                          orderBy(sortBy, "desc"), 
                          startAfter(pageParam), 
                          limit(6));

    // Conditionally apply the noteId filter
    if (noteId) {
      baseQuery = query(collection(db, "posts"),
                        where("owner", "==", owner),
                        where("noteId", "==", noteId),
                        orderBy(sortBy, "desc"),
                        startAfter(pageParam),
                        limit(6))
    }

    // Apply filters from the filters object, including a special case for tags
    Object.keys(filters).forEach(filterKey => {
      if (filters[filterKey]) {
        if (filterKey === 'tags') {
          // Use `array-contains` for the tags filter
          baseQuery = query(baseQuery, where(filterKey, "array-contains", filters[filterKey]));
        } else {
          // Apply other filters normally
          baseQuery = query(baseQuery, where(filterKey, "==", filters[filterKey]));
        }
      }
    });

    const querySnapshot = await getDocs(baseQuery);

    const items = [];
    querySnapshot.forEach((doc) => {
      items.push({ id: doc.id, ...doc.data() });
    });

    return {
      items,
      nextCursor: querySnapshot.docs.length === 0 ? null : querySnapshot.docs[querySnapshot.docs.length - 1].data()[sortBy],
    };
  };
}

export function usePagedPostsByOwner(owner, filters, sortBy) {
  return useInfiniteQuery(["posts", owner, filters, sortBy], fetchPagedPosts(owner, undefined, filters, sortBy), {
    getNextPageParam: (lastPage) => lastPage.nextCursor,
  });
}

export function usePagedPostByNoteId(owner, noteId) {
  return useInfiniteQuery(["posts", owner, noteId], fetchPagedPosts(owner, noteId), {
    getNextPageParam: (lastPage) => lastPage.nextCursor,
  });
}


export function useNoteOnce(noteId) {
  return useQuery(
    ["note", noteId],
    () => getDoc(doc(db, "notes", noteId)).then(doc => doc.data()),
    { enabled: !!noteId }
  );
}

export async function createNote(data) {
  // Create the message in the specified (or new) thread
  try {
    const docRef = await addDoc(collection(db, "notes"), {
      ...data,
      createdAt: serverTimestamp(),
    });
    return docRef.id; // Return the ID of the newly created note
  } catch (e) {
    console.error("Error adding document: ", e);
    return null; // Handle the error appropriately
  }
}

export function updateNote(data) {
  return updateDoc(doc(db, "notes", data.id), {
    ...data,
    updatedAt: serverTimestamp(),
  });
}

export function deleteNote (noteId) {
  return deleteDoc(doc(db, "notes", noteId));
}

// function fetchPagedNotes(owner){
//   return async ({ pageParam = new Date() }) => {
//     const q = query(collection(db, "notes"),where("owner", "==", owner), orderBy("createdAt", "desc"), startAfter(pageParam), limit(6))
//     const querySnapshot = await getDocs(q);

//     const items = [];
//     querySnapshot.forEach((doc) => {
//       items.push({
//         id: doc.id,
//         ...doc.data(),
//       });
//     });

//     return {
//       items,
//       nextCursor: querySnapshot.docs.length === 0 ? null : querySnapshot.docs[querySnapshot.docs.length - 1],
//     };
//   };
// }

function fetchPagedNotes(owner, filters, sortBy){
  return async ({ pageParam = new Date() }) => {
    let baseQuery = query(collection(db, "notes"), 
                          where("owner", "==", owner), 
                          orderBy(sortBy, "desc"), 
                          startAfter(pageParam), 
                          limit(6));

    // Apply filters from the filters object, including a special case for tags
    Object.keys(filters).forEach(filterKey => {
      if (filters[filterKey]) {
        if (filterKey === 'tags') {
          // Use `array-contains` for the tags filter
          baseQuery = query(baseQuery, where(filterKey, "array-contains", filters[filterKey]));
        } else {
          // Apply other filters normally
          baseQuery = query(baseQuery, where(filterKey, "==", filters[filterKey]));
        }
      }
    });

    const querySnapshot = await getDocs(baseQuery);

    const items = [];
    querySnapshot.forEach((doc) => {
      items.push({ id: doc.id, ...doc.data() });
    });

    return {
      items,
      nextCursor: querySnapshot.docs.length === 0 ? null : querySnapshot.docs[querySnapshot.docs.length - 1].data()[sortBy],
    };
  };
}

export function usePagedNotesByOwner(owner, filters={}, sortBy='createdAt') {
  return useInfiniteQuery(["notes", owner, filters, sortBy], fetchPagedNotes(owner, filters, sortBy), {
    getNextPageParam: (lastPage) => lastPage.nextCursor,
  });
}

// export function usePagedNotesByOwner(owner) {
//   return useInfiniteQuery(["notes", owner], fetchPagedNotes(owner), {
//     getNextPageParam: (lastPage) => lastPage.nextCursor,
//   });
// }


export async function createMessage(userId, threadId, data) {
  let usedThreadId = threadId;
  console.log('Creating messages..')

  // If threadId is null, create a new thread first
  if (!threadId) {
    const newThreadRef = await addDoc(collection(db, "threads"), {
      // Add any default or necessary data for a new thread
      owner: userId,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      // Example: title: 'New Thread', createdBy: 'userId'
      // You would replace 'userId' with the actual ID of the user creating the thread
    });
    usedThreadId = newThreadRef.id; // Use the newly created thread's ID
    console.log('created New Thread ', usedThreadId)
  }

  // Create the message in the specified (or new) thread
  const messageRef = await addDoc(collection(db, "threads", usedThreadId, "messages"), {
    ...data,
    owner: userId,
    createdAt: serverTimestamp(),
  });

  console.log('Messages created')

  // Return both the threadId and messageId for further use
  return { threadId: usedThreadId, messageId: messageRef.id };
}

export function updateMessage(threadId, messageId, data) {
  return updateDoc(doc(db, "threads", threadId, "messages", messageId), data);
}

export function deleteMessage(threadId, messageId) {
  return deleteDoc(doc(db, "threads", threadId, "messages", messageId));
}

// This returns a function
export function fetchPagedMessages(threadId, { pageParam = null }) {
  return async () => {
    const q = query(
      collection(db, "threads", threadId, "messages"),
      orderBy("createdAt", "asc"),
      startAfter(pageParam || new Date('2000')),
      limit(20)
    );
    const querySnapshot = await getDocs(q);

    const messages = [];
    querySnapshot.forEach((doc) => {
      messages.push({
        id: doc.id,
        ...doc.data(),
      });
    });

    return {
      messages,
      nextCursor: querySnapshot.docs.length === 0 ? null : querySnapshot.docs[querySnapshot.docs.length - 1],
    };
  };
}

export function usePagedMessages(threadId) {
  return useInfiniteQuery(["messages", threadId], fetchPagedMessages(threadId), {
    getNextPageParam: (lastPage) => lastPage.nextCursor,
  });
}

function fetchPagedThreads(userId) {
  // if(!userId) {
  //   const threads = []
  //   return {
  //     threads,
  //     nextCursor: null
  //   }
  // }

  return async ({ pageParam = new Date() }) => {
    console.log(userId)
    const q = query(
      collection(db, "threads"),
      where("owner", "==", userId), // Assuming threads have a 'createdBy' field
      orderBy("createdAt", "desc"),
      startAfter(pageParam),
      limit(15)
    );
    const querySnapshot = await getDocs(q);

    const threads = [];
    querySnapshot.forEach((doc) => {
      threads.push({
        id: doc.id,
        ...doc.data(),
      });
    });

    return {
      threads,
      nextCursor: querySnapshot.docs.length === 0 ? null : querySnapshot.docs[querySnapshot.docs.length - 1],
    };
  };
}

export function usePagedThreads(userId = '') {
  return useInfiniteQuery(["threads", userId], fetchPagedThreads(userId), {
    getNextPageParam: (lastPage) => lastPage.nextCursor,
  });
}

export function useMessagesMutation(func, threadId) {
  return useMutation(func, {
    onSuccess: () => {
      // Invalidate both messages within the thread and the thread list to ensure fresh data
      client.invalidateQueries(['messages', threadId]);
      client.invalidateQueries('threads');
    },
  });
}

/**** ITEMS ****/
/* Example query functions (modify to your needs) */

// Subscribe to item data
export function useItem(id) {
  return useQuery(
    ["item", { id }],
    createQuery(() => doc(db, "items", id)),
    { enabled: !!id }
  );
}

// Fetch item data once
export function useItemOnce(id) {
  return useQuery(
    ["item", { id }],
    // When fetching once there is no need to use `createQuery` to setup a subscription
    // Just fetch normally using `getDoc` so that we return a promise
    () => getDoc(doc(db, "items", id)).then(format),
    { enabled: !!id }
  );
}

// Subscribe to all items by owner
export function useItemsByOwner(owner) {
  return useQuery(
    ["items", { owner }],
    createQuery(() =>
      query(
        collection(db, "items"),
        where("owner", "==", owner),
        orderBy("createdAt", "desc")
      )
    ),
    { enabled: !!owner }
  );
}

// Create a new item
export function createItem(data) {
  return addDoc(collection(db, "items"), {
    ...data,
    createdAt: serverTimestamp(),
  });
}

// Update an item
export function updateItem(id, data) {
  return updateDoc(doc(db, "items", id), data);
}


export function likeItem(itemId){
  return apiRequest('like', "POST", {itemId: itemId})
}

// Delete an item
export function deleteItem(id) {
  return deleteDoc(doc(db, "items", id));
}

export function useItemsMutation(func){
  return useMutation(func, {
    onSuccess: () => {
      client.invalidateQueries('items');
    },
  });
}

function fetchPagedItems(owner, isSubscriber){
  
    
  return async ({ pageParam = new Date() }) => {
    let q = query(collection(db, "items"), where(documentId(), "in", featuredImages))
    if(isSubscriber){
      q = query(collection(db, "items"), where("isPrivate", "==", false), orderBy("createdAt", "desc"), startAfter(pageParam), limit(6))
    } else {
    }
    if (owner && !Array.isArray(owner)){
      q = query(collection(db, "items"),where("owner", "==", owner), orderBy("createdAt", "desc"), startAfter(pageParam), limit(6))
    }
    const querySnapshot = await getDocs(q);

    const items = [];
    querySnapshot.forEach((doc) => {
      items.push({
        id: doc.id,
        ...doc.data(),
      });
    });

    return {
      items,
      nextCursor: querySnapshot.docs.length === 0 ? null : querySnapshot.docs[querySnapshot.docs.length - 1],
    };
  };
}

export function usePagedItemsByOwner(owner) {

  return useInfiniteQuery(["items", owner], fetchPagedItems(owner), {
    getNextPageParam: (lastPage) => lastPage.nextCursor,
  });
}

function fetchPagedItemsLiked(likes){
  return async ({ pageParam = 0 }) => {
    const items = [];
    for (let i = pageParam; i < Math.min(pageParam+6, likes?.length || 0); i++) {
      const itemId = likes[i]
      const documentRef = doc(db, "items", itemId);
      const item = await getDoc(documentRef).then(format);
      if (item) {
        items.push(item);
      }
    }

    return {
      items,
      nextCursor:items.length === 0 ? null : pageParam+6,
    };
  };
}

export function usePagedItemsLiked(likes) {

  return useInfiniteQuery(["items", likes], fetchPagedItemsLiked(likes), {
    getNextPageParam: (lastPage) => lastPage.nextCursor,
  });
}

export function usePagedItems(isSubscriber=false) {

  return useInfiniteQuery("items", fetchPagedItems(null, isSubscriber), {
    getNextPageParam: (lastPage) => lastPage.nextCursor,
  });
}

export async function search(ownerId, query, limit=3, prefilters=[]){
  /**
   * Sample argument
  {
    query: "my query",
    limit: 4,
    prefilters: [
        {
            field: "age",
            operator: "==",
            value: 30
        }
    ]
  }

  return valie
  { ids: [ 'asdasd' ]}
   */
  if (limit === 0 || !query) return {ids: []}

  prefilters.push({field: 'owner', operator: '==', value: ownerId})
  try{

    const result = await searchCallable({query, limit, prefilters})
    return result.data
  }
  catch(e) {
    console.error(e)
  }
}

/**** HELPERS ****/

//convert date to firebase timestamp
export function toTimestamp(date){
  return Timestamp.fromDate(date)
}

// Store Firestore unsubscribe functions
const unsubs = {};

function createQuery(getRef) {
  // Create a query function to pass to `useQuery`
  return async ({ queryKey }) => {
    let unsubscribe;
    let firstRun = true;
    // Wrap `onSnapshot` with a promise so that we can return initial data
    const data = await new Promise((resolve, reject) => {
      unsubscribe = onSnapshot(
        getRef(),
        // Success handler resolves the promise on the first run.
        // For subsequent runs we manually update the React Query cache.
        (response) => {
          const data = format(response);
          if (firstRun) {
            firstRun = false;
            resolve(data);
          } else {
            client.setQueryData(queryKey, data);
          }
        },
        // Error handler rejects the promise on the first run.
        // We can't manually trigger an error in React Query, so on a subsequent runs we
        // invalidate the query so that it re-fetches and rejects if error persists.
        (error) => {
          if (firstRun) {
            firstRun = false;
            reject(error);
          } else {
            client.invalidateQueries(queryKey);
          }
        }
      );
    });

    // Unsubscribe from an existing subscription for this `queryKey` if one exists
    // Then store `unsubscribe` function so it can be called later
    const queryHash = hashQueryKey(queryKey);
    unsubs[queryHash] && unsubs[queryHash]();
    unsubs[queryHash] = unsubscribe;

    return data;
  };
}

// Automatically remove Firestore subscriptions when all observing components have unmounted
client.queryCache.subscribe(({ type, query }) => {
  if (
    type === "observerRemoved" &&
    query.getObserversCount() === 0 &&
    unsubs[query.queryHash]
  ) {
    // Call stored Firestore unsubscribe function
    unsubs[query.queryHash]();
    delete unsubs[query.queryHash];
  }
});

// Format Firestore response
function format(response) {
  // Converts doc into object that contains data and `doc.id`
  const formatDoc = (doc) => ({ id: doc.id, ...doc.data() });
  if (response.docs) {
    // Handle a collection of docs
    return response.docs.map(formatDoc);
  } else {
    // Handle a single doc
    return response.exists() ? formatDoc(response) : null;
  }
}

// React Query context provider that wraps our app
export function QueryClientProvider(props) {
  return (
    <QueryClientProviderBase client={client}>
      {props.children}
      {/* <ReactQueryDevtools initialIsOpen={true} /> */}
    </QueryClientProviderBase>
  );
}
