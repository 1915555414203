import React, { useState, useEffect } from "react";
import Navbar from "./../components/Navbar";
import IndexPage from "./index";
import GeneratorPaintPage from "./generatorPaint";
import GeneratorEditPage from "./generatorEdit";
import GeneratorShortsPage from "./generatorShorts";
import IdeasPage from "./ideas";
import OnboardingForm from "../components/OnboardingForm";
import ChatPage from "./chat";
import PricingPage from "./pricing";
import DashboardPage from "./dashboard";
import ItemPage from "./item";
import AuthPage from "./auth";
import SettingsPage from "./settings";
import LegalPage from "./legal";
import AboutPage from "./about";
import { Switch, Route, Router } from "./../util/router";
import PurchasePage from "./purchase";
import FirebaseActionPage from "./firebase-action";
import NotFoundPage from "./404";
import Footer from "./../components/Footer";
import "./../util/analytics";
import { AuthProvider } from "./../util/auth";
import { ThemeProvider } from "./../util/theme";
import { QueryClientProvider } from "./../util/db";
import FAQPage from "./faq";
import GalleryPage from "./gallery";
import ContactPage from "./contact";
import CreditPage from "./credit";
import NoteListPage from "./noteList";
import NotePage from "./note";
import PostListPage from "./postList";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CalendarPage from "./calendar";
import VerifyEmailPage from "./verifyEmail";
import LinkedinCallbackPage from "./linkedin-callback";
import RoastPage from "./roast";
import LinkedinUnwrappedPage from "./linkedin-unwrapped";
import LinkedinMonthlyStatsPage from "./linkedin-monthly-stats";
import StatsPage from "./stats";

function App(props) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 768);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  
  return (
    <QueryClientProvider>
      <ThemeProvider>
        <AuthProvider>
          <Router>
            <>
              <Navbar
                color="default"
                logo="/logo.webp"
                logoInverted="/logo.webp"
              />
              <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
              />
              <Switch>
                {/* <Route exact path="/" component={IndexPage} /> */}
                {/* <Route exact path="/"render={(props) => <ChatPage {...props} isMobile={isMobile} />} /> */}

                <Route exact path="/" render={(props) => <NoteListPage {...props} isMobile={isMobile} />} />
                <Route exact path="/tia" render={(props) => <NoteListPage {...props} isMobile={isMobile} />} />

                <Route exact path="/posts/" render={(props) => <PostListPage {...props} isMobile={isMobile} />} />

                <Route exact path="/explore/" render={(props) => <IdeasPage {...props} isMobile={isMobile} />} />
                
                <Route exact path="/calendar/" render={(props) => <CalendarPage {...props} isMobile={isMobile} />} />

                <Route exact path="/notes/new" render={(props) => <NotePage {...props} isMobile={isMobile} />} />

                <Route exact path="/notes/:id" render={(props) => <NotePage {...props} isMobile={isMobile} />} />
                
                {/* <Route exact path="/create" component={GeneratorPaintPage} /> */}

                <Route exact path="/onboarding" component={OnboardingForm} />

                <Route exact path="/chat" component={ChatPage} />

                {/* <Route exact path="/video" component={GeneratorShortsPage} /> */}

                <Route exact path="/pricing" component={PricingPage} />
                
                <Route exact path="/credit" component={CreditPage} />


                <Route exact path="/faq" component={FAQPage} />
                
                <Route exact path="/about" component={AboutPage} />

                <Route exact path="/contact" component={ContactPage} />

                <Route exact path="/dashboard" component={DashboardPage} />

                <Route exact path="/roastme/:id" component={RoastPage} />
                <Route exact path="/roastme" component={RoastPage} />

                <Route exact path="/unwrapped/:id" component={LinkedinUnwrappedPage} />
                <Route exact path="/unwrapped" component={LinkedinUnwrappedPage} />

                <Route exact path="/stats" component={StatsPage} />
                <Route exact path="/stats/:year/:month" component={LinkedinMonthlyStatsPage} />


                <Route exact path="/auth/verify-email" component={VerifyEmailPage} />
                <Route exact path="/auth/:type" component={AuthPage} />
                

                <Route
                  exact
                  path="/settings/:section"
                  component={SettingsPage}
                />

                <Route exact path="/legal/:section" component={LegalPage} />

                <Route exact path="/purchase/:plan" component={PurchasePage} />

                <Route
                  exact
                  path="/firebase-action"
                  component={FirebaseActionPage}
                />

                <Route
                  exact
                  path="/linkedin-callback"
                  component={LinkedinCallbackPage}
                />

                <Route component={NotFoundPage} />
              </Switch>

              {/* <Footer
                bgColor="default"
                size="medium"
                bgImage=""
                bgImageOpacity={1}
                description="AI assistant helping creators scale their online presence."
                copyright={`© ${new Date().getFullYear()} Company`}
                logo="/cleve-text-logo.png"
                logoInverted="/cleve-text-logo-inverted.png"
                sticky={true}
              /> */}
            </>
          </Router>
        </AuthProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
