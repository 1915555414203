import React, { useState, useRef, useEffect } from 'react';
import { 
  Typography, 
  Container, 
  Box, 
  Button,
  Grid,
  Card,
  CardContent,
  CircularProgress,
  LinearProgress,
  makeStyles,
  ButtonGroup,
  Dialog,
  DialogTitle, 
  DialogContent,
  DialogActions,
  Avatar,
  TextField,
  useMediaQuery,
  Tooltip as MUITooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import NoteNav from '../components/NoteNav.js';
import { apiRequest, formatNumber, getContrastTextColor, truncate } from '../util/util.js';
import { requireAuth } from "../util/auth";
import { LinkedIn, Refresh, Save, ExpandMore, WhatsApp, Facebook, Twitter, PostAdd, Person, GetApp, PictureAsPdf, Collections, CloudDownload, Link, CheckCircle } from '@material-ui/icons';
import { useAuth } from '../util/auth.js';
import { useRouter } from '../util/router.js';
import html2canvas from 'html2canvas';
import { useTheme } from '@material-ui/core/styles';
import dayjs from 'dayjs';
import CalHeatmap from 'cal-heatmap';
import Tooltip from 'cal-heatmap/plugins/Tooltip';
import LegendLite from 'cal-heatmap/plugins/LegendLite';
import CalendarLabel from 'cal-heatmap/plugins/CalendarLabel';
import analytics from '../util/analytics.js';
import 'cal-heatmap/cal-heatmap.css';
import { LINKEDIN_WRAPPED_DATA } from '../util/mock_data.js';
import {
  LineChart,
  Line,
  Label,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
  PieChart, Pie, Cell
} from 'recharts';
import jsPDF from 'jspdf';
import { toast } from 'react-toastify';
import Meta from '../components/Meta';
import { useInView } from 'react-intersection-observer';
import UnwrappedCTA, { CTA_COMPONENTS } from '../components/UnwrappedCTA';
import { UNWRAPPED_YEAR, FAQ_DATA, SHARING_STEPS, LOADING_MESSAGES } from './linkedin-unwrapped.js';

const CURRENT_YEAR = new Date().getFullYear();
const CURRENT_MONTH = new Date().getMonth();

const BACKGROUND_COLORS = [
  { 
    color: '#FFFDF5', 
    image: `
      radial-gradient(at 99% 43%, hsla(269,100%,86%,0.2) 0px, transparent 50%),
      radial-gradient(at 63% 63%, hsla(44,100%,57%,0.2) 0px, transparent 50%),
      radial-gradient(at 90% 7%, hsla(195,100%,81%,0) 0px, transparent 50%),
      radial-gradient(at 0% 9%, hsla(44,100%,57%,0.2) 0px, transparent 50%),
      radial-gradient(at 2% 89%, hsla(269,100%,86%,0.2) 0px, transparent 50%)
    `
  },
  { 
    color: '#FFFDF5', 
    image: `
      radial-gradient(at 99% 43%, hsla(269,100%,86%,0) 0px, transparent 50%),
      radial-gradient(at 63% 63%, hsla(44,100%,57%,0.1) 0px, transparent 50%),
      radial-gradient(at 80% 27%, hsla(195,100%,81%,0.2) 0px, transparent 50%),
      radial-gradient(at 0% 9%, hsla(44,100%,57%,0.1) 0px, transparent 50%),
      radial-gradient(at 2% 79%, hsla(195,100%,81%,0.2) 0px, transparent 50%)
    `
  },
  { 
    color: '#FFFDF5', 
    image: `
      radial-gradient(at 99% 43%, hsla(269,100%,86%,0.3) 0px, transparent 50%),
      radial-gradient(at 63% 63%, hsla(44,100%,57%,0.2) 0px, transparent 50%),
      radial-gradient(at 90% 7%, hsla(195,100%,81%,0) 0px, transparent 50%),
      radial-gradient(at 0% 9%, hsla(269,100%,86%,0.2) 0px, transparent 50%),
      radial-gradient(at 2% 89%, hsla(269,100%,86%,0.4) 0px, transparent 50%)
    `
  },
  { 
    color: '#FFFDF5', 
    image: `
      radial-gradient(at 99% 43%, hsla(269,100%,86%,0.2) 0px, transparent 50%),
      radial-gradient(at 63% 63%, hsla(44,100%,57%,0.2) 0px, transparent 50%),
      radial-gradient(at 90% 7%, hsla(195,100%,81%,0) 0px, transparent 50%),
      radial-gradient(at 0% 9%, hsla(44,100%,57%,0.2) 0px, transparent 50%),
      radial-gradient(at 2% 89%, hsla(195,100%,81%,0.2) 0px, transparent 50%)
    `
  },
]

const PALLETE = {
  'primary': '#6737E7',
  'secondary': '#E1D9D8',
  'tertiary': '#E54100',
  'quaternary': '#FFC21A',
  'quinary': '#FFD86E',
  'senary': '#FFFFFF',
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.ch-plugin-calendar-label-text': {
      fill: '#fff',
    },
    '#ch-tooltip, #ch-tooltip[data-theme="dark"]': {
      backgroundColor: '#fff',
      color: '#19083d',
      padding: theme.spacing(0),
      border: '1px solid #462986', // Solid border with the specified stroke color
      boxShadow: '0px 0px 8px rgba(140, 223, 255, 0.1)', // Drop shadow
      borderRadius: '8px', // Optional: adjust as needed for rounded corners
    }
  },
  root: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(0),
  },
  card: {
    width: '95vw', // Changed from fixed width
    maxWidth: '600px', // Keep max width
    aspectRatio: '4/5',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    backgroundColor: '#5028B9',
    margin: '40px auto', // Reduced margin for mobile
    fontFamily: 'Montserrat, sans-serif',
    color: '#222',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    borderRadius: '15px',
    '&:hover .MuiButton-root': {
      backgroundColor: 'rgba(255, 255, 255, 0.4)',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '20px auto', // Even smaller margin on mobile
    },
  },
  cardHeader: {
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  cardFooter: {
    width: '100%',
    position: 'absolute',
    bottom: '45px',
    left: 0,
  },
  cardContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2), // Add padding
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1), // Less padding on mobile
    },
  },
  title: {
    color: '#FFFFFF',
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    color: '#FFFFFF',
    marginBottom: theme.spacing(4),
  },
  linkedinReactionIcon: {
    height: '22px',
    marginTop: '20px',
    marginBottom: '-5px',
    marginRight: '5px',
    [theme.breakpoints.down('xs')]: {
      height: '16px',
      marginTop: '10px',
      marginBottom: '-2px',
      marginRight: '2px',
    },
  },
  statCard: {
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    padding: theme.spacing(1.5),
    marginBottom: theme.spacing(1),
    border: '1px solid rgb(149, 148, 152)', // Solid border with the specified stroke color
    // boxShadow: '0px 0px 8px rgba(140, 223, 255, 0.3)', // Drop shadow
    borderRadius: '8px', // Optional: adjust as needed for rounded corners
    textAlign: 'center',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  },
  statValue: {
    fontSize: '2rem',
    fontWeight: 'bold',
    color: '#000', //theme.palette.text.primary,
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.9rem',
    },
  },
  statLabel: {
    color: '#000', //theme.palette.text.primary,
    fontSize: '1rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.55rem',
    },
  },
  heatmapContainer: {
    maxWidth: '100%',
    padding: theme.spacing(0),
    // marginBottom: theme.spacing(-4),
    borderRadius: theme.spacing(1),
    '& .ch-container': {
      margin: '0 auto',
    },
    '& .ch-domain-text': {
      fill: '#FFFFFF',
      fontSize: '15px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '8px',
      },
    },
    '& .ch-subdomain-text': {
      fontSize: '12px',
      fontWeight: 700,
      [theme.breakpoints.down('xs')]: {
        fontSize: '10px',
      },
    },
    '& .ch-plugin-calendar-label-text': {
      fontSize: '12px !important',
      [theme.breakpoints.down('xs')]: {
        fontSize: '6px !important',
      },
    },
  },
  monthLabel: {
    color: '#FFFFFF',
    fontSize: '0.75rem',
    marginBottom: '4px',
  },
  footer: {
    fontSize: '0.7rem',
    padding: '8px 10px 10px',
    margin: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0,
    boxSizing: 'border-box',
  },
  topPostCard: {
    backgroundColor: 'transparent',
    padding: theme.spacing(1.5),
    margin: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0.5),
      gap: theme.spacing(1),
      margin: theme.spacing(0.5),
    },
  },
  medalEmoji: {
    fontSize: '32px',
    width: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '20px',
      width: '30px',
    },
  },
  topPostText: {
    color: '#fff',
    flex: 1,
    fontSize: '16px',
    fontWeight: 700,
    marginTop: '5px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  topPostSubtitle:{
    fontSize: '15px',
    opacity: 0.85,
    marginTop: '5px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '8px',
    },
  },
  buddyCard: {
    margin: theme.spacing(1),
    // border: '1px solid #462986',
    width: '40vw',
    maxWidth: '190px',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      width: '100%', // Full width on mobile
      margin: theme.spacing(0.5),
    },
  },
  buddyAvatar: {
    width: 48,
    height: 48,
    // border: '2px solid #462986',
    borderRadius: '20%',
    [theme.breakpoints.down('xs')]: {
      width: 24,
      height: 24,
    },
  },
  personaDescription: {
    color: '#fff',
    fontSize: '14px',
    lineHeight: '1.5',
    margin: theme.spacing(1.5, 0),
    padding: theme.spacing(0, 2),
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  chartCard: {
    backgroundColor: '#19083d',
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    border: '1px solid #462986',
    borderRadius: '8px',
    '& canvas': {
      maxWidth: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
      margin: theme.spacing(1),
      fontSize: '12px',
      '& .MuiTypography-body1': {
        fontSize: '12px',
      },
    },
  },
  streakBox: {
    backgroundColor: '#2a1a4d',
    padding: theme.spacing(2),
    borderRadius: '8px',
    textAlign: 'center',
    margin: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
      margin: theme.spacing(0.5),
    },
  },
  downloadButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    zIndex: 10,
    // backgroundColor: 'rgba(255, 255, 255, 0.2)',
    // '&:hover': {
    //   backgroundColor: 'rgba(255, 255, 255, 1)',
    // },
    minWidth: '40px',
    width: '40px',
    height: '40px',
    borderRadius: '10%',
    '&:disabled': {
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
    },
  },
  actionButtons: {
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column', // Stack buttons on mobile
      width: '100%',
      padding: theme.spacing(0, 2),
    },
  },
  actionButton: {
    fontWeight: 700,
    padding: '10px 20px',
    '&:disabled': {
      backgroundColor: 'rgba(255, 255, 255, 0.6)',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%', // Full width buttons on mobile
    },
  },
  sharingSteps: {
    backgroundColor: '#19083d',
    padding: theme.spacing(3),
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(0),
    border: '1px solid #462986',
    width: '100%',
    maxWidth: '600px',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  },
  stepContainer: {
    fontWeight: 800,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(2),
    gap: theme.spacing(2),
    '&:last-child': {
      marginBottom: 0,
    },
    [theme.breakpoints.down('xs')]: {
      alignItems: 'stretch',
    },
  },
  stepNumber: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
    },
  },
  stepText: {
    flex: 1,
    fontSize: '1rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.875rem',
    },
  },
  stepButton: {
    backgroundColor: '#FFD700',
    color: '#000000',
    '&:hover': {
      backgroundColor: '#FFE44D',
    },
    '&:disabled': {
      backgroundColor: 'rgba(255, 215, 0, 0.5)', // 50% opacity of #FFD700
      color: 'rgba(0, 0, 0, 0.5)',
    }
  },
  stepButtons: {
    // margin: 'auto',
    display: 'flex',
    gap: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  linkedinButton: {
    backgroundColor: '#0A66C2',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#004182',
    },
  },
  defaultButton: {
    backgroundColor: '#E0E0E0',
    color: '#000',
    '&:hover': {
      backgroundColor: '#BDBDBD',
    },
  },
  otherSharingOptions: {
    marginTop: theme.spacing(3),
    width: '100%',
  },
  otherSharingTitle: {
    color: '#fff',
    marginBottom: theme.spacing(1),
    textAlign: 'center',
  },
  otherSharingButtons: {
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'row',
    },
    '& .MuiButton-root:disabled': {
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // 50% opacity of #FFD700
      color: 'rgba(255, 255, 255, 0.7)'
    }
  },
  yellowButton: {
    fontWeight: 700,
    backgroundColor: '#FFD700',
    color: '#000',
    '&:hover': {
      backgroundColor: '#FFE44D',
    },
    flex: 1,
  },
  commentsContainer: {
    margin: '0 20px',
    maxHeight: '500px',
    position: 'relative',
    // '&::after': {
    //   content: '""',
    //   position: 'absolute',
    //   bottom: -10,
    //   left: 0,
    //   right: 0,
    //   height: '80px',
    //   background: 'linear-gradient(to bottom, transparent, rgba(253, 253, 253, 0.9))', // Matches the card background
    //   pointerEvents: 'none' // Allows clicking through the gradient
    // }
  },
  commentsGrid: {
    maxHeight: '500px',
    overflowY: 'hidden',
    paddingBottom: '50px', // Space for gradient
  }
}));

const IMAGE_FILENAMES = [
  '01-linkedin-unwrapped-cover',
  '02-linkedin-unwrapped-stats',
  '03-linkedin-unwrapped-categories',
  '04-linkedin-unwrapped-comments',
  '05-linkedin-unwrapped-moments',
  '06-linkedin-unwrapped-content-ideas',
  '07-linkedin-unwrapped-insights',
  '08-linkedin-unwrapped-end-note',
]

const IMAGE_FILENAMES_MAP = {
  cover: IMAGE_FILENAMES[0],
  categories: IMAGE_FILENAMES[2],
  comments: IMAGE_FILENAMES[3],
  moments: IMAGE_FILENAMES[4],
  contentIdeas: IMAGE_FILENAMES[5],
  insights: IMAGE_FILENAMES[6],
  endNote: IMAGE_FILENAMES[7],
}

const createTooltip = (content) => {
  const tooltip = document.createElement('div');
  tooltip.innerHTML = content;
  tooltip.id = 'ch-tooltip2';
  tooltip.style.position = 'fixed';
  tooltip.style.backgroundColor = '#fff';
  tooltip.style.color = '#19083d';
  tooltip.style.padding = '0';
  tooltip.style.border = '1px solid #462986';
  tooltip.style.boxShadow = '0px 0px 8px rgba(140, 223, 255, 0.1)';
  tooltip.style.borderRadius = '8px';
  tooltip.style.zIndex = 1000;
  return tooltip;
};

const updateTooltipPosition = (tooltip, e) => {
  if (tooltip) {
    tooltip.style.left = `${e.clientX + 10}px`;
    tooltip.style.top = `${e.clientY + 10}px`;
  }
};

const removeTooltip = () => {
  const tooltip = document.getElementById('ch-tooltip2');
  if (tooltip) {
    tooltip.remove();
  }
};

// Update CardWrapper to accept downloadCardAsImage as a prop
const CardWrapper = ({ 
  children, 
  cardRef, 
  downloadFileName, 
  className,
  footerRightText,
  onDownload,
  headerImage = "/wrapped/wrapped-header-3.webp", // default header
  hideFooterImage = false, // default to showing footer image,
  style = {}
}) => {
  const classes = useStyles();
  const [downloadingCard, setDownloadingCard] = useState(false);

  const handleDownload = async () => {
    try {
      setDownloadingCard(true);
      await onDownload(cardRef, downloadFileName);
    } finally {
      setDownloadingCard(false);
    }
  };

  return (
    <Box className={`${classes.card} ${className}`} ref={cardRef} style={style}>
      <Button
        className={classes.downloadButton}
        onClick={handleDownload}
        disabled={downloadingCard}
      >
        {downloadingCard ? 
          <CircularProgress size={24} color="inherit" /> : 
          <GetApp />
        }
      </Button>
      {/* <img 
        src={headerImage}
        alt="header" 
        className={classes.cardHeader}
      /> */}
      {children}
      {!hideFooterImage && (
        <img 
          src="/wrapped/wrapped-footer-3.webp" 
          alt="footer" 
          className={classes.cardFooter}
        />
      )}
      <CardFooter text={footerRightText}/>
    </Box>
  );
};

const CardFooter = ({text}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <Box className={classes.footer}>
      <Box style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
      <img src="/logo.webp" alt="Cleve.ai" style={{ height: isMobile? '12px' : '20px' }} />
        <Typography variant="body2" style={{ fontSize: isMobile? '0.6rem' : '0.8rem'}}>
          cleve.ai/stats
        </Typography>
      </Box>
      {text && <Typography variant="body2" style={{ fontSize: isMobile? '0.6rem' : '0.8rem'}}>
        {text}
      </Typography>}
    </Box>
  );
};

// First, create a function to encode the caption for the URL
const getShareUrl = (caption) => {
  const encodedText = encodeURIComponent(caption);
  return `https://www.linkedin.com/feed/?linkOrigin=LI_BADGE&shareActive=true&text=${encodedText}`;
};

const getPostTypeIcon = (post) => {
  if (post.image && post.image.length != 0) return '🖼️';
  if (post.video && post.video.length != 0) return '🎥';
  if (post.article && Object.keys(post.article).length != 0) return '📰';
  if (post.document && Object.keys(post.document).length != 0) return '📄';
  return '📝';
}

// Add this near the top of the file with other constants
const generateWrappedCaption = (linkedinData) => {
  if (!linkedinData) return '';

  const stats = linkedinData.stats || {};
  const lessons = linkedinData.lessons || [];
  const caption = linkedinData?.caption || '';

  return `📊 My ${UNWRAPPED_YEAR} LinkedIn stats (from Cleve.ai):
- ${formatNumber(stats.totalPosts || 0)} posts
- ${formatNumber(stats.totalReactions || 0)} total reactions
- ${formatNumber(stats.totalComments || 0)} comments

Here's 10 lessons I learned in ${UNWRAPPED_YEAR}:
${lessons.map(lesson => `${lesson?.emoji} ${lesson?.text}`).join('\n')}

${caption}

Get your free LinkedIn unwrapped from cleve.ai/unwrapped.

#LinkedinUnwrapped #CleveAI`;
};

// Add this component near the top of the file with other component definitions
const AIInsightCard = ({ text, icon = "✨", style = {}, children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  
  return (
    <MUITooltip title={text} placement="bottom">
    <Box
    style={{ 
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
      borderRadius: '12px',
      padding: '12px',
      margin: '0px 20px 20px',
      display: 'flex',
      alignItems: 'flex-start',
      gap: '12px',
      border: '1px solid rgba(104, 0, 160, 0.4)',
      // maxHeight: '70px',
      // overflow: 'hidden',
      ...style
    }}>
      <Box style={{
        width: '12px',
        height: '16px',
        flexShrink: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <span role="img" aria-label="insight icon" style={{ fontSize: '12px' }}>{icon}</span>
      </Box>
      <Box style={{ 
        fontSize: isMobile? '8px' : '11px',
        color: '#333',
        flex: 1,
        lineHeight: 1.4
      }}>
        {children ? children : (
          <Typography style={{
            fontSize: 'inherit',
            color: 'inherit',
            lineHeight: 'inherit'
          }}>
            {truncate(text, 250)}
          </Typography>
        )}
      </Box>
    </Box>
    </MUITooltip>
  );
};

// Add this component near other component definitions
const CommentCard = ({ tooltip, comment, onClick }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  
  return (
    
    <Box style={{
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      borderRadius: '12px',
      padding: '14px',
      marginBottom: theme.spacing(2),
      boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    }}>
      <MUITooltip title='Click to view the related post' placement="bottom">
      <Typography 
        onClick={onClick}
        style={{ 
          fontSize: isMobile ? '7px' : '9px',
          marginBottom: '12px',
          color: '#333'
        }}
      >
        {comment.text}
      </Typography>
      </MUITooltip>
      <MUITooltip title='Click to view the user' placement="bottom">
      <Box 
        onClick={() => {
          window.open(comment.author.linkedinUrl, '_blank');
        }}
      
        style={{ 
          display: 'flex', 
          alignItems: 'center',
          gap: '8px',
          cursor: 'pointer'
        }}>
        <Avatar 
          src={comment.author.image || `https://api.dicebear.com/7.x/avataaars/svg?seed=${comment.author.firstName}`} 
          style={{ 
            width: '22px', 
            height: '22px' 
          }}
        />
        <Box>
          <Typography style={{ 
            fontSize: isMobile ? '7px' : '9px',
            fontWeight: 'bold',
            color: '#333'
          }}>
            {comment.author.firstName + ' ' + comment.author.LastName}
          </Typography>
          <Typography style={{ 
            fontSize: isMobile ? '6px' : '8px',
            color: '#666'
          }}>
            {truncate(comment.author.title, 45)}
          </Typography>
        </Box>
      </Box>
      </MUITooltip>
    </Box>
  );
};

// Add this component near other component definitions
const EngagerCard = ({ name, title, avatar, onClick, engagementCount }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  
  return (
    <MUITooltip title='Click to view the user' placement="bottom">
    <Box 
      onClick={onClick}
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        padding: '6px 8px',
        borderRadius: '8px',
        marginBottom: '8px',
        width: isMobile? '100px' : '170px',
        cursor: 'pointer'
      }}
    >
      <Avatar 
        src={avatar}
        style={{ width: '18px', height: '18px' }}
      />
      <Box style={{ flex: 1 }}>
        <Typography style={{ 
          fontSize: isMobile ? '6px' : '8px',
          fontWeight: 'bold',
          color: '#333'
        }}>
          {name}
        </Typography>
        <Typography style={{ 
          fontSize: isMobile ? '5px' : '7px',
          color: '#666'
        }}>
          {title}
        </Typography>
      </Box>
      <Typography style={{ 
        fontSize: isMobile ? '5px' : '7px',
        color: '#666'
      }}>
        ✍️{engagementCount}
      </Typography>
    </Box>
    </MUITooltip>
  );
};

// Add this mock data near the top of the file with other constants
const JANUARY_DATA = {
  lessons: [
    { emoji: "📝", text: "There's more to life than work. It's not really that simple." },
    { emoji: "🚀", text: "20's is the best time to explore. Go wild, have fun and make the most of it." },
    { emoji: "💝", text: "Self-acceptance is important" },
    { emoji: "❤️", text: "It's important to be kind. It makes the biggest difference on someone's life." },
    { emoji: "❤️", text: "It's important to be kind. It makes the biggest difference on someone's life." },
    { emoji: "⚡", text: "Help those who are in need. You can do so much more with them." }
  ],
  activities: [
    { date: "1/1", text: "Celebrated new year's with family and friends & had a great time!", emoji: "🎉" },
    { date: "3/1", text: "Went hiking with startup founders. Challenging journey!", emoji: "⛰️" },
    { date: "4/1", text: "Reflected on the importance of family time", emoji: "👨‍👩‍👧‍👦" },
    { date: "5/1", text: "Attended Antler's speaking session", emoji: "" },
    { date: "11/1", text: "Got featured on BFM", emoji: "📻" },
    { date: "18/1", text: "Met an old friend who's now become a chiropractor and helped fix my back!", emoji: "🏥" },
    { date: "20/1", text: "Conducted training session at Asia school of business", emoji: "🎓" },
    { date: "22/1", text: "Second hike of the year🏃‍♂️ I made it out alive!", emoji: "" },
    { date: "24/1", text: "Ran into old high school teacher. Reminded me of the simple things in life.", emoji: "🎒" }
  ]
};

// Add this mock data near other constants
const CONTENT_IDEAS_DATA = {
  fromPosts: [
    "Last week I met the President. He was tall. Last week I met the President. He was tall.",
    "Last week I met the President. He was tall. Last week I met the President. He was tall.",
    "Last week I met the President. He was tall. Last week I met the President. He was tall.",
    "Last week I met the President. He was tall. Last week I met the President. He was tall.",
    "Last week I met the President. He was tall. Last week I met the President. He was tall.",
  ],
  fromComments: [
    "Last week I met the President. He was tall. Last week I met the President. He was tall.",
    "Last week I met the President. He was tall. Last week I met the President. He was tall.",
    "Last week I met the President. He was tall. Last week I met the President. He was tall.",
    "Last week I met the President. He was tall. Last week I met the President. He was tall.",
    "Last week I met the President. He was tall. Last week I met the President. He was tall.",
  ],
  fromNotes: [
    "Last week I met the President. He was tall. Last week I met the President. He was tall.",
    "Last week I met the President. He was tall. Last week I met the President. He was tall.",
    "Last week I met the President. He was tall. Last week I met the President. He was tall.",
    "Last week I met the President. He was tall. Last week I met the President. He was tall.",
    "Last week I met the President. He was tall. Last week I met the President. He was tall.",
  ]
};

// Add this component near other component definitions
const ContentIdeaItem = ({ text, tooltip }) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'));
  
  return (
    <MUITooltip title={tooltip} placement="bottom">
    <Box style={{
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
      borderRadius: '8px',
      padding: '8px 12px',
      marginBottom: '8px'
    }}>
      <Typography style={{ 
        fontSize: isMobile ? '7px' : '11px',
        color: '#333',
        lineHeight: 1.4
      }}>
        {text}
      </Typography>
    </Box>
    </MUITooltip>
  );
};

const LinkedinMonthlyStats = () => {
  const classes = useStyles();
  const auth = useAuth();
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState(LOADING_MESSAGES[0]);
  const [isPosting, setIsPosting] = useState(false);
  const [linkedinData, setLinkedinData] = useState(null);
  const [error, setError] = useState(null);
  const wrapperRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [calendars, setCalendars] = useState({});
  const calendarRefs = {
    main: useRef(null),
    activity: useRef(null),
    topPosts: useRef(null),
    final: useRef(null),
    monthly: useRef(null),
  };
  const [selectedPost, setSelectedPost] = useState(null);
  const [linkedinUrl, setLinkedinUrl] = useState('');
  const [isValidUrl, setIsValidUrl] = useState(true);
  const cardRefs = {
    cover: useRef(null),
    categories: useRef(null),
    comments: useRef(null),
    moments: useRef(null),
    contentIdeas: useRef(null),
    insights: useRef(null),
    endNote: useRef(null),
  };
  const [downloadingCard, setDownloadingCard] = useState(null);
  const [downloadingAll, setDownloadingAll] = useState({ pdf: false, images: false });
  const [copiedState, setCopiedState] = useState(false);
  const [copiedLinkState, setCopiedLinkState] = useState(false);  // Add this new state
  const [processedPdf, setProcessedPdf] = useState(null);
  // Add these new state variables near the top
  const [processedImages, setProcessedImages] = useState({});
  const [isPreprocessing, setIsPreprocessing] = useState(false);
  const [processedCanvases, setProcessedCanvases] = useState({});
  // Update state to store both canvases and converted data
  const [processedContent, setProcessedContent] = useState({
    canvases: {},
    images: {},
    pdf: null
  });
  // Add this state near other useState declarations
  const [email, setEmail] = useState('');

  // Add this validation function near other validation functions
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  useEffect(() => {
    const initCalendars = async () => {
      if (linkedinData?.stats?.heatmapData) {
        // Cleanup existing calendars
        Object.values(calendars).forEach(cal => {
          if (cal) cal.destroy();
        });

        const newCalendars = {};
        // Initialize calendars for each ref
        const calendarPromises = Object.entries(calendarRefs).map(async ([key, ref]) => {
          if (ref.current) {
            const cal = new CalHeatmap();
            await cal.paint({
              itemSelector: ref.current,
              domain: {
                type: 'month',
                // dynamicDimension: false,
                // gutter: isMobile ? -20 : -30,
                label: { text: null },
              },
              subDomain: {
                type: 'xDay',
                color: (t, v, backgroundColor) => v > linkedinData?.stats?.topPosts?.[0]?.engagement/2 ? '#fff' : '#000',
                width: isMobile ? 20 : 36,
                height: isMobile ? 20 : 36,
                radius: 4,
                gutter: 4,
                label: key == 'monthly' ? 'DD' : (t, v) => {
                  if (key == 'topPosts') {
                    return v > (linkedinData.stats.topPosts?.[3]?.engagement || 0) ? '🟡' : '';
                  }
                  return '';
                },
              },
              range: 1,
              date: {
                start: new Date(Date.UTC(CURRENT_YEAR, CURRENT_MONTH, 0)),
                min: new Date(Date.UTC(CURRENT_YEAR, CURRENT_MONTH, 1)),
                max: new Date(Date.UTC(CURRENT_YEAR, CURRENT_MONTH, 31)),
              },
              data: {
                source: linkedinData.stats.heatmapData,
                x: 'date',
                y: 'value',
                defaultValue: 0,
              },
              scale: {
                opacity: {
                  baseColor: '#6737E7',
                  // interpolate: 'rgb',
                  type: 'linear',
                  domain: [0, linkedinData?.stats?.topPosts?.[0]?.engagement || 100],
                },
              },
              theme: 'light',
            },
            [
              [
                Tooltip,
                {
                  text: (date, value, dayjsDate) => {
                    const post = linkedinData.stats.heatmapData?.find(
                      post => post.date === dayjsDate.format('YYYY-MM-DD')
                    );
                    if (!post?.posts?.[0]) return '';
                    
                    return `
                      <div style="padding: 10px; max-width: 300px;">
                        <b>${dayjsDate.format('MMM D, YYYY')}</b>
                        <br/>⭐ ${value} engagement
                        <br/>"${truncate(post.posts[0].text, 120)}"
                        <br/><small>Click to view full post</small>
                      </div>
                    `;
                  }
                }
              ],
              // [CalendarLabel, {
              //   width: isMobile ? 18 : 30,
              //   text: () => ['', 'Mon', '', 'Wed', '', 'Fri', ''],
              //   textAlign: 'start',
              // }],
              // [
              //   LegendLite,
              //   {
              //     itemSelector: `#legend-${key}`,
              //     radius: 2,
              //     width: 8,
              //     height: 8,
              //     gutter: 2,
              //   },
              // ],
            ]);

            cal.on('click', (event, timestamp, value) => {
              const date = dayjs(timestamp).format('YYYY-MM-DD');
              const post = linkedinData.stats.heatmapData?.find(
                post => post.date === date
              );
              if (post?.posts?.[0]) {
                setSelectedPost(post.posts[0]);
              }
            });

            newCalendars[key] = cal;
          }
        });

        await Promise.all(calendarPromises);
        setCalendars(newCalendars);

        // After calendars are initialized, wait a bit and preprocess content
        if (linkedinData && !isPreprocessing && (!processedCanvases || Object.keys(processedCanvases).length == 0)) {
          setTimeout(() => {
            console.log('Preprocessing content after calendars initialized');
            if (process.env.NODE_ENV === 'development') {
              console.log('Skipping preprocessing in dev environment');
            } else {
              //preprocessContent(cardRefs);
            }
          }, 2000);
        }
      }
    };

    initCalendars();

    return () => {
      Object.values(calendars).forEach(cal => {
        if (cal) cal.destroy();
      });
    };
  }, [linkedinData]);

  useEffect(() => {
    if (router.query.id) {
      const isCompany = router.query.id.includes('company-')
      const url = isCompany ? `https://www.linkedin.com/company/${router.query.id.replace('company-', '')}/` : 
        `https://www.linkedin.com/in/${router.query.id}/`
      setLinkedinUrl(url);
      // set to true if we want to only enable generation through button and not url
      fetchLinkedInData(url, '', false, false);
    }
  }, [router.query.id]);

  useEffect(() => {
    if (auth.user && !linkedinUrl) {
      console.log('auth.user', auth.user)
      if (auth.user.linkedinUrl) {
        let linkedinUrl = auth.user?.linkedinUrl
        // if(process.env.NODE_ENV === 'development') {
        //   linkedinUrl = 'https://www.linkedin.com/in/lizzietan/'
        // }
        setLinkedinUrl(linkedinUrl);
        // set to true if we want to only enable generation through button and not url
        fetchLinkedInData(linkedinUrl, '', false, false);
      } else {
        setError('Please connect your LinkedIn account in settings to view stats');
        toast.error('Please connect your LinkedIn account in settings to view stats');
        router.push('/settings/general');
      }
    }
  }, [auth.user]);

  // Replace preprocessImage, preprocessPdf, and preprocessAllContent with this single function
  const preprocessContent = async (cardRefs) => {
    setIsPreprocessing(true);
    try {
      console.log('Preprocessing content...');
      const canvases = {};
      const images = {};

      // Create PDF
      const pdf = new jsPDF('p', 'px', [600, 750], true);
      let isFirst = true;
      
      // Process all canvases and convert to images
      for (const [key, ref] of Object.entries(cardRefs)) {
        if (ref.current) {
          ref.key = key
          console.log('Processing canvas for', key);
          try {
            const canvas = await html2canvas(ref.current, {
              scale: 2,
              useCORS: true,
              logging: false,
              backgroundColor: '#11042E',
            });
            canvases[key] = canvas;
            images[key] = canvas.toDataURL('image/png');

            if (!isFirst) {
              pdf.addPage();
            }
            isFirst = false;
            pdf.addImage(images[key], 'PNG', 0, 0, 600, 750);
          } catch (error) {
            console.error(`Error preprocessing canvas for ${key}:`, error);
          }
        }
      }

      // Store all processed content
      setProcessedContent({
        canvases,
        images,
        pdf
      });
      
      console.log('Preprocessing complete');
    } catch(error) {
      console.error('Error preprocessing content:', error);
      toast.error('Error preprocessing content. Please try again.');
    } finally {
      setIsPreprocessing(false);
      console.log('Preprocessing complete 2');
    }
  };

  // Simplified download functions that use preprocessed data
  const downloadCardAsImage = async (cardRef, filename) => {
    try {
      if(!processedContent.images[cardRef.key]) {
        await preprocessContent(cardRefs);
      }
      setDownloadingCard(filename);
      const imgData = processedContent.images[cardRef.key];
      
      if (imgData) {
        const link = document.createElement('a');
        link.download = `${filename}.png`;
        link.href = imgData;
        link.click();
      } else {
        throw new Error('Failed to process image');
      }
    } catch (error) {
      console.error('Error downloading image:', error);
      toast.error('Error downloading image. Please try again.');
    } finally {
      setDownloadingCard(null);
    }
  };

  const downloadAllImages = async () => {
    try {
      if(!processedContent.images) {
        await preprocessContent(cardRefs);
      }
      setDownloadingAll(prev => ({ ...prev, images: true }));
      analytics.track('Unwrapped image download', {
        linkedinUrl: linkedinUrl,
        email: email,
      });
      const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
      
      for (const [key, imgData] of Object.entries(processedContent.images)) {
        const link = document.createElement('a');
        link.download = `${IMAGE_FILENAMES_MAP[key]}.png`;
        link.href = imgData;
        link.click();
        await delay(300);
      }
    } catch (error) {
      console.error('Error downloading all images:', error);
      toast.error('Error downloading images. Please try again.');
    } finally {
      setDownloadingAll(prev => ({ ...prev, images: false }));
    }
  };

  const saveAllAsPDF = async () => {
    try {
      if(!processedContent.pdf) {
        await preprocessContent(cardRefs);
      }
      setDownloadingAll(prev => ({ ...prev, pdf: true }));
      if (processedContent.pdf) {
        processedContent.pdf.save('linkedin-unwrapped-by-cleve-ai.pdf');
        analytics.track('Unwrapped PDF download', {
          linkedinUrl: linkedinUrl,
          email: email,
        });
      } else {
        throw new Error('PDF not ready');
      }
    } catch (error) {
      console.error('Error saving PDF:', error);
      toast.error('Error saving PDF. Please try again.');
    } finally {
      setDownloadingAll(prev => ({ ...prev, pdf: false }));
    }
  };

  const validateLinkedInUrl = (url) => {
    // Check for linkedin.com/in/ followed by at least 1 character
    return (url.includes('linkedin.com/in/') || url.includes('linkedin.com/company/')) && getLinkedinIdFromUrl(url).length > 0;
  };

  const cleanLinkedInUrl = (url) => {
    // Remove any query params or sub paths after the profile name
    const isCompany = url.includes('linkedin.com/company/')
    const cleanedUrl = url.split(isCompany ? 'linkedin.com/company/' : 'linkedin.com/in/')[1].split('/')[0].split('?')[0];
    const normalizedUrl = isCompany ? `https://www.linkedin.com/company/${cleanedUrl}` : `https://linkedin.com/in/${cleanedUrl}`;
    return normalizedUrl;
  }

  const getLinkedinIdFromUrl = (url) => {
    const isCompany = url.includes('linkedin.com/company/')
    const cleanedUrl = cleanLinkedInUrl(url);
    const linkedinId = cleanedUrl.split(isCompany ? '/company/' : '/in/')[1];
    return linkedinId;
  }

  // Update the fetchLinkedInData function to include email validation and sending
  const fetchLinkedInData = async (linkedinUrl, email, cache = false, emailValidation = true) => {
    let loadingInterval;
    try {
      if (!linkedinUrl) {
        setError('Please enter a LinkedIn profile URL');
        toast.error('Please enter a LinkedIn profile URL');
        return;
      }

      if (!validateLinkedInUrl(linkedinUrl)) {
        setError('Please enter a valid LinkedIn profile URL (e.g., https://linkedin.com/in/username)');
        toast.error('Please enter a valid LinkedIn profile URL (e.g., https://linkedin.com/in/username)');
        setIsValidUrl(false);
        return;
      }

      // Uncomment this if we want to require email
      // commented to 
      if (emailValidation && !email) {
        setError('Please enter your email');
        toast.error('Please enter your email');
        return;
      }

      if (emailValidation && email && !validateEmail(email)) {
        setError('Please enter a valid email address');
        toast.error('Please enter a valid email address');
        return;
      }

      // Remove any query params or sub paths after the profile name
      const cleanedUrl = cleanLinkedInUrl(linkedinUrl);
      const linkedinId = getLinkedinIdFromUrl(linkedinUrl);
      setLinkedinUrl(cleanedUrl);

      setIsLoading(true);
      // Set up loading text that changes every 3 seconds
      

      let messageIndex = 0;
      loadingInterval = setInterval(() => {
        setLoadingText(LOADING_MESSAGES[messageIndex]);
        messageIndex = (messageIndex + 1) % LOADING_MESSAGES.length;
      }, 4000);
      setError(null);
      setIsValidUrl(true);

      console.log(cleanedUrl)
      console.log(`${window.location.href.split('?')[0]}/${linkedinId}`)
      
      const data = await apiRequest('linkedin-stats', 'POST', {
        action: 'stats',
        user: auth.user,
        linkedinAccessToken: auth.user?.linkedinAccessToken,
        month: 1,
        year: 2025,
        linkedinUrl: cleanedUrl,
        email: email,
        cache: cache,
      });
      console.log('linkedin stats', data)
      analytics.track('Get linkedin stats', {
        linkedinUrl: cleanedUrl,
        email: email,
        unwrappedUrl: `${window.location.href.split('?')[0]}/${linkedinId}`,
      });
      localStorage.setItem('linkedinUrl', cleanedUrl);
      localStorage.setItem('email', email);

      // console.log('LinkedIn API Response:', data);
      setLinkedinData(data);
    } catch (err) {
      console.error('Error fetching LinkedIn data:', err);
      setError(err?.message || 'Failed to fetch LinkedIn data');
      toast.error(err?.message || 'Failed to fetch LinkedIn data');
      
      // Set mock data for testing
      // setLinkedinData(LINKEDIN_WRAPPED_DATA);
    } finally {
      setIsLoading(false);
      clearInterval(loadingInterval)
    }
  };

  const renderHeatmap = (id) => (
    <Box className={classes.heatmapContainer}>
      {id == 'monthly' && 
      <Box style={{ backgroundColor: PALLETE.secondary, borderRadius: '4px', padding: '4px', width: '40px', margin: '0 auto', marginBottom: '12px' }}>
        <Typography style={{ fontSize: '14px', color: PALLETE.primary, lineHeight: 1, textAlign: 'center' }}>
        Jan
        </Typography>
      </Box>}
      <div ref={calendarRefs[id]} style={{ margin: '0 auto', maxWidth: '100%', color: '#fff' }}></div>

      {id == 'main' && <div style={{ float: 'right', fontSize: 7, padding: 10, display: 'flex', alignItems: 'center' }}>
        <span style={{ color: '#768390' }}>less</span>
        <div
          id={`legend-${id}`}
          style={{ display: 'inline-block', margin: '0 4px' }}
        ></div>
        <span style={{ color: '#768390', fontSize: 7 }}>more</span>
      </div>}
    </Box>
  );

  const renderPostDialog = () => (
    <Dialog 
      open={!!selectedPost}
      onClose={() => setSelectedPost(null)}
      maxWidth="sm"
      fullWidth
      // className={classes.statCard}
    >
      <DialogTitle>
        {selectedPost?.postedDate && dayjs(selectedPost.postedDate).format('MMMM D, YYYY')}
      </DialogTitle>
      <DialogContent>
        <Typography style={{ whiteSpace: 'pre-wrap', marginBottom: '16px' }}>
          {selectedPost?.text}
        </Typography>
        {selectedPost?.image?.[0] && (
          <Box mt={2}>
            <img 
              src={selectedPost.image[0].url}
              alt="Post content"
              style={{ maxWidth: '100%', height: 'auto' }}
            />
          </Box>
        )}
        <Box mt={2}>
          <Typography variant="body2" color="textSecondary">
            ⭐ {formatNumber(selectedPost?.totalReactionCount || 0)} reactions
            • 💬 {formatNumber(selectedPost?.commentsCount || 0)} comments  
            • 🔄 {formatNumber(selectedPost?.repostsCount || 0)} reposts
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        {selectedPost?.postUrl && (
          <Button 
            href={selectedPost.postUrl}
            target="_blank"
            color="primary"
          >
            View on LinkedIn
          </Button>
        )}
        <Button onClick={() => setSelectedPost(null)} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );

  const PostingPatternHourlyCard = ({ data }) => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
    const userTimezoneOffset = new Date().getTimezoneOffset() / 60;


    // Prepare data for hour chart
    const hourData = Array.from({length: 24}, (_, i) => {
      // Calculate the UTC hour that corresponds to this local hour
      const utcHour = (i + userTimezoneOffset + 24) % 24;
      
      return {
        hour: `${i}`,
        posts: data.stats.byHour[Math.floor(utcHour)]?.posts || 0,
        avgEngagement: data.stats.byHour[Math.floor(utcHour)]?.posts && 
          data.stats.byHour[Math.floor(utcHour)]?.posts > 0 ? 
          Math.round((data.stats.byHour[Math.floor(utcHour)]?.engagement || 0) / 
          data.stats.byHour[Math.floor(utcHour)]?.posts) : 
          'Not enough data'
      };
    });

    const CustomTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <Box style={{
            backgroundColor: '#19083d',
            padding: '10px',
            border: '1px solid #462986',
            borderRadius: '4px',
          }}>
            <Typography style={{ color: '#fff' }}>
              {label}:00
            </Typography>
            <Typography style={{ color: '#fff' }}>
              {payload[0].value === 'Not enough data' ? 
                'Not enough data' : 
                `${payload[0].value} ${payload[0].dataKey === 'posts' ? 'posts' : 'engagement'}`}
            </Typography>
          </Box>
        );
      }
      return null;
    };

    const renderChart = (data, xKey, dataKey, color, interval = 0, fontSize = 10) => (
      <div style={{ width: isMobile ? '130%' : '100%', height: isMobile ? 60 : 80, marginLeft: isMobile ? '-30%' : '-30px'}}>
        <ResponsiveContainer>
          <BarChart data={data}>
            <CartesianGrid strokeDasharray="3 3" stroke="rgba(82, 55, 55, 0.1)" />
            <XAxis 
              dataKey={xKey} 
              tick={{ fill: '#000', fontSize: isMobile ? fontSize - 2 : fontSize }}
              height={10}
              interval={interval}
            />
            <YAxis tick={{ fill: '#000', fontSize: isMobile ? fontSize - 2 : fontSize }}/>
            <RechartsTooltip content={<CustomTooltip />} />
            <Bar dataKey={dataKey} fill={color} radius={[4, 4, 0, 0]} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );

    return (
        <Box className={classes.statCard}>
          <Typography style={{ marginBottom: '8px', fontSize: isMobile ? '9px' : '11px' }}>
            Your Posts by Time {`(${new Date().toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2]})`}
          </Typography>
          <Typography align="center" style={{ fontSize: isMobile ? '7px' : '10px'}}>
            Time posted
          </Typography>
          {renderChart(hourData, 'hour', 'posts', '#FFD86E', 3)}
          
          <Typography align="center" style={{ fontSize: isMobile ? '7px' : '10px', marginTop: '10px'}}>
            Average Engagement
          </Typography>
          {renderChart(hourData, 'hour', 'avgEngagement', '#DD00E5', 3)}
        </Box>
    );
  };

  const PostingPatternWeeklyCard = ({ data }) => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    // Prepare data for day chart
    const dayLabels = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const dayData = dayLabels.map((day, index) => ({
      day,
      posts: data.stats.byDay[index]?.posts || 0,
      avgEngagement: data.stats.byDay[index]?.posts && data.stats.byDay[index]?.posts > 0 ? 
        Math.round((data.stats.byDay[index]?.engagement || 0) / data.stats.byDay[index]?.posts) : 'Not enough data'
    }));

    const CustomTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <Box style={{
            backgroundColor: '#19083d',
            padding: '10px',
            border: '1px solid #462986',
            borderRadius: '4px',
          }}>
            <Typography style={{ color: '#fff' }}>
              {label}
            </Typography>
            <Typography style={{ color: '#fff' }}>
              {payload[0].value === 'Not enough data' ? 
                'Not enough data' : 
                `${payload[0].value} ${payload[0].dataKey === 'posts' ? 'posts' : 'engagement'}`}
            </Typography>
          </Box>
        );
      }
      return null;
    };

    const renderChart = (data, xKey, dataKey, color, interval = 0, fontSize = 10) => (
      <div style={{ width: isMobile ? '130%' : '100%', height: isMobile ? 60 : 80, marginLeft: isMobile ? '-30%' : '-30px'}}>
        <ResponsiveContainer>
          <BarChart data={data}>
            <CartesianGrid strokeDasharray="3 3" stroke="rgba(82, 55, 55, 0.1)" />
            <XAxis 
              dataKey={xKey} 
              tick={{ fill: '#000', fontSize: isMobile ? fontSize - 3 : fontSize }}
              height={10}
              interval={interval}
            />
            <YAxis tick={{ fill: '#000', fontSize: isMobile ? fontSize - 3 : fontSize }}/>
            <RechartsTooltip content={<CustomTooltip />} />
            <Bar dataKey={dataKey} fill={color} radius={[4, 4, 0, 0]} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );

    return (
      <Box className={classes.statCard}>
          <Typography style={{ marginBottom: '8px', fontSize: isMobile ? '9px' : '11px' }}>
            Your Posts by Day
          </Typography>
          <Typography align="center" style={{ fontSize: isMobile ? '7px' : '10px'}}>
            Day Posted
          </Typography>
          {renderChart(dayData, 'day', 'posts', '#FFD86E')}
          
          <Typography align="center" style={{ fontSize: isMobile ? '7px' : '10px', marginTop: '10px'}}>
            Average Engagement
          </Typography>
          {renderChart(dayData, 'day', 'avgEngagement', '#DD00E5')}
        </Box>
    );
  };

  // Then update handleCopyCaption to use this function
  const handleCopyCaption = () => {
    const caption = generateWrappedCaption(linkedinData);
    navigator.clipboard.writeText(caption);
    setCopiedState(true);
    setTimeout(() => {
      setCopiedState(false);
    }, 2000);
    //toast.success('Caption copied to clipboard!');
  };

  // Add this near the top of the file with other imports
  const { ref: ctaRef, inView: ctaInView } = useInView({
    threshold: 0.3, // Trigger when 30% of the element is visible
    triggerOnce: true // Only trigger once
  });

  // Add useEffect to track when CTA becomes visible
  // useEffect(() => {
  //   if (ctaInView) {
  //     console.log('CTA in view');
  //     analytics.track('Unwrapped Scrolled to CTA', {
  //       linkedinUrl: linkedinUrl,
  //       email: email
  //     });
  //   }
  // }, [ctaInView]);

  // Add this near the top of the file with other state declarations:
  const selectedCTAVariation = React.useMemo(() => 
    CTA_COMPONENTS[Math.floor(Math.random() * CTA_COMPONENTS.length)],
    //CTA_COMPONENTS.find(component => component.id === 'lite'),
  []);

  // Add these constants near the top of the file
  const CATEGORIES = {
    'inspirational': {
      name: 'Inspirational',
      color: '#6737E7'
    },
    'educational': {
      name: 'Educational',
      color: '#FF4500'
    },
    'thought_leadership': {
      name: 'Thought leadership',
      color: '#7FE7D9'
    },
    'entertainment': {
      name: 'Entertainment',
      color: '#FFD700'
    },
    'moments': {
      name: 'Moments',
      color: '#E8D3FF'
    },
    'promotional': {
      name: 'Promotional',
      color: '#4A4A4A'
    }
  }

  // Add this helper function to chunk array into pairs
  const chunk = (array, size) => {
    const chunked = [];
    for (let i = 0; i < array.length; i += size) {
      chunked.push(array.slice(i, i + size));
    }
    return chunked;
  };

  // Add these components near other component definitions
  const LessonItem = ({ text, emoji }) => {
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'));
    
    return (
      <Box style={{
        display: 'flex',
        alignItems: 'flex-start',
        gap: isMobile ? '0px' : '6px',
        marginBottom: '8px'
      }}>
        <Typography style={{ 
          fontSize: isMobile ? '7px' : '11px',
          lineHeight: 1.2
        }}>
          {emoji}
        </Typography>
        <Typography style={{ 
          fontSize: isMobile ? '7px' : '11px',
          color: '#333',
          flex: 1
        }}>
          {text}
        </Typography>
      </Box>
    );
  };

  const ActivityItem = ({ date, text, emoji, onClick }) => {
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'));
    
    return (
      <Box style={{
        display: 'flex',
        alignItems: 'flex-start',
        gap: isMobile ? '0px' : '8px',
        marginBottom: '8px',  
        cursor: 'pointer'
      }}
      onClick={onClick}
      >
        <Typography style={{ 
          fontSize: isMobile ? '7px' : '11px',
          color: '#666',
          width: '24px',
          flexShrink: 0
        }}>
          {date}
        </Typography>
        <Typography style={{ 
          fontSize: isMobile ? '7px' : '11px',
          color: '#333',
          flex: 1
        }}>
          {text} {emoji}
        </Typography>
      </Box>
    );
  };

  return (
    <Box>
      <Meta 
        key="linkedin-stats"
        title="Stats" 
        description="Stats"
        image="/stats/stats-social-sharing.webp"
      />
      <Box 
        style={{ 
          // backgroundColor: '#fff',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: isMobile? '10px 20px' : '40px 20px'
        }}
      >
        
      {isLoading &&
          <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', maxWidth: '360px', color: '#fff', marginTop: '40px'}}>
            {/* <Typography
              variant="body2"
              align="center"
              style={{
                color: '#FFFFFF',
                textAlign: 'center',
                opacity: 0.8,
                fontSize: isMobile ? '10px' : '12px'
              }}
            >
              This may take 1 - 2 min to complete.
            </Typography> */}
            <img src="/wrapped/working-cat2.gif" alt="Loading" style={{ width: '240px', marginBottom: '16px' }} />
            <Box style={{ width: '100%', marginBottom: 5 }}>
              <LinearProgress 
                variant="indeterminate" 
                style={{
                  height: 8,
                  borderRadius: 4,
                  backgroundColor: 'rgba(255,255,255,0.1)',
                }}
              />
            </Box>
            <Typography variant="h6" style={{ 
              marginTop: 8,
              textAlign: 'center',
              animation: 'fadeInOut 2s infinite',
              color: '#000'
            }}>
              {loadingText}
            </Typography>
            
          </Box>
      }
        
        {linkedinData &&
        <>
        <Typography 
        variant="h4" 
        align="left" 
        style={{ 
          fontWeight: 'bold',
          margin: isMobile ? '10px 10px' : '10px 50px'
        }}
      >
        Your Stats
      </Typography>
      <Typography
          variant="body2"
          align="center"
          style={{
            margin: isMobile ? '20px 20px 0px' : '10px 50px 0px',
            opacity: 0.8
          }}
        >
          This report is interactive. Hover on the cards to see what happens!
          {isMobile && <><br/> For best experience, view on desktop</>}
        </Typography>
      {(isPreprocessing || !processedContent.pdf) && 
      <Box>
        {/* <Typography
          variant="body2"
          align="center"
          style={{
            color: '#FFFFFF',
            margin: isMobile ? '0px 20px 10px' : '0px 300px 10px',
            opacity: 0.8
          }}
        >
          In ~30 seconds your shareable PDF will finish processing and the experience will be smoother. <br/> Scroll below the report to download the PDF.
        </Typography> */}
        {/* <Box style={{ width: '100%', maxWidth: '390px', margin: '20px auto' }}>
          <LinearProgress 
            variant="indeterminate" 
          style={{
            backgroundColor: 'rgba(255,255,255,0.1)',
            height: '4px',
              borderRadius: '10px'
            }}
          />
        </Box> */}
      </Box>
      }

      {/* Add the sharing instructions here - Add this code: */}
      {/* <Box className={classes.sharingSteps}>
        <Typography 
          variant="h6" 
          style={{ 
            color: '#fff', 
            marginBottom: theme.spacing(2),
            textAlign: 'left'
          }}
        >
          Be the first to share it with your friends!
        </Typography>
        {SHARING_STEPS.map((step) => (
          <Box key={step.step} className={classes.stepContainer}>
            <Typography className={classes.stepText}>
              {step.step}. {step.text}
            </Typography>
            {step.action && (
              <Button
                variant="contained"
                className={classes.stepButton}
                startIcon={downloadingAll.pdf ? <CircularProgress size={20} color="inherit" /> : step.icon}
                onClick={saveAllAsPDF}
                disabled={isPreprocessing || downloadingAll.pdf || downloadingAll.images || !processedContent.pdf}
              >
                {isPreprocessing || !processedContent.pdf ? 'Processing PDF...' : 
                  (downloadingAll.pdf ? 'Downloading...' : step.action)}
              </Button>
            )}
            {step.buttons && (
              <Box className={classes.stepButtons}>
                {step.buttons.map((button, index) => (
                  <Button
                    key={index}
                    variant="contained"
                    className={button.variant === 'linkedin' ? classes.linkedinButton : classes.defaultButton}
                    startIcon={button.variant === 'linkedin' ? 
                      <LinkedIn /> : 
                      (copiedState ? <CheckCircle /> : <PostAdd />)}
                    onClick={button.variant === 'linkedin' ? 
                      () => {
                        const caption = generateWrappedCaption(linkedinData);
                        window.open(getShareUrl(caption), '_blank');
                      } : 
                      handleCopyCaption}
                  >
                    {button.variant === 'linkedin' ? 
                      button.label : 
                      (copiedState ? "Copied!" : button.label)}
                  </Button>
                ))}
              </Box>
            )}
          </Box>
        ))}

        
      </Box>
      <Box className={classes.otherSharingOptions}>
          <Typography variant="h6" className={classes.otherSharingTitle}>
            Other sharing options
          </Typography>
          <Box className={classes.otherSharingButtons}>
            <Button
              variant="contained"
              color="secondary"
              startIcon={downloadingAll.images ? <CircularProgress size={20} color="inherit" /> : <GetApp />}
              onClick={downloadAllImages}
              disabled={isPreprocessing || downloadingAll.images || Object.keys(processedContent.images).length === 0}
            >
              {isPreprocessing || processedContent.images.length === 0 ? 'Processing images...' : 
                (downloadingAll.images ? 'Downloading...' : 'Save (images)')}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              startIcon={copiedLinkState ? <CheckCircle /> : <Link />}
              onClick={() => {
                const isCompany = linkedinUrl.includes('linkedin.com/company/')
                const publicIdentifier = linkedinUrl.split(isCompany ? '/company/' : '/in/')[1]?.replace(/\/$/, '') || '';
                const shareUrl = `https://notes.cleve.ai/unwrapped/${isCompany ? 'company-' : ''}${publicIdentifier}`;
                navigator.clipboard.writeText(shareUrl);
                setCopiedLinkState(true);
                setTimeout(() => {
                  setCopiedLinkState(false);
                }, 2000); // Reset after 2 seconds
                // toast.success('Link copied to clipboard!');
              }}
            >
              {copiedLinkState ? "Copied!" : "Copy link"}
            </Button>
          </Box>
        </Box> */}

      {/* Add the action buttons here */}
      {/* <Box className={classes.actionButtons}>
        <Button
          variant="contained"
          color="primary"
          className={classes.actionButton}
          startIcon={downloadingAll.pdf ? <CircularProgress size={20} color="inherit" /> : <GetApp />}
          onClick={() => saveAllAsPDF(cardRefs)}
          disabled={downloadingAll.pdf || downloadingAll.images}
        >
          {downloadingAll.pdf ? 'Saving...' : 'Save (PDF)'}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.actionButton}
          startIcon={downloadingAll.images ? <CircularProgress size={20} color="inherit" /> : <GetApp />}
          onClick={() => downloadAllImages(cardRefs)}
          disabled={downloadingAll.pdf || downloadingAll.images}
        >
          {downloadingAll.images ? 'Saving...' : 'Save (Images)'}
        </Button>
      </Box> */}

        <Box style={{ marginTop: '0px', 
          // backgroundColor: !linkedinData ? '#fff' : '#fff', 
          padding: isMobile? '5px' : '5px', 
          borderRadius: '12px' }}>
        
              

              {/* First Card - Original Statistics */}
              <CardWrapper 
                cardRef={cardRefs.cover} 
                downloadFileName={IMAGE_FILENAMES[0]}
                style={{
                  backgroundColor: BACKGROUND_COLORS[0].color,
                  backgroundImage: BACKGROUND_COLORS[0].image
                }}
                onDownload={downloadCardAsImage}
                headerImage="/wrapped/wrapped-header.webp"
                hideFooterImage={true}
                footerRightText={linkedinData?.profile?.full_name || auth.user?.name || ''}
              >
                <Box className={classes.cardContent}>
                  <Grid container spacing={2}>
                  <Grid item xs={6}>
                  <Typography align="left" style={{ 
                    fontSize: isMobile? '18px' : '34px', 
                    fontWeight: 800,
                    fontOpticalSizing: 'auto',
                    fontStyle: 'bold',
                    lineHeight: 1,
                    marginTop: '12px',
                    color: '#6737E7'
                  }}>
                    {new Date(linkedinData.year, linkedinData.month - 1).toLocaleString('default', { month: 'long' }).toUpperCase()} {linkedinData.year} STATS
                  </Typography>

                  <Box style={{
                    marginTop: '8px',
                    backgroundColor: 'rgba(255, 248, 225, 0.8)',
                    borderRadius: '8px',
                    padding: '8px'
                  }}>
                    <Typography style={{
                      fontSize: isMobile? '8px' : '11px',
                      fontWeight: 600,
                      color: '#666666',
                      marginBottom: '4px'
                    }}>
                      Key themes this month
                    </Typography>
                    
                    {linkedinData?.keyThemes && linkedinData?.keyThemes?.length > 0 && linkedinData?.keyThemes.slice(0, 4)?.map((theme) => (
                          <Typography key={theme} style={{
                            fontSize: isMobile? '7px' : '10px',
                            color: '#8B6F3D',
                            marginBottom: '2px'
                          }}>
                            {theme}
                          </Typography>
                        ))
                    }
                  </Box>
                  
                  </Grid>


                  <Grid item xs={6}>
                  {/* Profile Section */}
                  <Box style={{ 
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                    borderRadius: '12px',
                    padding: '10px',
                    margin: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    gap: '12px'
                  }}>
                    {linkedinData?.profile?.profile_pic_url && 
                      <Avatar 
                        src={linkedinData?.profile?.profile_pic_url} 
                        style={{ 
                          width: isMobile? '40px' : '50px', 
                          height: isMobile? '40px' : '50px',
                        }}
                      />
                    }
                    <Box>
                      <Typography style={{ 
                        color: '#000000',
                        fontSize: isMobile? '14px' : '16px',
                        fontWeight: 'bold'
                      }}>
                        {linkedinData?.profile?.full_name || auth.user?.name || ''}
                      </Typography>
                      <Typography style={{ 
                        color: '#666666',
                        fontSize: isMobile? '8px' : '11px'
                      }}>
                        {linkedinData?.profile?.headline || ''}
                      </Typography>
                      {/* <Typography style={{ 
                        color: '#666666',
                        fontSize: isMobile? '9px' : '10px'
                      }}>
                        {formatNumber(linkedinData?.profile?.follower_count || 0)} followers
                      </Typography> */}
                    </Box>
                  </Box>
                  </Grid>

                  <Grid item xs={5}>
                  {/* Stats Grid */}
                  <Box style={{ width: '100%', maxWidth: '520px' }}>
                    <Grid container spacing={1}>
                      {[
                        { value: linkedinData.stats.totalPosts, label: 'Posts', change: '+20%', color: '#4CAF50' },
                        { value: linkedinData.stats.totalReactions, label: 'Reactions', change: '-14%', color: '#F44336' },
                        { value: linkedinData.stats.totalComments, label: 'Comments', change: '+20%', color: '#4CAF50' },
                        { value: linkedinData.stats.totalReposts || 0, label: 'Reposts', change: '+20%', color: '#4CAF50' }
                      ].map((stat, index) => (
                        <Grid item xs={6} key={index}>
                          <Box className={classes.statCard}>
                            <Typography className={classes.statLabel}>
                              {stat.label}
                            </Typography>
                            <Typography className={classes.statValue}>
                              {formatNumber(stat.value)}
                            </Typography>
                            {/* {stat.change && <Typography style={{ 
                              color: stat.color, 
                              fontSize: isMobile? '10px' : '13px'
                            }}>
                              {stat.change}
                            </Typography>
                            } */}
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                  </Grid>

                  <Grid item xs={7}>
                    <Box className={classes.statCard} style={{ width: '100%'}}>
                      {renderHeatmap('monthly')}
                    </Box>
                  </Grid>
                  
                  <Grid item xs={12}>
                    {/* Top Performing Content */}
                  <Box className={classes.statCard} style={{ 
                    width: '100%',
                  }}>
                    <Box style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: '8px',
                        padding: '8px 0px',
                      }}>
                    <Typography className={classes.statLabel} style={{ 
                      fontSize: isMobile? '8px' : '11px',
                      fontWeight: 'bold',
                      opacity: 0.6
                    }}>
                      YOUR TOP PERFORMING CONTENT
                    </Typography>
                    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      {['Reposts', 'Reactions', 'Comments'].map((metric, index) => (
                        <Typography className={classes.statLabel} style={{
                          fontSize: isMobile? '8px' : '10px',
                          fontWeight: 'bold',
                          width: isMobile? '50px' : '60px',
                          opacity: 0.6
                        }}>
                          {metric}
                        </Typography>
                      ))}
                    </Box>
                    
                    </Box>
                    {linkedinData.posts.sort((a, b) => b.totalReactionCount - a.totalReactionCount).slice(0, 5).map((post, index) => (
                      <Box key={index} style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: '4px',
                        padding: '4px',
                      }}>
                        <Typography align="left" className={classes.statLabel} style={{ 
                          fontSize: isMobile? '10px' : '11px',
                          width: '100%',
                          flex: 1
                        }}>
                          {getPostTypeIcon(post)} {truncate(post?.summary?.text || post.text, 60)}
                        </Typography>
                          {['repostsCount', 'totalReactionCount', 'commentsCount'].map((metric, index) => (
                          <Typography className={classes.statLabel} style={{
                              fontSize: isMobile? '8px' : '10px',
                              fontWeight: 'bold',
                              width: isMobile? '50px' : '60px',
                              opacity: 0.6
                            }}>
                              {formatNumber(post[metric])}
                            </Typography>
                          ))}
                      </Box>
                    ))}
                  </Box>
                  </Grid>
                  </Grid>
                </Box>
              </CardWrapper>

              {/* Third Card - Categories and Top Posts */}
              <CardWrapper 
                cardRef={cardRefs.categories} 
                downloadFileName={IMAGE_FILENAMES[2]}
                onDownload={downloadCardAsImage}
                style={{
                  backgroundColor: BACKGROUND_COLORS[2].color,
                  backgroundImage: BACKGROUND_COLORS[2].image
                }}
                hideFooterImage={true}
                footerRightText={linkedinData?.profile?.full_name || auth.user?.name || ''}
              >
                <Box className={classes.cardContent}>
                  <Typography align="left" style={{ 
                    fontSize: isMobile? '18px' : '26px', 
                    fontWeight: 'bold',
                    marginBottom: '10px',
                    marginTop: '0px'
                  }}>
                    Top posts by category
                  </Typography>


                  {/* AI Insights card */}
                  <AIInsightCard 
                    text={linkedinData.categoryInsight || "Sometimes it's helpful to see your top posts by category. AI has identified 4 categories for your posts - Inspirational, Thought leadership, Educational and Promotional. Here's which posts under each category performed the best."}
                    icon="✨"
                  />
                  {/* End AI Insights card */}

                  <Box style={{ 
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    margin: '0px 20px',
                    height: '100px'
                  }}>
                    {/* Pie Chart */}
                    <Box style={{ width: '85%', height: '100%' }}>
                      <ResponsiveContainer>
                        <PieChart>
                          <Pie
                            data={Object.entries(CATEGORIES).map(([key, category]) => ({
                              name: category.name,
                              value: linkedinData.stats.categoryDistribution[key]
                            }))}
                            cx="50%"
                            cy="50%"
                            innerRadius={isMobile ? 10 : 30}
                            outerRadius={isMobile ? 40 : 50}
                            paddingAngle={2}
                            dataKey="value"
                          >
                            {Object.entries(CATEGORIES).map(([key, category]) => (
                              <Cell key={key} fill={category.color} />
                            ))}
                          </Pie>
                        </PieChart>
                      </ResponsiveContainer>
                    </Box>

                    {/* Legend */}
                    <Box style={{ 
                      width: '60%',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '2px'
                    }}>
                      {Object.entries(CATEGORIES).filter(([key, category]) => linkedinData.stats.categoryDistribution[key] > 0).map(([key, category]) => (
                        <Box key={key} style={{ 
                          display: 'flex', 
                          alignItems: 'center',
                          gap: '8px'
                        }}>
                          <Box style={{ 
                            width: '8px', 
                            height: '8px', 
                            backgroundColor: category.color,
                            borderRadius: '2px'
                          }} />
                          <Typography style={{ 
                            fontSize: isMobile? '7px' : '10px',
                            color: '#333'
                          }}>
                            {category.name} <span style={{ color: '#666' }}>{(linkedinData.stats.categoryDistribution[key] / linkedinData.stats.totalPosts * 100).toFixed(1)}%</span>
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>

                  {/* Category Posts */}
                  <Box style={{ margin: '20px' }}>
                    <Box style={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
                      {/* Chunk the categories into pairs */}
                      {chunk(Object.entries(CATEGORIES).filter(([key, category]) => linkedinData.stats.categoryDistribution[key] > 0), 3).map((categoryPair, pairIndex) => (
                        <Box key={pairIndex} style={{ flex: '1 1 45%' }}>
                          {categoryPair.map(([key, category]) => (
                            <Box key={key} style={{ marginBottom: '15px' }}>
                              <Typography style={{
                                backgroundColor: category.color,
                                padding: '4px 12px',
                                borderRadius: '4px',
                                display: 'inline-block',
                                fontSize: isMobile? '10px' : '12px',
                                fontWeight: 'bold',
                                marginBottom: '0px',
                                color: getContrastTextColor(category.color)
                              }}>
                                {category.name}
                              </Typography>
                              
                              <Box style={{
                                display: 'flex',
                                gap: '8px',
                                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                padding: '10px',
                                borderRadius: '8px',
                                boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                                color: '#666',
                                minHeight: isMobile ? '100px' : '110px',
                                maxHeight: isMobile ? '100px' : '110px'
                              }}>
                                {linkedinData.posts.filter(post => post.category === key).slice(0, 2).length > 0 ? (
                                  linkedinData.posts.filter(post => post.category === key).slice(0, 2).map((post, postIndex) => (
                                    <Box key={postIndex} style={{ flex: '1 1 50%' }}>
                                      <MUITooltip title='Click to view the post' placement="bottom">
                                      <Box 
                                        onClick={() => {
                                          setSelectedPost(post)
                                        }}
                                        style={{
                                          cursor: 'pointer',
                                          backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                          padding: '10px',
                                          borderRadius: '8px',
                                          border: '1px solid #EDEDED',
                                          // boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                                          color: '#666',
                                          height: '100%',
                                          display: 'flex',
                                          flexDirection: 'column',
                                          justifyContent: 'space-between',
                                          maxWidth: '180px'
                                        }}>
                                        <Typography style={{ 
                                          fontSize: isMobile? '7px' : '9px',
                                          marginBottom: '0px'
                                        }}>
                                          {truncate(post.text, 70)}
                                        </Typography>
                                        <Box style={{ 
                                          display: 'flex',
                                          gap: '8px',
                                          marginTop: '8px',
                                          color: '#666'
                                        }}>
                                          {[`❤️${post.totalReactionCount || 0}`, `💬${post.commentsCount || 0}`, `🔃 ${post.repostsCount || 0}`].map((stat, i) => (
                                            <Typography key={i} style={{ 
                                              fontSize: isMobile? '6px' : '8px',
                                              color: '#666'
                                            }}>
                                              {stat}
                                            </Typography>
                                          ))}
                                        </Box>
                                      </Box>
                                      </MUITooltip>
                                    </Box>
                                  ))
                                ) : (
                                  <Typography style={{
                                    fontSize: isMobile ? '7px' : '10px',
                                    color: '#999',
                                    width: '100%',
                                    textAlign: 'center',
                                    margin: 'auto'
                                  }}>
                                    There are no posts in this category
                                  </Typography>
                                )}
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Box>
              </CardWrapper>

              {/* Fourth Card - Top Comments */}
              <CardWrapper 
                cardRef={cardRefs.comments} 
                downloadFileName={IMAGE_FILENAMES[3]}
                onDownload={downloadCardAsImage}
                style={{
                  backgroundColor: BACKGROUND_COLORS[3].color,
                  backgroundImage: BACKGROUND_COLORS[3].image
                }}
                hideFooterImage={true}
                footerRightText={linkedinData?.profile?.full_name || auth.user?.name || ''}
              >
                <Box className={classes.cardContent}>
                  <Typography align="left" style={{ 
                    fontSize: isMobile? '18px' : '26px', 
                    fontWeight: 'bold',
                    marginBottom: '10px',
                    marginTop: '0px',
                    padding: '0 20px'
                  }}>
                    Top comments this month
                  </Typography>

                  <AIInsightCard 
                    text={linkedinData.commentsInsight || "Comments can help you understand what in your writing is resonating. Your followers have found your ideas around communication, the loading process in your unwrapped project and the impact of the product particularly helpful. Some users also mentioned they really liked the storytelling elements in the product."}
                    icon="✨"
                  />

                  <Box className={classes.commentsContainer}>
                    <Grid container spacing={2} className={classes.commentsGrid}>
                      {chunk(linkedinData?.comments.filter(comment => comment.author.username != linkedinData?.profile?.public_identifier) || [], Math.ceil((linkedinData?.comments.filter(comment => comment.text) || []).length / 2))
                        .map((commentChunk, i) => (
                          <Grid item xs={6} key={i}>
                            {commentChunk.map((comment, index) => (
                              <CommentCard
                                key={index} 
                                comment={comment}
                                onClick={() => {
                                  setSelectedPost(linkedinData.posts.find(post => post.urn === comment.postUrn))
                                }}
                              />
                            ))}
                          </Grid>
                        ))}
                    </Grid>
                  </Box>
                </Box>
              </CardWrapper>

              {/* Fifth Card - Timing & Engagers */}
              <CardWrapper 
                cardRef={cardRefs.timing} 
                downloadFileName={IMAGE_FILENAMES[0]}
                style={{
                  backgroundColor: BACKGROUND_COLORS[0].color,
                  backgroundImage: BACKGROUND_COLORS[0].image,
                }}
                onDownload={downloadCardAsImage}
                headerImage="/wrapped/wrapped-header.webp"
                hideFooterImage={true}
                footerRightText={linkedinData?.profile?.full_name || auth.user?.name || ''}
              >
                <Box className={classes.cardContent} >
                  <Typography align="left" style={{ 
                    fontSize: isMobile? '18px' : '26px', 
                    fontWeight: 'bold',
                    marginBottom: '10px',
                    marginTop: '0px',
                  }}>
                    Post timing stats & Engagers
                  </Typography>
                  <AIInsightCard 
                      text={linkedinData.performanceInsight || "Comments can help you understand what in your writing is resonating. Your followers have found your ideas around communication, the loading process in your unwrapped project and the impact of the product particularly helpful. Some users also mentioned they really liked the storytelling elements in the product."}
                      icon="✨"
                    />
                  <Grid container spacing={2} style={{padding: '0 20px'}}>

                  <Grid item xs={6}>
                  {/* Stats Grid */}
                  <PostingPatternHourlyCard data={linkedinData} />
                  </Grid>

                  <Grid item xs={6} >
                    <PostingPatternWeeklyCard data={linkedinData} />
                  </Grid>

                  {/* Consistent Engagers Section */}
                  <Grid item xs={12}>
                    <Typography style={{ 
                      fontSize: isMobile ? '14px' : '16px',
                      fontWeight: 'bold',
                      marginBottom: '12px'
                    }}>
                      Followers who comments on your content frequently
                    </Typography>
                    <Box style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: 2,
                      justifyContent: 'space-between'
                    }}>
                      {linkedinData?.stats?.topCommenters?.slice(0, 9)?.map((engager, index) => (
                          <EngagerCard 
                            key={index}
                            name={engager.profile?.firstName + " " + engager.profile?.LastName || ""}
                            onClick={() => {
                              window.open(`https://www.linkedin.com/in/${engager.profile?.linkedinUrl}`, '_blank');
                            }}
                            title={truncate(engager.profile?.title, 60) || ""}
                            avatar={engager.profile?.avatar || `https://api.dicebear.com/7.x/avataaars/svg?seed=${index}`}
                            engagementCount={engager.count || "0"}
                          />
                      ))}
                    </Box>
                  </Grid>
                  </Grid>
                </Box>
              </CardWrapper>

              {/* Sixth Card - Key Moments */}
              <CardWrapper 
                cardRef={cardRefs.moments} 
                downloadFileName={IMAGE_FILENAMES[5]}
                onDownload={downloadCardAsImage}
                style={{
                  backgroundColor: BACKGROUND_COLORS[1].color,
                  backgroundImage: BACKGROUND_COLORS[1].image,
                }}
                hideFooterImage={true}
                footerRightText={linkedinData?.profile?.full_name || auth.user?.name || ''}
              >
                <Box className={classes.cardContent}>
                  <Typography align="left" style={{ 
                    fontSize: isMobile? '24px' : '32px', 
                    fontWeight: 'bold',
                    marginBottom: '16px',
                    marginTop: '20px',
                    padding: '0 20px'
                  }}>
                    Key moments from January
                  </Typography>

                  <AIInsightCard 
                    text="Here’s a quick recap of the month to help with thinking about your content and what’s standing out. Your lessons are based on the key ideas from your top performing posts, while your recap is based on posts that "
                    icon="✨"
                  />

                  <Grid container spacing={2} style={{ padding: '0 20px' }}>
                    {/* Left Column - Lessons */}
                    <Grid item xs={6}>
                    <Box className={classes.statCard} style={{textAlign: 'left'}}>
                      <Typography style={{ 
                        fontSize: isMobile ? '7px' : '11px',
                        fontWeight: 'bold',
                        marginBottom: '12px',
                        color: '#AC592E'  // Orange color for "LESSONS"
                      }}>
                        LESSONS FROM THIS MONTH
                      </Typography>
                      
                      <Box>
                        {linkedinData?.lessons?.slice(0, 8).map((lesson, index) => (
                          <LessonItem 
                            key={index}
                            emoji={lesson.emoji}
                            text={lesson.text}
                          />
                        ))}
                      </Box>
                    </Box>
                    </Grid>

                    {/* Right Column - Activity Recap */}
                    <Grid item xs={6}>
                      <Box className={classes.statCard} style={{textAlign: 'left'}}>
                        <Typography style={{ 
                          fontSize: isMobile ? '7px' : '11px',
                          fontWeight: 'bold',
                          marginBottom: '12px',
                          color: '#AC592E'  // Orange color for "ACTIVITY"
                        }}>
                          YOUR ACTIVITY RECAP
                        </Typography>

                        <Box>
                          {linkedinData?.posts.sort((a, b) => b.totalReactionCount - a.totalReactionCount).slice(0, 8).sort((a, b) => new Date(a.postedDate) - new Date(b.postedDate)).map((post, index) => (
                            <ActivityItem 
                              key={index}
                              onClick={() => {
                                setSelectedPost(post)
                              }}
                              date={post.postedDate? new Date(post.postedDate).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' }) : ''}
                              text={post.summary?.text}
                              emoji={post.summary?.emoji}
                            />
                          ))}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </CardWrapper>

              {/* 7th card - Content Ideas card */ }
              <CardWrapper 
                cardRef={cardRefs.contentIdeas} 
                downloadFileName={IMAGE_FILENAMES[6]}
                onDownload={downloadCardAsImage}
                style={{
                  backgroundColor: BACKGROUND_COLORS[2].color,
                  backgroundImage: BACKGROUND_COLORS[2].image,
                }}
                hideFooterImage={true}
                footerRightText={linkedinData?.profile?.full_name || auth.user?.name || ''}
              >
                <Box className={classes.cardContent}>
                  <Typography align="left" style={{ 
                    fontSize: isMobile? '24px' : '32px', 
                    fontWeight: 'bold',
                    marginBottom: '4px',
                    marginTop: '20px',
                    padding: '0 20px'
                  }}>
                    Content ideas for you <span style={{ fontSize: '0.4em', verticalAlign: 'top', opacity: 0.8 }}>beta</span>
                  </Typography>

                  <AIInsightCard 
                    text="We've extracted some content ideas from your posts, comments and notes. These may serve as some potential ideas to go deeper into in February. This feature is currently in Beta though, so do let us know your feedback on them!"
                    icon="✨"
                  />

                  <Grid container spacing={2} style={{ padding: '0 20px' }}>
                    {/* From Posts */}
                    <Grid item xs={4}>
                      <Box style={{textAlign: 'left'}}>
                        <Typography style={{ 
                          fontSize: isMobile ? '9px' : '11px',
                          fontWeight: 'bold',
                          marginBottom: '12px',
                          color: '#6737E7'  // Purple color
                        }}>
                          FROM YOUR TOP POSTS
                        </Typography>
                        
                        <Box>
                          {/* {linkedinData?.posts
                            .flatMap(post => post.content_ideas || [])
                            .slice(0, 8)
                            .map((idea, index) => (
                              <ContentIdeaItem 
                                key={index}
                                text={idea}
                              />
                            ))} */}
                            {linkedinData?.contentIdeas?.posts?.slice(0, 8).map((idea, index) => (
                              <ContentIdeaItem 
                                key={index}
                                text={idea.hook}
                                tooltip={idea.supporting_point}
                              />
                            ))}
                        </Box>
                      </Box>
                    </Grid>

                    {/* From Comments */}
                    <Grid item xs={4}>
                      <Box style={{textAlign: 'left'}}>
                        <Typography style={{ 
                          fontSize: isMobile ? '9px' : '11px',
                          fontWeight: 'bold',
                          marginBottom: '12px',
                          color: '#FF4500'  // Orange color
                        }}>
                          FROM YOUR TOP COMMENTS
                        </Typography>
                        
                        <Box>
                          {linkedinData?.contentIdeas?.comments?.slice(0, 8).map((idea, index) => (
                            <ContentIdeaItem 
                              key={index}
                              text={idea.hook}
                              tooltip={idea.supporting_point}
                            />
                          ))}
                        </Box>
                      </Box>
                    </Grid>

                    {/* From Notes */}
                    <Grid item xs={4}>
                      <Box style={{textAlign: 'left'}}>
                        <Typography style={{ 
                          fontSize: isMobile ? '9px' : '11px',
                          fontWeight: 'bold',
                          marginBottom: '12px',
                          color: '#C49300'  // Gold color
                        }}>
                          FROM YOUR NOTES
                        </Typography>
                        
                        <Box>
                          {linkedinData?.contentIdeas?.notes?.slice(0, 8).map((idea, index) => (
                            <ContentIdeaItem 
                              key={index}
                              text={idea.hook}
                              tooltip={idea.supporting_point}
                            />
                          ))}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </CardWrapper>

              {/* Timeline Card */}
              {/* <CardWrapper 
                cardRef={cardRefs.timeline} 
                downloadFileName={IMAGE_FILENAMES[5]}
                onDownload={downloadCardAsImage}
                style={{
                  backgroundColor: BACKGROUND_COLORS[1].color,
                  backgroundImage: BACKGROUND_COLORS[1].image,
                }}
                hideFooterImage={true}
                footerRightText={linkedinData?.profile?.full_name || auth.user?.name || ''}
              >
                <Box className={classes.cardContent}>
                  <Typography align="center" style={{ 
                    fontSize: isMobile? '16px' : '24px', 
                    fontWeight: 'bold',
                    marginTop: '30px',
                    marginBottom: '0px',
                    padding: '0 20px',
                  }}>
                    You've made a lot of memories this year
                  </Typography>

                  <Typography align="center" style={{ 
                    fontSize: isMobile? '12px' : '16px',
                    padding: '0 20px',

                  }}>
                    Here's a recap with your top posts each month
                  </Typography>

                  <Box style={{ 
                    margin: '10px 40px',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    gap: '8px',
                  }}>
                    <Box style={{ 
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '8px',
                    }}>
                      {['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'].map((month, index) => (
                        <MonthlyTimelineItem 
                          key={index}
                          month={month}
                          summary={linkedinData?.monthlySummaries?.[index]}
                        />
                      ))}
                    </Box>

                    <Box style={{ 
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '8px',
                    }}>
                      {['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].map((month, index) => (
                        <MonthlyTimelineItem 
                          key={index + 6}
                          month={month}
                          summary={linkedinData?.monthlySummaries?.[index + 6]}
                        />
                      ))}
                    </Box>
                  </Box>
                </Box>
              </CardWrapper> */}

              {/* Lessons Card */}
              {/* <CardWrapper 
                cardRef={cardRefs.lessons} 
                downloadFileName={IMAGE_FILENAMES[6]}
                onDownload={downloadCardAsImage}
                style={{
                  backgroundColor: BACKGROUND_COLORS[1].color,
                  backgroundImage: BACKGROUND_COLORS[1].image,
                }}
                hideFooterImage={true}
                footerRightText={linkedinData?.profile?.full_name || auth.user?.name || ''}
              >
                <Box className={classes.cardContent}>
                  <Typography align="center" style={{ 
                    fontSize: isMobile? '16px' : '32px', 
                    fontWeight: 'bold',
                    marginTop: '40px',
                    padding: '0 20px',
                  }}>
                    Your top 10 lessons from 2024
                  </Typography>
                  <Typography align="center" style={{ 
                    fontSize: isMobile? '12px' : '16px',
                    padding: '0 20px',
                    marginBottom: '10px',
                  }}>
                    Save this for later 😉
                  </Typography>

                  <Box style={{ 
                    margin: isMobile? '5px' : '20px',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)', // Responsive grid
                    gap: isMobile? '8px' : '12px',
                  }}>
                    {(linkedinData?.lessons || []).map((lesson, index) => (
                      <Box 
                        key={index}
                        className={classes.statCard}
                        style={{ 
                          marginBottom: '0',
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px',
                          padding: isMobile? '6px' : '10px',
                          textAlign: 'left',
                          height: '100%',
                        }}
                      >
                        <Typography style={{ 
                          fontSize: '20px',
                          lineHeight: 1,
                          marginTop: '2px'
                        }}>
                          {lesson.emoji}
                        </Typography>
                        <Typography style={{ 
                          fontSize: isMobile? '8px' : '11px',
                          lineHeight: 1.4,
                        }}>
                          {lesson.text}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </CardWrapper> */}

              {/* Seventh Card - Cleve's AI Insights */}
              <CardWrapper 
                cardRef={cardRefs.insights} 
                downloadFileName={IMAGE_FILENAMES[7]}
                onDownload={downloadCardAsImage}
                style={{
                  backgroundColor: BACKGROUND_COLORS[1].color,
                  backgroundImage: BACKGROUND_COLORS[1].image,
                }}
                hideFooterImage={true}
                footerRightText={linkedinData?.profile?.full_name || auth.user?.name || ''}
              >
                  <Typography align="center" style={{ 
                    fontSize: isMobile? '20px' : '32px', 
                    fontWeight: 'bold',
                    marginTop: '30px',
                    padding: '0 20px',
                    marginBottom: '20px'
                  }}>
                    Key AI Insights from January
                  </Typography>

                  <Grid container spacing={0} style={{ padding: '0 20px' }}>
                    {/* Left Column - Lessons */}
                    <Grid item xs={12}>

                  <AIInsightCard style={{margin: '10px'}}>
                    <Typography style={{
                      fontSize: isMobile? '8px' : '12px',
                      lineHeight: 1.4,
                      whiteSpace: 'pre-wrap',
                      marginBottom: '10px',
                      fontWeight: 'bold'
                    }}>
                      What you're doing well:
                    </Typography>
                    <Typography style={{
                      fontSize: isMobile? '8px' : '12px',
                      lineHeight: 1.4,
                      whiteSpace: 'pre-wrap'
                    }}>
                      {linkedinData?.insightsSummary?.split('\n').map((line, i) => 
                        line.startsWith('#') ? 
                          <h3 key={i}>{line.replace('#', '')}</h3> : 
                          <p key={i}>{line}</p>
                      )}
                    </Typography>
                  </AIInsightCard>
                  </Grid>

                  {/* <Grid item xs={12}>
                  <AIInsightCard style={{margin: '10px'}}>
                    <Typography style={{
                      fontSize: isMobile? '8px' : '12px',
                      lineHeight: 1.4,
                      whiteSpace: 'pre-wrap',
                      marginBottom: '10px',
                      fontWeight: 'bold'
                    }}>
                      Actionable insights:
                    </Typography>
                    <Typography style={{
                      fontSize: isMobile? '8px' : '12px',
                      lineHeight: 1.4,
                      whiteSpace: 'pre-wrap'
                    }}>
                      Your LinkedIn growth shows steady progress. Posts celebrating achievements and sharing learnings naturally attract engagement. Posts about your personal journey and behind-the-scenes content resonate well with your audience. Your posts maintain a playful side of LinkedIn which helps you stand out. Your audience clearly feels seen and appreciated, which keeps them engaged and excited to interact.
                      </Typography>
                    </AIInsightCard>
                  </Grid> */}
                </Grid>
              </CardWrapper>

              {/* Eighth Card - Cleve's Letter */}
              <CardWrapper 
                cardRef={cardRefs.endNote} 
                downloadFileName={IMAGE_FILENAMES[7]}
                onDownload={downloadCardAsImage}
                style={{
                  backgroundColor: BACKGROUND_COLORS[1].color,
                  backgroundImage: BACKGROUND_COLORS[1].image,
                }}
                hideFooterImage={true}
                footerRightText={linkedinData?.profile?.full_name || auth.user?.name || ''}
              >
                <Box className={classes.cardContent}>
                  <Typography align="center" style={{ 
                    fontSize: isMobile? '20px' : '32px', 
                    fontWeight: 'bold',
                    marginTop: '30px',
                    padding: '0 20px',
                  }}>
                    A quick note from us
                  </Typography>

                  <Box style={{
                    margin: isMobile? '10px 10px' : '40px 20px',
                    padding: '24px',
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    borderRadius: '12px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                  }}>
                    <Typography style={{
                      color: '#000',
                      whiteSpace: 'pre-wrap',
                      fontSize: isMobile? '8px' : '12px',
                      lineHeight: 1.5,
                    }}>
                      {`Hey ${linkedinData?.profile?.first_name},

Thanks for being a part of our journey and for being among the first 500 people to receive Stats! You being here says a lot about your commitment towards your brand and strengthening your impact and influence in 2025.

We built Stats to empower you with the data you need to make little informed tweaks in your strategy to help you drive more visibility to your brand and be able to continue writing with more clarity.

This is our first (of many) interactive Stats Reports –  Each one will be better than the last, and we’d love to hear your feedback to improve these faster too. Just drop us a text at the bottom right corner.

We can’t wait to keep building Stats & Cleve with you.

And as always - keep creating & have an awesome 2025!

Cheers,
The Cleve Team`}
                    </Typography>
                  </Box>
                </Box>
              </CardWrapper>
              
              {/* <PostingPatternStreaksCard data={linkedinData} /> */}
              {/* <PostingPatternHourlyCard data={linkedinData} /> */}
              {/* <PostingPatternWeeklyCard data={linkedinData} /> */}
        </Box>
        {(isPreprocessing || !processedContent.pdf) && 
      <Box>
        <Typography
          variant="body2"
          align="center"
          style={{
            // color: '#FFFFFF',
            margin: isMobile ? '15px 20px 10px' : '15px 300px 10px',
            opacity: 0.8
          }}
        >
          In ~30 seconds your shareable PDF will finish processing and the experience will be smoother.
        </Typography>
      </Box>
      }
        <Box className={classes.sharingSteps} 
          style={{ 
            marginTop: '30px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            border: 'none',
            backgroundColor: 'hsla(175,0%,100%,1)',
                  backgroundImage: `radial-gradient(at 7% 92%, hsla(345,100%,78%,0.55) 0px, transparent 50%),
radial-gradient(at 38% 31%, hsla(345,100%,78%,0.55) 0px, transparent 50%),
radial-gradient(at 74% 83%, hsla(252,65%,72%,0.52) 0px, transparent 50%);`,
            opacity: 0.8,
            color: '#000'
          }}
        >
        <Typography 
          variant="h6" 
          style={{ 
            marginBottom: theme.spacing(2),
            textAlign: 'left'
          }}
        >
          Be the first to share it with your friends:
        </Typography>
        {SHARING_STEPS.map((step) => (
          <Box key={step.step} className={classes.stepContainer}>
            <Typography className={classes.stepText}>
              {step.step}. {step.text}
            </Typography>
            {step.action && (
              <Button
                variant="contained"
                className={classes.stepButton}
                startIcon={downloadingAll.pdf ? <CircularProgress size={20} color="inherit" /> : step.icon}
                onClick={saveAllAsPDF}
                disabled={isPreprocessing || downloadingAll.pdf || downloadingAll.images }
              >
                {isPreprocessing ? 'Processing PDF...' : 
                  (downloadingAll.pdf ? 'Downloading...' : step.action)}
              </Button>
            )}
            {step.buttons && (
              <Box className={classes.stepButtons}>
                {step.buttons.map((button, index) => (
                  <Button
                    key={index}
                    variant="contained"
                    className={button.variant === 'linkedin' ? classes.linkedinButton : classes.defaultButton}
                    startIcon={button.variant === 'linkedin' ? 
                      <LinkedIn /> : 
                      (copiedState ? <CheckCircle /> : <PostAdd />)}
                    onClick={button.variant === 'linkedin' ? 
                      () => {
                        const caption = "I just got my LinkedIn Content Stats from Cleve. Check it out!"//generateWrappedCaption(linkedinData);
                        window.open(getShareUrl(caption), '_blank');
                      } : 
                      handleCopyCaption}
                  >
                    {button.variant === 'linkedin' ? 
                      button.label : 
                      (copiedState ? "Copied!" : button.label)}
                  </Button>
                ))}
              </Box>
            )}
          </Box>
        ))}

        
      </Box>
      <Box className={classes.otherSharingOptions}>
        <Typography variant="h6" className={classes.otherSharingTitle}>
          Other sharing options
        </Typography>
        <Box className={classes.otherSharingButtons}>
          <Button
            variant="contained"
            color="secondary"
            startIcon={isPreprocessing || downloadingAll.images ? <CircularProgress size={20} color="inherit" /> : <GetApp />}
            onClick={downloadAllImages}
            disabled={isPreprocessing || downloadingAll.images}
          >
            {isPreprocessing? 'Processing images...' : 
              (downloadingAll.images ? 'Downloading...' : 'Save (images)')}
          </Button>
          {/* <Button
            variant="contained"
            color="secondary"
            startIcon={copiedLinkState ? <CheckCircle /> : <Link />}
            onClick={() => {
              const publicIdentifier = linkedinUrl.split('/in/')[1]?.replace(/\/$/, '') || '';
              const shareUrl = `https://notes.cleve.ai/stats/${publicIdentifier}/${linkedinData.year}/${linkedinData.month}`;
              navigator.clipboard.writeText(shareUrl);
              setCopiedLinkState(true);
              setTimeout(() => {
                setCopiedLinkState(false);
              }, 2000); // Reset after 2 seconds
              // toast.success('Link copied to clipboard!');
            }}
          >
            {copiedLinkState ? "Copied!" : "Copy link"}
          </Button> */}
        </Box>
      </Box>

      {/* Share on social media */}
      {/* <Box className={classes.otherSharingOptions} style={{marginTop: '50px'}}>
        <Typography variant="h6" className={classes.otherSharingTitle} style={{marginBottom: '20px', }}>
          Recommend this tool to your friends/boss/colleagues
        </Typography>
        <Box className={classes.otherSharingButtons}>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<WhatsApp />}
            onClick={() => window.open('https://api.whatsapp.com/send?phone=&text=I%20found%20this%20AI%20tool%20that%20studies%20my%20entire%20year%27s%20worth%20of%20LinkedIn%20posts%20and%20gives%20a%20summary%20of%20key%20insights.%20Thought%20you%20might%20find%20it%20interesting%20-%20It%27s%20fast%2C%20easy%20and%20free%3A%20www.cleve.ai%2Funwrapped', '_blank')}
          >
            Whatsapp
          </Button>

          <Button
            variant="contained" 
            color="secondary"
            startIcon={<Facebook />}
            onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent('https://www.cleve.ai/unwrapped')}&quote=${encodeURIComponent('I found this AI tool that studies my entire year\'s worth of LinkedIn posts and gives a summary of key insights. Thought you might find it interesting - It\'s fast, easy and free!')}`, '_blank')}
          >
            Facebook
          </Button>

          <Button
            variant="contained"
            color="secondary" 
            startIcon={<Twitter />}
            onClick={() => window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent('I found this AI tool that studies my entire year\'s worth of LinkedIn posts and gives a summary of key insights. Thought you might find it interesting - It\'s fast, easy and free!')} ${encodeURIComponent('https://www.cleve.ai/unwrapped')}`, '_blank')}
          >
            X
          </Button>
        </Box>
      </Box> */}

      {/* Add CTA before FAQ section */}
      {/* <UnwrappedCTA 
        linkedinUrl={linkedinUrl}
        email={email}
        firstName={linkedinData?.profile?.first_name || linkedinData?.profile?.full_name || ''}
        className={classes.ctaSection}
        linkedinData={linkedinData}
        selectedVariation={selectedCTAVariation}
      /> */}

      {/* FAQ Section */}
      {/* <Box style={{ padding: '20px', maxWidth: '800px', margin: '40px auto', color: '#fff' }}>
        <Typography variant="h1" style={{
          fontSize: isMobile ? '32px' : '48px',
          fontWeight: 'bold',
          marginBottom: '40px',
          textAlign: 'center'
        }}>
          FAQ
        </Typography>
        {FAQ_DATA.map((faq, index) => (
          <Accordion 
            key={index}
            style={{
              backgroundColor: '#ededed',
              color: '#000',
              marginBottom: '10px',
              borderRadius: '8px',
              '&:before': {
                display: 'none',
              }
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore style={{color: '#000'}} />}
              style={{
                borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
              }}
            >
              <Typography 
                style={{ 
                  fontWeight: 'bold',
                  fontSize: isMobile? '14px' : '20px'
                }}
              >
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography 
                style={{ 
                  whiteSpace: 'pre-line',
                  fontSize: isMobile? '12px' : '16px',
                  lineHeight: 1.6
                }}
              >
                {faq.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box> */}
      </>
      }
      </Box>
      {error && (
        <Box mb={4} p={2} bgcolor="error.main" color="error.contrastText" borderRadius={1}>
          <Typography>{error}</Typography>
            </Box>
      )}

      {renderPostDialog()}
      <NoteNav/>
    </Box>
  );
};

export default requireAuth(LinkedinMonthlyStats);
