import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { useForm, Controller } from "react-hook-form";
import { useAuth } from "./../util/auth";
import { updateUser } from "./../util/db";
import { TOPICS, GOALS, LANGUAGES, TONES } from "./../util/enums";
import { Link } from "../util/router";
import { Warning24Regular } from "@fluentui/react-icons";
import { SvgIcon } from "@material-ui/core";
import {Typography} from "@material-ui/core";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ControlledAutocomplete = ({ 
  options = [], 
  renderInput, 
  getOptionLabel, 
  control, 
  defaultValue, 
  name, 
  renderOption,
  getOptionSelected,
  multiple = false
}) => {
  return (
    <Controller
      render={({ onChange, ...props }) => (
        <Autocomplete
          multiple={multiple}
          options={options}
          getOptionLabel={getOptionLabel}
          getOptionSelected={getOptionSelected}
          renderOption={renderOption}
          renderInput={renderInput}
          disableCloseOnSelect
          onChange={(event, value) => onChange(value)}
          {...props}
        />
      )}
      //onChange={([, data]) => (data.map(data.title))} // Ensure this handler correctly interprets the data format
      defaultValue={defaultValue}
      name={name}
      control={control}
    />
  );
};

function SettingsGeneral(props) {
  const auth = useAuth();
  const [pending, setPending] = useState(false);
  const { register, handleSubmit, errors, control } = useForm();

  const onSubmit = async (data) => {
    setPending(true);
    try {
        // data.goals = data.goals.map(goal => goal.title);
        // data.topics = data.topics.map(topic => topic.title);
        console.log(data)
        await auth.updateProfile({name: data.name, email: data.email});
        await updateUser(auth.user.uid, data);
        props.onStatus({
            type: "success",
            message: "Your profile has been updated",
        });
    } catch (error) {
        if (error.code === "auth/requires-recent-login") {
            props.onStatus({
                type: "requires-recent-login",
                callback: () => onSubmit(data),
            });
        } else {
            props.onStatus({
                type: "error",
                message: error.message,
            });
        }
    } finally {
        setPending(false);
    }
  };

  console.log(auth?.user)

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        {
          (!auth?.user?.linkedinAccessToken || auth?.user?.linkedinUrl.includes('undefined')) &&
          <Grid item xs={12}>
            <Button
              variant="contained"
              style={{ backgroundColor: '#0077B5', color: '#ffffff' }}
              size="large"
              component={Link}
              to="/onboarding?step=1"
              fullWidth={true}
              endIcon={<SvgIcon><Warning24Regular /></SvgIcon>}
            >
              <span>Add Your LinkedIn Profile</span>
            </Button>
            <Typography variant="subtitle2" style={{ marginTop: '8px', textAlign: 'center', fontSize: '0.6rem' }}>
              Users who add LinkedIn gain a 70% more personalised experience
            </Typography>
          </Grid>
        }
        

        
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            type="text"
            label="Name"
            name="name"
            placeholder="Name"
            defaultValue={auth.user.name}
            error={errors.name ? true : false}
            helperText={errors.name && errors.name.message}
            fullWidth={true}
            inputRef={register({
              required: "Please enter your name",
            })}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            type="email"
            label="Email"
            name="email"
            placeholder="user@example.com"
            defaultValue={auth.user.email}
            error={errors.email ? true : false}
            helperText={errors.email && errors.email.message}
            fullWidth={true}
            inputRef={register({
              required: "Please enter your email",
            })}
          />
        </Grid>
        {auth?.user?.linkedinUrl && <Grid item xs={12}>
          <TextField
            variant="outlined"
            type="text"
            label="Linkedin Profile URL"
            name="linkedinUrl"
            placeholder="https://www.linkedin.com/in/your-profile"
            defaultValue={auth.user.linkedinUrl}
            error={errors.linkedinUrl ? true : false}
            helperText={errors.linkedinUrl && errors.linkedinUrl.message}
            fullWidth={true}
            inputRef={register({
              required: "Please enter your linkedin URL",
            })}
          />
        </Grid> }
        <Grid item xs={12}>
          <ControlledAutocomplete
            multiple
            control={control}
            name="goals"
            options={GOALS}
            getOptionLabel={(option) => `${option}`}
            //getOptionSelected={(option, value) => option.title === value.title}
            renderInput={(params) => <TextField {...params} variant="outlined" label="Goals" placeholder="Select goals" />}
            defaultValue={auth.user.goals} // Set to an empty array or an array of default selected options
            renderOption={(option, {selected}) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  checked={selected}
                  style={{ marginRight: 8 }}
                />
                {option}
              </React.Fragment>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledAutocomplete
            multiple
            control={control}
            name="topics"
            options={TOPICS.flatMap(topic => topic.subtopics)}
            getOptionLabel={(option) => `${option}`}
            //getOptionSelected={(option, value) => option.title === value.title}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Topics" placeholder="Select topics" />
            )}
            defaultValue={auth.user.topics}
            renderOption={(option, {selected}) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  checked={selected}
                  style={{ marginRight: 8 }}
                />
                {option}
              </React.Fragment>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            label="Audience"
            name="audience"
            fullWidth={true}
            defaultValue={auth.user.audience || ''}
            inputRef={register}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            label="Description"
            name="description"
            defaultValue={auth.user.description || ''}
            fullWidth={true}
            multiline
            rows={4}
            inputRef={register}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledAutocomplete
            control={control}
            name="tone"
            options={TONES}
            getOptionLabel={(option) => `${option}`}
            //getOptionSelected={(option, value) => option.title === value.title}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Tone" placeholder="Select Tone" />
            )}
            defaultValue={auth?.user?.tone || 'Default'}
            renderOption={(option, {selected}) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  checked={selected}
                  style={{ marginRight: 8 }}
                />
                {option}
              </React.Fragment>
            )}
          />
          </Grid>
          <Grid item xs={12}>
            <ControlledAutocomplete
              control={control}
              name="language"
              options={LANGUAGES}
              getOptionLabel={(option) => `${option.name} - ${option.native}`}
              getOptionSelected={(option, value) => option.code === value.code }
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Default Language Output" placeholder="Select Language" />
              )}
              defaultValue={auth?.user?.language || {name: "English", code: "en", native: "English"}}
              renderOption={(option, {selected}) => (
                <React.Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    checked={selected}
                    style={{ marginRight: 8 }}
                  />
                  {`${option.name} - ${option.native}`}
                </React.Fragment>
              )}
            />
          </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            disabled={pending}
            fullWidth={true}
          >
            {pending ? <CircularProgress size={28} /> : <span>Save</span>}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default SettingsGeneral;
