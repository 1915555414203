import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Chip,
} from '@material-ui/core';
import { Check as CheckIcon } from '@material-ui/icons';
import { useAuth } from '../util/auth';
import { Link, useRouter } from "./../util/router";
import { styled } from '@material-ui/core/styles';

const pricingLink = {
  'lite': '/purchase/lite',
  'monthly': '/purchase/plus',
  'yearly': '/purchase/premium'
}

const plans = {
  lite: {
    name: "Lite",
    price: 10,
    perks: [
      "Monthly content analytics report",
      "20 free AI posts credits per month",
      
    ]
  },
  pro: {
    name: "Pro",
    monthlyPrice: 29,
    yearlyPrice: 20,
    perks: [
      "Monthly content analytics report",
      "Unlimited AI editing",
      "Unlimited AI generated posts",
      "Unlimited Voice Mode",
      "Cleve vision - Repurpose Images",
    ]
  }
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    zIndex: 99,
  },
  title: {
    padding: 0,
    textAlign: 'center',
    marginBottom: theme.spacing(1),
    '& h2': {
      fontWeight: 600,
    },
  },
  subtitle: {
    marginBottom: theme.spacing(3),
    color: theme.palette.text.primary,
    opacity: 0.6,
  },
  buttonGroup: {
    display: 'flex',
    width: '100%',
    marginBottom: theme.spacing(3),
  },
  button: {
    width: '50%',
    border: '2px solid #c0c0c088',
    borderRadius: 4,
    textTransform: 'none',
    fontSize: '0.875rem',
    padding: theme.spacing(1),
    '&:first-child': {
      marginRight: theme.spacing(1),
    },
    '&.selected': {
      borderColor: theme.palette.primary.main,
    },
  },
  saveText: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: '0.75rem',
    marginLeft: theme.spacing(1),
  },
  listItem: {
    padding: 0,
  },
  checkIcon: {
    minWidth: 'auto',
    marginRight: theme.spacing(1),
  },
  price: {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  upgradeButton: {
    backgroundColor: theme.palette.primary.main,
    color: 'black',
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: '1rem',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      boxShadow: 'none',
    },
  },
  planBox: {
    background: theme.paper,
    borderRadius: '10px',
    padding: theme.spacing(3),
    minWidth: {
      xs: '280px',
      sm: '320px',
      md: '340px'
    },
    width: '100%',
    maxWidth: '340px',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    border: '1px solid #ccc',
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
  },
  plansContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: {
      xs: 'column',
      md: 'row'
    },
    alignItems: 'flex-start',
    width: '100%',
    maxWidth: '900px',
    margin: '0 auto'
  },
  singlePlanContainer: {
    width: '100%',
    maxWidth: {
      xs: '100%',
      sm: '400px'
    },
    margin: '0 auto'
  },
  perkItem: {
    marginTop: 20,
    maxWidth: '250px',
  },
  priceText: {
    fontSize: '2.3rem',
    fontWeight: 'bold',
    // color: '#E22B75',
  },
  saveBadge: {
    backgroundColor: '#1BA4FF',
    color: 'white',
    padding: '4px 8px',
    borderRadius: '12px',
    fontSize: '0.75rem',
    position: 'absolute',
    top: '-10px',
    left: '40px',
  }
}));

const BillingSwitch = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '30px',
  padding: '4px',
  width: 'fit-content',
  margin: '0 auto',
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(1),
}));

const BillingOption = styled(Button)(({ theme, active }) => ({
  padding: '8px 16px',
  borderRadius: '24px',
  backgroundColor: active ? 'white' : 'transparent',
  color: active ? '#000' : theme.palette.text.secondary,
  boxShadow: active ? '0 2px 4px rgba(0,0,0,0.1)' : 'none',
  '&:hover': {
    backgroundColor: active ? 'white' : 'rgba(0,0,0,0.05)',
  },
  transition: 'all 0.2s ease',
  textTransform: 'none',
  marginRight: '4px',
  marginLeft: '4px',
}));

const PricingModal = ({ title, open, onClose }) => {
  const classes = useStyles();
  const [billingCycle, setBillingCycle] = useState('yearly');
  const auth = useAuth();
  const router = useRouter();

  const handleBillingCycleChange = (newBillingCycle) => {
    setBillingCycle(newBillingCycle);
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      maxWidth="lg" 
      PaperProps={{
        style: {
          margin: '16px',
          width: billingCycle === 'yearly' ? '100%' : '600px',
          maxWidth: '900px'
        }
      }}
    >
      <DialogContent className={classes.root}>
        <DialogTitle disableTypography className={classes.title}>
          <Typography variant="h5" component="h2">
            {title || "Upgrade to continue"} 
          </Typography>
          
        <Link to="/pricing" className={classes.subtitle}>
          <Typography variant="body2" style={{ textAlign: 'center', marginTop: '5px'}}>
            Learn more about pricing plans
          </Typography>
        </Link>
        </DialogTitle>

        <BillingSwitch>
          <BillingOption
            active={billingCycle === 'monthly'}
            onClick={() => handleBillingCycleChange('monthly')}
            disableRipple
          >
            Pay monthly
          </BillingOption>
          <BillingOption
            active={billingCycle === 'yearly'}
            onClick={() => handleBillingCycleChange('yearly')}
            disableRipple
          >
            Pay yearly
          </BillingOption>
        </BillingSwitch>

        {billingCycle === 'yearly' ? (
          <Box className={classes.plansContainer} >
            <Box className={classes.planBox}>
              <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Typography style={{ color: 'text.primary', fontWeight: '800' }}>
                  {plans.lite.name}
                </Typography>
              </Box>

              <Box my={1} sx={{ display: 'inline-flex', alignItems: 'center', gap: 1 }}>
                <Typography className={classes.priceText}>
                  ${plans.lite.price}
                </Typography>
                <Typography variant="body1" sx={{ fontSize: '0.875rem', fontWeight: 'medium' }}>
                  /month
                </Typography>
              </Box>

              

              {plans.lite.perks.map((perk) => (
                <div key={perk} className={classes.perkItem}>
                  ✔️&nbsp;{perk}
                </div>
              ))}

              <Button
                variant="contained"
                fullWidth
                className={classes.upgradeButton}
                disableElevation
                component={Link}
                to={`${auth.user? '' : '/auth/signup?next='}${pricingLink.lite}`}
                style={{ marginTop: '20px' }}
              >
                <strong>Upgrade to {plans.lite.name}</strong>
              </Button>
            </Box>

            <Box className={classes.planBox}>
              <Chip label="30% off" className={classes.saveBadge} size="small" />
              <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Typography style={{ color: 'text.primary', fontWeight: '800' }}>
                  {plans.pro.name}
                </Typography>
              </Box>

              <Box my={1} sx={{ display: 'inline-flex', alignItems: 'center', gap: 1 }}>
                <Typography className={classes.priceText}>
                  ${plans.pro.yearlyPrice}
                </Typography>
                <Typography variant="body1" sx={{ fontSize: '0.875rem', fontWeight: 'medium' }}>
                  /month
                </Typography>
              </Box>

              

              {plans.pro.perks.map((perk) => (
                <div key={perk} className={classes.perkItem}>
                  ✔️&nbsp;{perk}
                </div>
              ))}

              <Button
                variant="contained"
                fullWidth
                className={classes.upgradeButton}
                disableElevation
                component={Link}
                to={`${auth.user? '' : '/auth/signup?next='}${pricingLink.yearly}`}
                style={{ marginTop: '20px' }}
              >
                <strong>Upgrade to {plans.pro.name}</strong>
              </Button>
            </Box>
          </Box>
        ) : (
          <Box className={classes.plansContainer}>
            <Box className={classes.planBox}>
              <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Typography style={{ color: 'text.primary', fontWeight: '800' }}>
                  {plans.pro.name}
                </Typography>
              </Box>

              <Box my={1} sx={{ display: 'inline-flex', alignItems: 'center', gap: 1 }}>
                <Typography className={classes.priceText}>
                  ${plans.pro.monthlyPrice}
                </Typography>
                <Typography variant="body1" sx={{ fontSize: '0.875rem', fontWeight: 'medium' }}>
                  /month
                </Typography>
              </Box>

              

              {plans.pro.perks.map((perk) => (
                <div key={perk} className={classes.perkItem}>
                  ✔️&nbsp;{perk}
                </div>
              ))}

            <Button
                variant="contained"
                fullWidth
                className={classes.upgradeButton}
                disableElevation
                component={Link}
                to={`${auth.user? '' : '/auth/signup?next='}${pricingLink.monthly}`}
                style={{ marginTop: '20px' }}
              >
                <strong>Upgrade to {plans.pro.name}</strong>
              </Button>
            </Box>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default PricingModal;