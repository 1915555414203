import React, { useState, useRef, useEffect } from 'react';
import { 
  Typography, 
  Box,
  Grid,
  Avatar,
  makeStyles,
  useMediaQuery,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
import { apiRequest, formatNumber, truncate, linkedinUrlToId } from '../util/util.js';
import { useAuth } from '../util/auth.js';
import { useRouter, Link } from '../util/router.js';
import dayjs from 'dayjs';
import CalHeatmap from 'cal-heatmap';
import Tooltip from 'cal-heatmap/plugins/Tooltip';
import 'cal-heatmap/cal-heatmap.css';
import Meta from '../components/Meta';
import NoteNav from '../components/NoteNav.js';
import SubscribeModal from '../components/SubcribeModal.js';
import { toast } from 'react-toastify';
import analytics from '../util/analytics.js';


const useStyles = makeStyles((theme) => ({
  '@global': {
    '.ch-plugin-calendar-label-text': {
      fill: '#000',
    },
    '#ch-tooltip, #ch-tooltip[data-theme="dark"]': {
      backgroundColor: '#fff',
      color: '#19083d',
      padding: theme.spacing(0),
      border: '1px solid rgba(0,0,0,0.1)',
      boxShadow: '0px 0px 8px rgba(0,0,0,0.1)',
      borderRadius: '8px',
    }
  },
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100vh',
    maxWidth: '80vw',
    padding: theme.spacing(2),
    marginLeft: '250px',
    marginRight: '40px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0px',
      marginRight: '0px',
    },
  },
  statCard: {
    padding: theme.spacing(1.5),
    marginBottom: theme.spacing(1),
    border: '1px solid rgba(0,0,0,0.1)',
    borderRadius: '8px',
    textAlign: 'center',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  },
  statValue: {
    fontSize: '2rem',
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.5rem',
    },
  },
  statLabel: {
    fontSize: '0.7rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.75rem',
    },
  },
  heatmapContainer: {
    maxWidth: '100%',
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    // border: '1px solid rgba(0,0,0,0.1)',
    // boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    // '& .ch-container': {
    //   margin: '0 auto',
    // },
  },
  contentItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
    borderBottom: '1px solid rgba(0,0,0,0.1)',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  statGrid: {
    display: 'flex',
    gap: theme.spacing(3),
    marginBottom: theme.spacing(4),
    flexWrap: 'wrap',
    maxWidth: '85vw',
  },
  statItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  },
  statIcon: {
    fontSize: '1.5rem',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    gap: theme.spacing(0.5),
    width: '80px',
  },
  reportsSection: {
    marginTop: theme.spacing(4),
  },
  reportGrid: {
    display: 'flex',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
    // overflowX: 'auto',
  },
  reportCard: {
    borderRadius: '12px',
    padding: theme.spacing(2),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
    },
  },
  reportImage: {
    width: '120px',
    height: '150px',
    objectFit: 'cover',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    marginBottom: theme.spacing(1),
  },
  topPostsGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  postCard: {
    padding: theme.spacing(2),
    border: '1px solid rgba(0,0,0,0.1)',
    borderRadius: '8px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  postText: {
    fontSize: '0.9rem',
    lineHeight: '1.4',
    flex: 1,
    whiteSpace: 'pre-wrap'
  },
  postStats: {
    display: 'flex',
    gap: theme.spacing(2),
    fontSize: '0.85rem',
    color: theme.palette.text.secondary,
    borderTop: '1px solid rgba(0,0,0,0.1)',
    paddingTop: theme.spacing(1),
    marginTop: 'auto',
  },
  seeMoreButton: {
    textAlign: 'center',
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
  },
}));

const StatsPage = () => {
  const classes = useStyles();
  const auth = useAuth();
  const router = useRouter();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [statsData, setStatsData] = useState(null);
  const [calendar, setCalendar] = useState(null);
  const calendarRef = useRef(null);
  const [subscribeModalOpen, setSubscribeModalOpen] = useState(false)
  const [selectedPost, setSelectedPost] = useState(null);

  useEffect(() => {
    if (auth.user?.linkedinUrl) {
      fetchStatsData(auth.user.linkedinUrl);
    }
  }, [auth.user]);

  useEffect(() => {
    console.log('statsData', statsData)
    if (statsData?.lastYearData?.stats?.heatmapData && calendarRef.current) {
      // Clear any existing calendar content
      if (calendarRef.current.firstChild) {
        calendarRef.current.innerHTML = '';
      }
      initializeCalendar();
    }
  }, [statsData]);

  const fetchStatsData = async (linkedinUrl) => {
    try {
      // Check localStorage first
      const cachedData = localStorage.getItem('statsData');
      if (cachedData) {
        const { currentMonthData, lastYearData, timestamp } = JSON.parse(cachedData);
        const hoursSinceCache = (Date.now() - timestamp) / (1000 * 60 * 60);
        
        // Use cache if less than 1000 hours old
        if (hoursSinceCache < 1) {
          setStatsData({currentMonthData, lastYearData});
          console.log({currentMonthData, lastYearData})
          return;
        }
      }

      // Fetch fresh data if no cache or cache expired
      const currentMonthData = await apiRequest('linkedin-stats', 'POST', {
        action: 'stats', 
        user: auth.user,
        linkedinAccessToken: auth.user?.linkedinAccessToken,
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
        linkedinUrl: auth?.user?.linkedinUrl,
        email: auth.user?.email,
      });

      const lastYearData = await apiRequest('linkedin-unwrapped', 'POST', {
        action: 'wrapped',
        user: auth.user,
        linkedinAccessToken: auth.user?.linkedinAccessToken,
        linkedinUrl: auth?.user?.linkedinUrl,
        email: auth.user?.email,
      });

      // Cache the new data with timestamp
      localStorage.setItem('statsData', JSON.stringify({
        currentMonthData,
        lastYearData,
        timestamp: Date.now()
      }));

      setStatsData({currentMonthData, lastYearData});
    } catch (error) {
      console.error('Error fetching stats:', error);
    }
  };

  const initializeCalendar = async () => {
    // Remove previous calendar instance if it exists
    if (calendar) {
      calendar.destroy();
      setCalendar(null);
    }

    // Create new calendar instance
    const cal = new CalHeatmap();
    await cal.paint({
      itemSelector: calendarRef.current,
      domain: {
        type: 'month',
        gutter: isMobile ? -4 : -10,
        label: { text: 'MMM' }
      },
      subDomain: {
        type: 'day',
        width: isMobile ? 4 : 10,
        height: isMobile ? 4 : 10,
        gutter: 2,
      },
      date: {
        start: new Date(Date.UTC(new Date().getFullYear() - 1, new Date().getMonth(), 1)),
      },
      data: {
        source: [...statsData.lastYearData.stats.heatmapData, ...statsData.currentMonthData.stats.heatmapData],
        x: 'date',
        y: 'value',
        defaultValue: 0,
      },
      scale: {
        color: {
          type: 'linear',
          range: [theme.palette.background.paper, '#5500FF', '#00F6FF'],
          domain: [0, 200, 1000],
        },
      },
    }, [
      [Tooltip, {
        text: (date, value) => {
          const post = [...statsData.lastYearData.stats.heatmapData, ...statsData.currentMonthData.stats.heatmapData]?.find(
            post => post.date === dayjs(date).format('YYYY-MM-DD')
          );
          if (!post?.posts?.[0]) return '';
          
          return `
            <div style="padding: 10px;">
              <b>${dayjs(date).format('MMM D, YYYY')}</b>
              <br/>⭐ ${value} engagement
              <br/>"${truncate(post.posts[0].text, 100)}"
            </div>
          `;
        }
      }],
    ]);

    cal.on('click', (event, timestamp, value) => {
      const date = dayjs(timestamp).format('YYYY-MM-DD');
      const post = statsData.lastYearData.stats.heatmapData?.find(
        post => post.date === date
      );
      if (post?.posts?.[0]) {
        setSelectedPost(post.posts[0]);
      }
    });

    setCalendar(cal);
  };

  const renderPostDialog = () => (
    <Dialog 
      open={!!selectedPost}
      onClose={() => setSelectedPost(null)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        {selectedPost?.postedDate && dayjs(selectedPost.postedDate).format('MMMM D, YYYY')}
      </DialogTitle>
      <DialogContent>
        <Typography style={{ whiteSpace: 'pre-wrap', marginBottom: '16px' }}>
          {selectedPost?.text}
        </Typography>
        {selectedPost?.image?.[0] && (
          <Box mt={2}>
            <img 
              src={selectedPost.image[0].url}
              alt="Post content"
              style={{ maxWidth: '100%', height: 'auto' }}
            />
          </Box>
        )}
        <Box mt={2}>
          <Typography variant="body2" color="textSecondary">
            ⭐ {formatNumber(selectedPost?.totalReactionCount || 0)} reactions
            • 💬 {formatNumber(selectedPost?.commentsCount || 0)} comments  
            • 🔄 {formatNumber(selectedPost?.repostsCount || 0)} reposts
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        {selectedPost?.postUrl && (
          <Button 
            href={selectedPost.postUrl}
            target="_blank"
            color="primary"
          >
            View on LinkedIn
          </Button>
        )}
        <Button onClick={() => setSelectedPost(null)} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Box className={classes.root}>
      <Meta 
        title="Cleve Stats" 
        description="Your LinkedIn Stats"
      />

      <Typography variant="h5" style={{ fontWeight: 'bold', marginBottom: theme.spacing(3) }}>
        Cleve Stats
      </Typography>

      {/* Stats Grid */}
      <Box className={classes.statGrid}>
        {[
          { value: auth.user?.stats?.savedNotesCount || '-', label: 'Notes saved', icon: '✏️' },
          { value: auth.user?.stats?.generatedPostsCount || 0, label: 'Posts', icon: '✍️' },
          { value: auth.user?.stats?.bestNotesStreakCount || 0, label: 'Longest streak', icon: '🔥' },
          { value: auth.user?.stats?.notesStreakCount || 0, label: 'Current streak', icon: '🎉' },
        ].map((stat, index) => (
          <Box key={index} className={classes.statItem}>
            <Typography className={classes.statLabel}>{stat.label}</Typography>
            <Box className={classes.statIcon}>
              <Typography>{stat.icon}</Typography>
              {formatNumber(stat.value)}
            </Box>
            {/* <Typography className={classes.statValue}>
              
            </Typography> */}
            
          </Box>
        ))}
      </Box>

      {/* Reports Section */}
      <Box className={classes.reportsSection}>
        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6">Reports</Typography>
        </Box>
        
        <Box className={classes.reportGrid}>
          {[
            ...[...Array(new Date().getFullYear() - 2024)].map((_, i) => ({
              title: `${2024 + i} LinkedIn Unwrapped`,
              image: '/stats/unwrapped-report-preview.webp',
              link: `/unwrapped/${linkedinUrlToId(auth?.user?.linkedinUrl)}/`
            })),
            ...Array.from({length: new Date().getMonth()}, (_, i) => ({
              title: `${new Date(0, i).toLocaleString('default', {month: 'long'})} ${new Date().getFullYear()} Unwrapped`, 
              image: auth?.user?.planIsActive ? '/stats/monthly-report-preview.webp' : '/stats/monthly-report-locked-preview.webp',
              link: `/stats/${new Date().getFullYear()}/${i + 1}`
            })),
          ].map((report, index) => (
            <Box key={index} style={{ textDecoration: 'none' }} onClick={() => {
              if (!auth?.user?.linkedinUrl || auth?.user?.linkedinUrl.includes('undefined')) {
                toast.error('Please connect your LinkedIn account to view reports');
                router.push('/settings/general');
                return;
              }
              if (report.link.includes('unwrapped') || auth?.user?.planIsActive) {
                analytics.track('View Stats Report', {
                  report_type: report.link.includes('unwrapped') ? 'unwrapped' : 'monthly',
                  report_date: report.link.includes('unwrapped') ? report.title : `${new Date().getFullYear()}-${new Date().getMonth() + 1}`
                });
                router.push(report.link);
              } else {
                setSubscribeModalOpen(true);
              }
            }}>
              <Box className={classes.reportCard}>
                <img src={report.image} alt={report.title} className={classes.reportImage} />
                <Typography style={{ fontSize: '10px', fontWeight: 'bold'}} variant="subtitle1">{report.title}</Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>

      {/* Content Snapshot */}
      <Box style={{ marginTop: theme.spacing(4) }}>
        <Typography variant="h6">Content Snapshot</Typography>
        <Typography variant="body2" style={{ opacity: 0.7, marginBottom: theme.spacing(2) }}>
          Hover over the plots to view the content posted on that day
        </Typography>
        <Box className={classes.heatmapContainer} ref={calendarRef} />
      </Box>

      {/* Top Posts */}
      {statsData && (
        <Box style={{ marginTop: theme.spacing(4) }}>
          <Typography variant="h6">Your Top Posts</Typography>
          <Typography variant="body2" style={{ opacity: 0.7, marginBottom: theme.spacing(2) }}>
            Browse through your best performing content for inspiration
          </Typography>
          
          <Box className={classes.topPostsGrid}>
            {[
              ...(statsData?.lastYearData?.posts.sort((a, b) => b.totalReactionCount - a.totalReactionCount) || [])?.slice(0, 9), 
              ...(statsData?.currentMonthData?.posts.sort((a, b) => b.totalReactionCount - a.totalReactionCount) || [])?.slice(0, 9)
            ].map((post, index) => (
              <Box 
                key={index} 
                className={classes.postCard}
                onClick={() => setSelectedPost(post)}
                style={{ cursor: 'pointer' }}
              >
                <Typography className={classes.postText}>
                  {truncate(post.text, 200)}
                </Typography>
                <Box className={classes.postStats}>
                  <span>🔄 {formatNumber(post.repostsCount || 0)}</span>
                  <span>❤️ {formatNumber(post.totalReactionCount || 0)}</span>
                  <span>💬 {formatNumber(post.commentsCount || 0)}</span>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      )}

      <NoteNav />
      {renderPostDialog()}
      <SubscribeModal title="Upgrade to access your monthly AI reports" open={subscribeModalOpen} onClose={() => setSubscribeModalOpen(false)} />
    </Box>
  );
};

export default StatsPage;
