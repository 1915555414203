import React, { useEffect, useState } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogContentText, 
  TextField, 
  DialogActions, 
  Button,
  FormGroup,
  FormControlLabel,
  Switch,
  Typography
} from '@material-ui/core';
import { toTimestamp, updatePost } from '../util/db';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { useDarkMode } from '../util/theme';
import analytics from '../util/analytics';
import { apiRequest } from '../util/util';
import { useAuth } from '../util/auth';

const ScheduleDialog = ({ open, onClose, note }) => {
  const darkMode = useDarkMode();
  const [selectedDateTime, setSelectedDateTime] = useState("");
  const [platforms, setPlatforms] = useState({
    linkedin: false
  });
  const queryClient = useQueryClient();
  const auth = useAuth();

  useEffect(() => {
    const date = note?.scheduledAt ? note.scheduledAt.toDate() : new Date();
    const tzoffset = date.getTimezoneOffset() * 60000;
    const localISOTime = (new Date(date - tzoffset)).toISOString().slice(0, 16);
    setSelectedDateTime(localISOTime);

    // Set initial platform state based on user's connected accounts
    setPlatforms(prev => ({
      ...prev,
      linkedin: !!auth.user?.linkedinAccessToken
    }));
  }, [note, auth.user]);

  const handleDateTimeChange = (event) => {
    setSelectedDateTime(event.target.value);
  };

  const handlePlatformChange = (platform) => (event) => {
    if (platform === 'linkedin' && !auth.user?.linkedinAccessToken) {
      toast.error('Please connect your LinkedIn account in Settings first');
      return;
    }
    setPlatforms({ ...platforms, [platform]: event.target.checked });
  };

  const handleSchedule = async () => {
    // Validate at least one platform is selected
    if (!Object.values(platforms).some(v => v)) {
      toast.error('Please select at least one platform to post to');
      return;
    }

    const toastId = toast.loading('Scheduling post...');
    try {
      analytics.track('schedulePost', {
        postId: note.id, 
        scheduledAt: selectedDateTime,
        platforms: Object.entries(platforms)
          .filter(([_, enabled]) => enabled)
          .map(([platform]) => platform)
      });
      
      // Update post status in Firestore
      await updatePost({
        id: note.id, 
        status: 'Scheduled', 
        scheduledAt: toTimestamp(new Date(selectedDateTime)),
        platforms: platforms // Store selected platforms
      });

      if (platforms.linkedin) {
      // Trigger Inngest function to schedule the post
        await apiRequest('jobs', 'POST', {
          name: "schedule.linkedin.post",
          data: {
            postId: note.id,
            scheduledAt: selectedDateTime,
            userId: note.owner,
            platforms: platforms
          }
        });
      }

      queryClient.invalidateQueries({queryKey: ['posts', note.owner]});
      toast.update(toastId, { 
        render: "Post scheduled", 
        type: "success", 
        isLoading: false, 
        autoClose: 2000 
      });
      onClose();
    } catch (error) {
      console.error('Error scheduling post:', error);
      toast.update(toastId, { 
        render: "Failed to schedule post", 
        type: "error", 
        isLoading: false, 
        autoClose: 2000 
      });
    }
  };

  const handleCancel = async () => {
    const toastId = toast.loading('Cancelling scheduled post...');
    try {
      analytics.track('cancelScheduledPost', {postId: note.id});
      
      // Send cancellation event to Inngest
      await apiRequest('jobs', 'POST', {
        name: "linkedin.post.cancelled",
        data: {
          postId: note.id,
          userId: note.owner
        }
      });

      // Update post status in Firestore
      await updatePost({
        id: note.id, 
        status: 'Draft', 
        scheduledAt: null
      });

      queryClient.invalidateQueries({queryKey: ['posts', note.owner]});
      toast.update(toastId, { 
        render: "Post schedule cancelled", 
        type: "success", 
        isLoading: false, 
        autoClose: 2000 
      });
      onClose();
    } catch (error) {
      console.error('Error cancelling scheduled post:', error);
      toast.update(toastId, { 
        render: "Failed to cancel schedule", 
        type: "error", 
        isLoading: false, 
        autoClose: 2000 
      });
    }
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        {note?.status === 'Scheduled' ? 'Edit Schedule' : 'Schedule Post'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {note?.status === 'Scheduled' && (
            <>
              <br/>
              Current schedule: {note.scheduledAt.toDate().toLocaleString()}
            </>
          )}
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="datetime-local"
          label="Select Date & Time"
          type="datetime-local"
          fullWidth
          variant="outlined"
          style={{colorScheme: darkMode.value ? 'dark' : 'light'}}
          InputLabelProps={{
            shrink: true,
          }}
          value={selectedDateTime}
          onChange={handleDateTimeChange}
        />
        <FormGroup style={{ marginTop: 16 }}>
          <Typography variant="subtitle2" style={{ marginBottom: 8 }}>
            Post to platforms:
          </Typography>
          <FormControlLabel
            control={
              <Switch
                checked={platforms.linkedin}
                onChange={handlePlatformChange('linkedin')}
                color="primary"
                // disabled={!auth.user?.linkedinAccessToken}
              />
            }
            label={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                LinkedIn
                {!auth.user?.linkedinAccessToken && (
                  <Typography 
                    variant="caption" 
                    color="textSecondary" 
                    style={{ marginLeft: 8 }}
                  >
                    (Not connected)
                  </Typography>
                )}
              </div>
            }
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
        {note?.status === 'Scheduled' && (
          <Button onClick={handleCancel} color="secondary" style={{color: 'red'}}>
            Cancel Schedule
          </Button>
        )}
        <Button 
          onClick={handleSchedule} 
          color="primary"
          disabled={!Object.values(platforms).some(v => v)}
        >
          {note?.status === 'Scheduled' ? 'Reschedule' : 'Schedule'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ScheduleDialog;