export const POST_TYPE = {
  linkedin: {id: 'linkedin', label: 'LinkedIn Post', limit: 3000 },
  instagram: {id: 'instagram', label: 'Instagram Post', limit: 2200},
  script: {id: 'script', label: 'Reels / TikTok Script', limit: 6000},
  tweet: {id: 'tweet', label: 'Tweet / Threads post', limit: 280},
  thread: {id: 'thread', label: 'Twitter Thread', limit: 6000},
  newsletter: {id: 'newsletter', label: 'Newsletter', limit: 3000}
}

//onboarding user options
export const GOALS = [
  'Drive opportunities to my career/business',
  'Become a thought leader in my industry',
  'Network with others',
  'Build my own community online'
];

export const TOPICS = [
  {topic: '💼 Business, Finance & Self-development', subtopics: ['🏢 Business', '🚀 Startups', '💰 Investment', '🏦 Personal finance', '🗣 Politics', '🌱 Personal development', '⏱ Productivity', '👔 Career advice', '🤝 Sales', '📢 Marketing']},
  {topic: '🤖 Science & Technology', subtopics: ['📱 Gadgets', '🧠 AI', '🌍 Environment & sustainability', '🧬 Scientific breakthroughs']},
  {topic: '🌿 Wellness', subtopics: ['💊 Nutrition', ' 🏋️‍♀️ Exercise routines', '🧠 Mental health', '🧘 Meditation', '🏃‍♀️ Fitness']},
  {topic: '🏖️  Lifestyle', subtopics: ['✈️ Travel', '👗 Fashion', '💄 Beauty', '🏡 Home decor', '🍲 Recipes', '🧸 Parenting', '🎥 Movies and TV shows', '🎵 Music', '🕹 Gaming']},
  {topic: '🎨 Creativity & Arts', subtopics: ['🤳  Content creation', '📸 Photography', '✍️ Writing', '🎨 Design', '🛠 DIY projects']}
  // More topics can be added here
];

export const LANGUAGES = [
  {name: "English", code: "en", native: "English"},
  {name: "Afrikaans", code: "af", native: "Afrikaans"},
  {name: "Albanian", code: "sq", native: "shqip"},
  {name: "Amharic", code: "am", native: "አማርኛ"},
  {name: "Arabic", code: "ar", native: "العربية"},
  {name: "Aragonese", code: "an", native: "aragonés"},
  {name: "Armenian", code: "hy", native: "հայերեն"},
  {name: "Asturian", code: "ast", native: "asturianu"},
  {name: "Azerbaijani", code: "az", native: "azərbaycan dili"},
  {name: "Basque", code: "eu", native: "euskara"},
  {name: "Belarusian", code: "be", native: "беларуская"},
  {name: "Bengali", code: "bn", native: "বাংলা"},
  {name: "Bosnian", code: "bs", native: "bosanski"},
  {name: "Breton", code: "br", native: "brezhoneg"},
  {name: "Bulgarian", code: "bg", native: "български"},
  {name: "Catalan", code: "ca", native: "català"},
  {name: "Central Kurdish", code: "ckb", native: "کوردی (دەستنوسی عەرەبی)"},
  {name: "Chinese", code: "zh", native: "中文"},
  {name: "Chinese (Hong Kong)", code: "zh-HK", native: "中文（香港）"},
  {name: "Chinese (Simplified)", code: "zh-CN", native: "中文（简体）"},
  {name: "Chinese (Traditional)", code: "zh-TW", native: "中文（繁體）"},
  {name: "Corsican", code: "co", native: "Corsican"},
  {name: "Croatian", code: "hr", native: "hrvatski"},
  {name: "Czech", code: "cs", native: "čeština"},
  {name: "Danish", code: "da", native: "dansk"},
  {name: "Dutch", code: "nl", native: "Nederlands"},
  {name: "English", code: "en", native: "English"},
  {name: "English (United Kingdom)", code: "en-GB", native: "English (United Kingdom)"},
  {name: "English (United States)", code: "en-US", native: "English (United States)"},
  {name: "English (Australia)", code: "en-AU", native: "English (Australia)"},
  {name: "English (Canada)", code: "en-CA", native: "English (Canada)"},
  {name: "English (India)", code: "en-IN", native: "English (India)"},
  {name: "Singlish (Singapore English)", code: "en-SG", native: "Singlish"},
  {name: "Esperanto", code: "eo", native: "esperanto"},
  {name: "Estonian", code: "et", native: "eesti"},
  {name: "Faroese", code: "fo", native: "føroyskt"},
  {name: "Filipino", code: "fil", native: "Filipino"},
  {name: "Finnish", code: "fi", native: "suomi"},
  {name: "French", code: "fr", native: "français"},
  {name: "French (Canada)", code: "fr-CA", native: "français (Canada)"},
  {name: "French (France)", code: "fr-FR", native: "français (France)"},
  {name: "French (Switzerland)", code: "fr-CH", native: "français (Suisse)"},
  {name: "Galician", code: "gl", native: "galego"},
  {name: "Georgian", code: "ka", native: "ქართული"},
  {name: "German", code: "de", native: "Deutsch"},
  {name: "Greek", code: "el", native: "Ελληνικά"},
  {name: "Guarani", code: "gn", native: "Guarani"},
  {name: "Gujarati", code: "gu", native: "ગુજરાતી"},
  {name: "Hausa", code: "ha", native: "Hausa"},
  {name: "Hawaiian", code: "haw", native: "ʻŌlelo Hawaiʻi"},
  {name: "Hebrew", code: "he", native: "עברית"},
  {name: "Hindi", code: "hi", native: "हिन्दी"},
  {name: "Hinglish (Hindi-English)", code: "hi-EN", native: "Hinglish (Hindi-English)"},
  {name: "Tanglish (Tamil-English)", code: "ta-EN", native: "Tanglish (Tamil-English)"},
  {name: "Malayalam English", code: "ml-EN", native: "Malayalam English"},
  {name: "Hungarian", code: "hu", native: "magyar"},
  {name: "Icelandic", code: "is", native: "íslenska"},
  {name: "Indonesian", code: "id", native: "Indonesia"},
  {name: "Interlingua", code: "ia", native: "Interlingua"},
  {name: "Irish", code: "ga", native: "Gaeilge"},
  {name: "Italian", code: "it", native: "italiano"},
  {name: "Japanese", code: "ja", native: "日本語"},
  {name: "Kannada", code: "kn", native: "ಕನ್ನಡ"},
  {name: "Kazakh", code: "kk", native: "қазақ тілі"},
  {name: "Khmer", code: "km", native: "ខ្មែរ"},
  {name: "Korean", code: "ko", native: "한국어"},
  {name: "Kurdish", code: "ku", native: "Kurdî"},
  {name: "Kyrgyz", code: "ky", native: "кыргызча"},
  {name: "Lao", code: "lo", native: "ລາວ"},
  {name: "Latin", code: "la", native: "Latin"},
  {name: "Latvian", code: "lv", native: "latviešu"},
  {name: "Lingala", code: "ln", native: "lingála"},
  {name: "Lithuanian", code: "lt", native: "lietuvių"},
  {name: "Macedonian", code: "mk", native: "македонски"},
  {name: "Malay", code: "ms", native: "Bahasa Melayu"},
  {name: "Jawi", code: "ms-JW", native: "جاوي"},
  {name: "Kelantanese Malay", code: "ms-KL", native: "Bahasa Kelantan"},
  {name: "Sarawak Malay", code: "ms-SR", native: "Bahasa Sarawak"},
  {name: "Terengganu Malay", code: "ms-TR", native: "Bahasa Terengganu"},
  {name: "Manglish (Malay English", code: "en-MG", native: "English Malay"},
  {name: "Malayalam", code: "ml", native: "മലയാളം"},
  {name: "Maltese", code: "mt", native: "Malti"},
  {name: "Marathi", code: "mr", native: "मराठी"},
  {name: "Mongolian", code: "mn", native: "монгол"},
  {name: "Nepali", code: "ne", native: "नेपाली"},
  {name: "Norwegian", code: "no", native: "norsk"},
  {name: "Norwegian Bokmål", code: "nb", native: "norsk bokmål"},
  {name: "Norwegian Nynorsk", code: "nn", native: "nynorsk"},
  {name: "Occitan", code: "oc", native: "Occitan"},
  {name: "Oriya", code: "or", native: "ଓଡ଼ିଆ"},
  {name: "Oromo", code: "om", native: "Oromoo"},
  {name: "Pashto", code: "ps", native: "پښتو"},
  {name: "Persian", code: "fa", native: "فارسی"},
  {name: "Polish", code: "pl", native: "polski"},
  {name: "Portuguese", code: "pt", native: "português"},
  {name: "Portuguese (Brazil)", code: "pt-BR", native: "português (Brasil)"},
  {name: "Punjabi", code: "pa", native: "ਪੰਜਾਬੀ"},
  {name: "Quechua", code: "qu", native: "Quechua"},
  {name: "Romanian", code: "ro", native: "română"},
  {name: "Russian", code: "ru", native: "русский"},
  {name: "Scottish Gaelic", code: "gd", native: "Scottish Gaelic"},
  {name: "Serbian", code: "sr", native: "српски"},
  {name: "Serbo", code: "sh", native: "Croatian"},
  {name: "Shona", code: "sn", native: "chiShona"},
  {name: "Sindhi", code: "sd", native: "Sindhi"},
  {name: "Sinhala", code: "si", native: "සිංහල"},
  {name: "Slovak", code: "sk", native: "slovenčina"},
  {name: "Slovenian", code: "sl", native: "slovenščina"},
  {name: "Somali", code: "so", native: "Soomaali"},
  {name: "Southern Sotho", code: "st", native: "Southern Sotho"},
  {name: "Spanish", code: "es", native: "español"},
  {name: "Spanish (Mexico)", code: "es-MX", native: "español (México)"},
  {name: "Sundanese", code: "su", native: "Sundanese"},
  {name: "Swahili", code: "sw", native: "Kiswahili"},
  {name: "Swedish", code: "sv", native: "svenska"},
  {name: "Tajik", code: "tg", native: "тоҷикӣ"},
  {name: "Tamil", code: "ta", native: "தமிழ்"},
  {name: "Tatar", code: "tt", native: "Tatar"},
  {name: "Telugu", code: "te", native: "తెలుగు"},
  {name: "Thai", code: "th", native: "ไทย"},
  {name: "Tigrinya", code: "ti", native: "ትግርኛ"},
  {name: "Tongan", code: "to", native: "lea fakatonga"},
  {name: "Turkish", code: "tr", native: "Türkçe"},
  {name: "Twi", code: "tw", native: "Twi"},
  {name: "Ukrainian", code: "uk", native: "українська"},
  {name: "Urdu", code: "ur", native: "اردو"},
  {name: "Uyghur", code: "ug", native: "Uyghur"},
  {name: "Uzbek", code: "uz", native: "o‘zbek"},
  {name: "Vietnamese", code: "vi", native: "Tiếng Việt"},
  {name: "Walloon", code: "wa", native: "wa"},
  {name: "Welsh", code: "cy", native: "Cymraeg"},
  {name: "Western Frisian", code: "fy", native: "Western Frisian"},
  {name: "Xhosa", code: "xh", native: "Xhosa"},
  {name: "Yiddish", code: "yi", native: "Yiddish"},
  {name: "Yoruba", code: "yo", native: "Èdè Yorùbá"},
  {name: "Zulu", code: "zu", native: "isiZulu"}
];

export const TONES = [
  'Default',
  'Conversational',
  'Direct & Professional',
  'Casual',
  'Witty & Playful',
  'Storytelling',
  'Inspirational',
  'Persuasive',
  'Logical',
  'Empathetic & Relatable',
  'Minimalist & Concise',
  'Gen Alpha',
  'Gen Z',
  'Millennial',
  'Gen X',
  'Boomer',
  'Greek Philosopher',
  'Caveman',
  'Brainrot',
  'Corporate',
  'Passive-aggressive',
]