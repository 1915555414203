import React, { useState, useRef, useEffect } from 'react';
import { 
  Typography, 
  Container, 
  Box, 
  Button,
  Grid,
  Card,
  CardContent,
  CircularProgress,
  LinearProgress,
  makeStyles,
  ButtonGroup,
  Dialog,
  DialogTitle, 
  DialogContent,
  DialogActions,
  Avatar,
  TextField,
  useMediaQuery,
  Tooltip as MUITooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import { apiRequest, formatNumber } from '../util/util.js';
import { LinkedIn, Refresh, Save, ExpandMore, WhatsApp, Facebook, Twitter, PostAdd, Person, GetApp, PictureAsPdf, Collections, CloudDownload, Link, CheckCircle } from '@material-ui/icons';
import { useAuth } from '../util/auth.js';
import { useRouter } from '../util/router.js';
import html2canvas from 'html2canvas';
import { useTheme } from '@material-ui/core/styles';
import dayjs from 'dayjs';
import CalHeatmap from 'cal-heatmap';
import Tooltip from 'cal-heatmap/plugins/Tooltip';
import LegendLite from 'cal-heatmap/plugins/LegendLite';
import CalendarLabel from 'cal-heatmap/plugins/CalendarLabel';
import analytics from '../util/analytics.js';
import 'cal-heatmap/cal-heatmap.css';
import { LINKEDIN_WRAPPED_DATA } from '../util/mock_data.js';
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  ResponsiveContainer
} from 'recharts';
import jsPDF from 'jspdf';
import { toast } from 'react-toastify';
import Meta from '../components/Meta';
import { useInView } from 'react-intersection-observer';
import UnwrappedCTA, { CTA_COMPONENTS } from '../components/UnwrappedCTA';
import NoteNav from '../components/NoteNav.js';

// import CoffeeWidget from '../components/CoffeeWidget';

export const UNWRAPPED_YEAR = 2024

export const FAQ_DATA  = [
  {
    question: "How does the number of posts is calculated?",
    answer: `We count all posts that you have made in ${UNWRAPPED_YEAR} but excluded reposts (with or without commentary). At 19/12/${UNWRAPPED_YEAR}, we made an update to include reposts with commentary. Reposts without commentary however are still excluded.`
  },
  {
    question: "I posted in Jan, February, March, etc. but it doesn't show up in the report chart?",
    answer: "Our system start reading your most recent posts first. In rare case, it might fail and hence causing empty section in the early parts of the year. Please chat with us to resolve the issue and get your report."
  },
  {
    question: "Can I regenerate my report?",
    answer: "Please contact us in the chat for a request to generate your report."
  },
  {
    question: "I'd like to request my account or data to be removed",
    answer: "Please contact us in the chat to request for your data to be removed."
  },
  {
    question: "I think I wrote more comment than the what's shown in the report",
    answer: "The comments count are how many comments you received in all your posts. It's not how many comments you made."
  },
  {
    question: "How do the linkedin buddies are determined",
    answer: "It's based on the most frequent commenters in your posts that doesn't perform well. However for performance reason, it's only based on selected few posts and might not be fully accurate."
  },
  // {
  //   question: "How does the LinkedIn Unwrapped tool work?",
  //   answer: "Enter your LinkedIn profile URL, and we'll analyze your activity for 2024 to create a personalized report. This includes your top-performing posts, key trends, and notable insights about your engagement. It's quick, easy, and 100% tailored to you."
  // },
  {
    question: "Is it really free?",
    answer: `Yes! LinkedIn Unwrapped is completely free for you to use. No hidden costs, no strings attached—just a fun way to reflect on your LinkedIn journey this year.

We're offering this as a way to share value and show what Cleve.ai can do. Cleve.ai makes money through our premium subscription plans, which help entrepreneurs and professionals create consistent, high-quality content for their personal brands. If you find LinkedIn Unwrapped helpful, you might love what Cleve.ai offers for leveling up your content game!`
  },
  {
    question: "What will I get?",
    answer: `You'll receive a 10 page summary of your year which you can post on LinkedIn, Instagram, Facebook, X - or just share with your friends!

This summary will include:
• Your top posts in ${UNWRAPPED_YEAR}
• Key engagement trends
• Fun highlights like your LinkedIn "buddies"`
  },
  {
    question: "Who built this?",
    answer: "This tool was created by Cleve.ai—a team dedicated to helping professionals share their stories and grow their personal brands with ease."
  },
  {
    question: "How do I find my profile URL?",
    answer: `Desktop:
1. Open your LinkedIn profile on your web browser
2. Copy the link from the address bar at the top of your browser (e.g., linkedin.com/in/yourname)
3. Paste it into the box, and you're all set!

Mobile:
1. Open the LinkedIn app on your phone
2. Go to your profile by tapping your profile picture in the top-left corner
3. Tap the More button (three dots) and select Copy Profile URL from the menu
4. Paste it into the box, and you're good to go!`
  },
  {
    question: "Can I share this on LinkedIn?",
    answer: "Enter your LinkedIn profile URL, and we'll analyze your activity for 2024 to create a personalized report. This includes your top-performing posts, key trends, and notable insights about your engagement. It's quick, easy, and 100% tailored to you."
  },
  {
    question: "Is my data safe?",
    answer: `Yes, your data is safe. We only access public data from your LinkedIn profile and past posts to generate your personalized report. This data is stored securely and used exclusively within Cleve.ai for the purpose of creating your Unwrapped report.

We never sell your data with third parties, and we don't use it for anything beyond improving and personalizing your experience with Cleve. Your privacy and trust are our top priorities. For more details, review our privacy policy at bottom of the page at www.cleve.ai.`
  }
]

export const LOADING_MESSAGES = [
  // "Connecting securely to LinkedIn...",
  "Getting profile data...", 
  "Analysing your top posts...",
  "Judging you...",
  "Realising you're cooler than we are....",
  "Reflecting on life choices...",
  "Having a mid-life crisis...",
  "I mean quarter life crisis...",
  "Talking to my therapist...",
  "Accepting your sheer awesomeness...",
  "Btw hi we're cleve",
  "we're a 3 person team in Malaysia that's building the ultimate content tool for LinkedIn",
  "we launched in June this year, welcomed >8000 users",
  "and have a secret special offer for you (on the next page!) 👀🎊",
  "Anyways more on that later, enjoy your wrapped!",
  "This usually takes 1-2 min at most",
];

export const SHARING_STEPS = [
  {
    step: 1,
    text: "Download your Unwrapped",
    action: "Download (PDF)",
    icon: <GetApp />
  },
  {
    step: 2,
    text: "Copy the suggested caption",
    buttons: [
      {
        label: "Copy caption",
        icon: <PostAdd />,
        variant: "default"
      },
      {
        label: "Export to LinkedIn",
        icon: <LinkedIn />,
        variant: "linkedin"
      },
    ]
  },
  {
    step: 3,
    text: "Click "+" and Attach Document in LinkedIn, then select the pdf.",
    action: null
  }
];



const useStyles = makeStyles((theme) => ({
  '@global': {
    '#root': {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
    },
    '.ch-plugin-calendar-label-text': {
      fill: '#fff',
    },
    '#ch-tooltip, #ch-tooltip[data-theme="dark"]': {
      backgroundColor: '#fff',
      color: '#19083d',
      padding: theme.spacing(0),
      border: '1px solid #462986', // Solid border with the specified stroke color
      boxShadow: '0px 0px 8px rgba(140, 223, 255, 0.1)', // Drop shadow
      borderRadius: '8px', // Optional: adjust as needed for rounded corners
    }
  },
  root: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
  card: {
    width: '95vw', // Changed from fixed width
    maxWidth: '600px', // Keep max width
    aspectRatio: '4/5',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    backgroundColor: '#11042E',
    margin: '40px auto', // Reduced margin for mobile
    fontFamily: 'Montserrat, sans-serif',
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    borderRadius: '5px',
    '&:hover .MuiButton-root': {
      backgroundColor: 'rgba(255, 255, 255, 0.4)',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '20px auto', // Even smaller margin on mobile
    },
  },
  cardHeader: {
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  cardFooter: {
    width: '100%',
    position: 'absolute',
    bottom: '45px',
    left: 0,
  },
  cardContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2), // Add padding
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1), // Less padding on mobile
    },
  },
  title: {
    color: '#FFFFFF',
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    color: '#FFFFFF',
    marginBottom: theme.spacing(4),
  },
  linkedinReactionIcon: {
    height: '22px',
    marginTop: '20px',
    marginBottom: '-5px',
    marginRight: '5px',
    [theme.breakpoints.down('xs')]: {
      height: '16px',
      marginTop: '10px',
      marginBottom: '-2px',
      marginRight: '2px',
    },
  },
  statCard: {
    backgroundColor: '#19083d',
    padding: theme.spacing(1.5),
    marginBottom: theme.spacing(2),
    border: '1px solid #462986', // Solid border with the specified stroke color
    // boxShadow: '0px 0px 8px rgba(140, 223, 255, 0.3)', // Drop shadow
    borderRadius: '8px', // Optional: adjust as needed for rounded corners
    textAlign: 'center',
  },
  statValue: {
    fontSize: '2rem',
    fontWeight: 'bold',
    color: '#fff',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.9rem',
    },
  },
  statLabel: {
    color: '#fff',
    fontSize: '1rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.7rem',
    },
  },
  heatmapContainer: {
    maxWidth: '100%',
    padding: theme.spacing(0),
    borderRadius: theme.spacing(1),
    '& .ch-container': {
      margin: '0 auto',
    },
    '& .ch-domain-text': {
      fill: '#FFFFFF',
      fontSize: '12px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '8px',
      },
    },
    '& .ch-subdomain-text': {
      [theme.breakpoints.down('xs')]: {
        fontSize: '5px',
      },
    },
    '& .ch-plugin-calendar-label-text': {
      fontSize: '12px !important',
      [theme.breakpoints.down('xs')]: {
        fontSize: '6px !important',
      },
    },
  },
  monthLabel: {
    color: '#FFFFFF',
    fontSize: '0.75rem',
    marginBottom: '4px',
  },
  footer: {
    fontSize: '0.7rem',
    padding: '8px 10px 10px',
    margin: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0,
    boxSizing: 'border-box',
  },
  topPostCard: {
    backgroundColor: 'transparent',
    padding: theme.spacing(1.5),
    margin: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0.5),
      gap: theme.spacing(1),
      margin: theme.spacing(0.5),
    },
  },
  medalEmoji: {
    fontSize: '32px',
    width: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '20px',
      width: '30px',
    },
  },
  topPostText: {
    color: '#fff',
    flex: 1,
    fontSize: '16px',
    fontWeight: 700,
    marginTop: '5px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  topPostSubtitle:{
    fontSize: '15px',
    opacity: 0.85,
    marginTop: '5px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '8px',
    },
  },
  buddyCard: {
    margin: theme.spacing(1),
    // border: '1px solid #462986',
    width: '40vw',
    maxWidth: '190px',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      width: '100%', // Full width on mobile
      margin: theme.spacing(0.5),
    },
  },
  buddyAvatar: {
    width: 48,
    height: 48,
    // border: '2px solid #462986',
    borderRadius: '20%',
    [theme.breakpoints.down('xs')]: {
      width: 24,
      height: 24,
    },
  },
  personaDescription: {
    color: '#fff',
    fontSize: '14px',
    lineHeight: '1.5',
    margin: theme.spacing(1.5, 0),
    padding: theme.spacing(0, 2),
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  chartCard: {
    backgroundColor: '#19083d',
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    border: '1px solid #462986',
    borderRadius: '8px',
    '& canvas': {
      maxWidth: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
      margin: theme.spacing(1),
      fontSize: '12px',
      '& .MuiTypography-body1': {
        fontSize: '12px',
      },
    },
  },
  streakBox: {
    backgroundColor: '#2a1a4d',
    padding: theme.spacing(2),
    borderRadius: '8px',
    textAlign: 'center',
    margin: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
      margin: theme.spacing(0.5),
    },
  },
  downloadButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    zIndex: 10,
    // backgroundColor: 'rgba(255, 255, 255, 0.2)',
    // '&:hover': {
    //   backgroundColor: 'rgba(255, 255, 255, 1)',
    // },
    minWidth: '40px',
    width: '40px',
    height: '40px',
    borderRadius: '10%',
    '&:disabled': {
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
    },
  },
  actionButtons: {
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column', // Stack buttons on mobile
      width: '100%',
      padding: theme.spacing(0, 2),
    },
  },
  actionButton: {
    fontWeight: 700,
    padding: '10px 20px',
    '&:disabled': {
      backgroundColor: 'rgba(255, 255, 255, 0.6)',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%', // Full width buttons on mobile
    },
  },
  sharingSteps: {
    backgroundColor: '#19083d',
    padding: theme.spacing(3),
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(0),
    border: '1px solid #462986',
    width: '100%',
    maxWidth: '600px',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  },
  stepContainer: {
    fontWeight: 800,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(2),
    gap: theme.spacing(2),
    '&:last-child': {
      marginBottom: 0,
    },
    [theme.breakpoints.down('xs')]: {
      alignItems: 'stretch',
    },
  },
  stepNumber: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: '1.2rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
    },
  },
  stepText: {
    color: '#fff',
    flex: 1,
    fontSize: '1rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.875rem',
    },
  },
  stepButton: {
    backgroundColor: '#FFD700',
    color: '#000000',
    '&:hover': {
      backgroundColor: '#FFE44D',
    },
    '&:disabled': {
      backgroundColor: 'rgba(255, 215, 0, 0.5)', // 50% opacity of #FFD700
      color: 'rgba(0, 0, 0, 0.5)',
    }
  },
  stepButtons: {
    // margin: 'auto',
    display: 'flex',
    gap: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  linkedinButton: {
    backgroundColor: '#0A66C2',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#004182',
    },
  },
  defaultButton: {
    backgroundColor: '#E0E0E0',
    color: '#000',
    '&:hover': {
      backgroundColor: '#BDBDBD',
    },
  },
  otherSharingOptions: {
    marginTop: theme.spacing(3),
    width: '100%',
  },
  otherSharingTitle: {
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(1),
    textAlign: 'center',
  },
  otherSharingButtons: {
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'row',
    },
    '& .MuiButton-root:disabled': {
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // 50% opacity of #FFD700
      color: 'rgba(255, 255, 255, 0.7)'
    }
  },
  yellowButton: {
    fontWeight: 700,
    backgroundColor: '#FFD700',
    color: '#000',
    '&:hover': {
      backgroundColor: '#FFE44D',
    },
    flex: 1,
  },
}));

const IMAGE_FILENAMES = [
  '01-linkedin-unwrapped-cover',
  '02-linkedin-unwrapped-stats',
  '03-linkedin-unwrapped-top-posts',
  '04-linkedin-unwrapped-buddies',
  '05-linkedin-unwrapped-persona',
  '06-linkedin-unwrapped-timeline',
  '07-linkedin-unwrapped-lessons',
  '08-linkedin-unwrapped-letter',
  '09-linkedin-unwrapped-streaks',
  '10-linkedin-unwrapped-hourly',
  '11-linkedin-unwrapped-weekly',
  '12-linkedin-unwrapped-final',
]

const IMAGE_FILENAMES_MAP = {
  stats: IMAGE_FILENAMES[0],
  activity: IMAGE_FILENAMES[1],
  topPosts: IMAGE_FILENAMES[2],
  buddies: IMAGE_FILENAMES[3],
  persona: IMAGE_FILENAMES[4],
  timeline: IMAGE_FILENAMES[5],
  lessons: IMAGE_FILENAMES[6],
  letter: IMAGE_FILENAMES[7],
  streaks: IMAGE_FILENAMES[8],
  hourly: IMAGE_FILENAMES[9],
  weekly: IMAGE_FILENAMES[10],
  final: IMAGE_FILENAMES[11],
}

const createTooltip = (content) => {
  const tooltip = document.createElement('div');
  tooltip.innerHTML = content;
  tooltip.id = 'ch-tooltip2';
  tooltip.style.position = 'fixed';
  tooltip.style.backgroundColor = '#fff';
  tooltip.style.color = '#19083d';
  tooltip.style.padding = '0';
  tooltip.style.border = '1px solid #462986';
  tooltip.style.boxShadow = '0px 0px 8px rgba(140, 223, 255, 0.1)';
  tooltip.style.borderRadius = '8px';
  tooltip.style.zIndex = 1000;
  return tooltip;
};

const updateTooltipPosition = (tooltip, e) => {
  if (tooltip) {
    tooltip.style.left = `${e.clientX + 10}px`;
    tooltip.style.top = `${e.clientY + 10}px`;
  }
};

const removeTooltip = () => {
  const tooltip = document.getElementById('ch-tooltip2');
  if (tooltip) {
    tooltip.remove();
  }
};

// Update CardWrapper to accept downloadCardAsImage as a prop
const CardWrapper = ({ 
  children, 
  cardRef, 
  downloadFileName, 
  className,
  footerRightText,
  onDownload,
  headerImage = "/wrapped/wrapped-header-3.webp", // default header
  hideFooterImage = false // default to showing footer image
}) => {
  const classes = useStyles();
  const [downloadingCard, setDownloadingCard] = useState(false);

  const handleDownload = async () => {
    try {
      setDownloadingCard(true);
      await onDownload(cardRef, downloadFileName);
    } finally {
      setDownloadingCard(false);
    }
  };

  return (
    <Box className={`${classes.card} ${className}`} ref={cardRef}>
      <Button
        className={classes.downloadButton}
        onClick={handleDownload}
        disabled={downloadingCard}
      >
        {downloadingCard ? 
          <CircularProgress size={24} color="inherit" /> : 
          <GetApp />
        }
      </Button>
      <img 
        src={headerImage}
        alt="header" 
        className={classes.cardHeader}
      />
      {children}
      {!hideFooterImage && (
        <img 
          src="/wrapped/wrapped-footer-3.webp" 
          alt="footer" 
          className={classes.cardFooter}
        />
      )}
      <CardFooter text={footerRightText}/>
    </Box>
  );
};

const CardFooter = ({text}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <Box className={classes.footer}>
      <Box style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
      <img src="/logo.webp" alt="Cleve.ai" style={{ height: isMobile? '12px' : '20px' }} />
        <Typography variant="body2" style={{ fontSize: isMobile? '0.6rem' : '0.8rem', color: '#fff'}}>
          cleve.ai/unwrapped
        </Typography>
      </Box>
      {text && <Typography variant="body2" style={{ fontSize: isMobile? '0.6rem' : '0.8rem', color: '#fff'}}>
        {text}
      </Typography>}
    </Box>
  );
};

const CardTitle = ({ title, subtitle }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <>
      <Typography align="center" style={{ 
      fontSize: isMobile? '18px' : '32px', 
      fontWeight: 'bold',
      marginTop: isMobile? '50px' : '25px',
      color: '#fff',
    }}>
      {title}
    </Typography>
    {subtitle && (
      <Typography align="center" style={{ 
        fontSize: isMobile? '14px' : '20px',
        color: '#fff',
        margin: isMobile? '20px 0px' : '10px 0px',
      }}>
        {subtitle}
      </Typography>
    )}
  </>
)};

const ResponsiveGrid = ({ children, minWidth = '200px', gap = '12px', margin = '20px' }) => (
  <Box style={{ 
    margin,
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fit, minmax(${minWidth}, 1fr))`,
    gap,
  }}>
    {children}
  </Box>
);

const MonthlyTimelineItem = ({ month, summary }) => {
  const classes = useStyles();
  const text = summary?.text || "-";
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Box 
      className={classes.statCard}
      style={{ 
        padding: '8px',
        margin: 0,
        height: isMobile? '50px' : '72px',
        display: 'flex',
        alignItems: 'center',
        width: '45vw',
        maxWidth: isMobile? '170px' : '265px',
      }}
    >
      <Box style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRight: '1px solid rgba(255,255,255,0.1)',
        paddingRight: '8px',
        marginRight: '8px',
        width: isMobile? '25px' : '40px',
        height: '100%',
        justifyContent: 'center',
      }}>
        <Typography style={{ 
          color: '#fff',
          fontSize: isMobile? '12px' : '16px',
          fontWeight: 'bold',
          lineHeight: 1.5,
        }}>
          {month}
        </Typography>
      </Box>
      <Typography style={{ 
        color: '#fff',
        fontSize: isMobile? '8px' : '11px',
        lineHeight: 1.4,
        overflow: 'hidden',
        flex: 1,
        display: '-webkit-box',
        WebkitLineClamp: 3,
        WebkitBoxOrient: 'vertical',
        textAlign: 'left',
      }}>
        {text} {summary?.emoji}
      </Typography>
    </Box>
  );
};

// First, create a function to encode the caption for the URL
const getShareUrl = (caption) => {
  const encodedText = encodeURIComponent(caption);
  return `https://www.linkedin.com/feed/?linkOrigin=LI_BADGE&shareActive=true&text=${encodedText}`;
};

// Add this near the top of the file with other constants
const generateWrappedCaption = (linkedinData) => {
  if (!linkedinData) return '';

  const stats = linkedinData.stats || {};
  const lessons = linkedinData.lessons || [];
  const caption = linkedinData?.caption || '';

  return `📊 My ${UNWRAPPED_YEAR} LinkedIn stats (from Cleve.ai):
- ${formatNumber(stats.totalPosts || 0)} posts
- ${formatNumber(stats.totalReactions || 0)} total reactions
- ${formatNumber(stats.totalComments || 0)} comments

Here's 10 lessons I learned in ${UNWRAPPED_YEAR}:
${lessons.map(lesson => `${lesson?.emoji} ${lesson?.text}`).join('\n')}

${caption}

Get your free LinkedIn unwrapped from cleve.ai/unwrapped.

#LinkedinUnwrapped #CleveAI`;
};

// Add this component near the top of the file after other component imports:
const CountdownBanner = ({ timeLeft }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  if (!timeLeft) return (
    <Typography style={{
      color: '#000',
      fontSize: isMobile ? '0.75em' : '1em',
      fontWeight: 700,
    }}>
      Get 20% off Cleve Analytics ($8/month)
    </Typography>
  );

  return (
    <Typography style={{
      color: '#000',
      fontSize: isMobile ? '0.75em' : '1em',
      fontWeight: 700,
    }}>
      🔥 Special offer (50% off): Get monthly analytics with Cleve Lite ($5/month) • {isMobile? <br/> : ''}<span style={{backgroundColor: '#19083d', padding: '2px 6px', marginTop: '4px', color: '#FFE69F', borderRadius: '4px'}}> Expires in {timeLeft}</span>
    </Typography>
  );
};

// Add this hook near the top of the file after imports:
const useTimeLeft = (lastUpdated) => {
  const [timeLeft, setTimeLeft] = useState('');

  useEffect(() => {
    const calculateTimeLeft = () => {
      if (!lastUpdated) return null;
      
      const lastUpdateTime = dayjs(lastUpdated);
      const expiryTime = lastUpdateTime.add(12, 'hour');
      const now = dayjs();
      
      if (now.isAfter(expiryTime)) {
        return null;
      }

      const duration = dayjs.duration(expiryTime.diff(now));
      const hours = Math.floor(duration.asHours());
      const minutes = Math.floor(duration.minutes());
      const seconds = Math.floor(duration.seconds());

      return `${hours}h ${minutes}m ${seconds}s`;
    };

    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [lastUpdated]);

  return timeLeft;
};

const LinkedInWrapped = () => {
  const classes = useStyles();
  const auth = useAuth();
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState(LOADING_MESSAGES[0]);
  const [isPosting, setIsPosting] = useState(false);
  const [linkedinData, setLinkedinData] = useState(null);
  const [error, setError] = useState(null);
  const wrapperRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [calendars, setCalendars] = useState({});
  const calendarRefs = {
    main: useRef(null),
    activity: useRef(null),
    topPosts: useRef(null),
    final: useRef(null),
  };
  const [selectedPost, setSelectedPost] = useState(null);
  const [linkedinUrl, setLinkedinUrl] = useState('');
  const [isValidUrl, setIsValidUrl] = useState(true);
  const cardRefs = {
    stats: useRef(null),
    activity: useRef(null),
    topPosts: useRef(null),
    buddies: useRef(null),
    persona: useRef(null),
    timeline: useRef(null),
    lessons: useRef(null),
    letter: useRef(null),
    streaks: useRef(null),
    hourly: useRef(null),
    weekly: useRef(null),
    final: useRef(null),
  };
  const [downloadingCard, setDownloadingCard] = useState(null);
  const [downloadingAll, setDownloadingAll] = useState({ pdf: false, images: false });
  const [copiedState, setCopiedState] = useState(false);
  const [copiedLinkState, setCopiedLinkState] = useState(false);  // Add this new state
  const [processedPdf, setProcessedPdf] = useState(null);
  // Add these new state variables near the top
  const [processedImages, setProcessedImages] = useState({});
  const [isPreprocessing, setIsPreprocessing] = useState(false);
  const [processedCanvases, setProcessedCanvases] = useState({});
  // Update state to store both canvases and converted data
  const [processedContent, setProcessedContent] = useState({
    canvases: {},
    images: {},
    pdf: null
  });
  // Add this state near other useState declarations
  const [email, setEmail] = useState('');
  // Add timeLeft state with other state declarations
  const timeLeft = useTimeLeft(linkedinData?.lastUpdated);

  // Add this validation function near other validation functions
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  useEffect(() => {
    const initCalendars = async () => {
      if (linkedinData?.stats?.heatmapData) {
        // Cleanup existing calendars
        Object.values(calendars).forEach(cal => {
          if (cal) cal.destroy();
        });

        const newCalendars = {};

        // Initialize calendars for each ref
        const calendarPromises = Object.entries(calendarRefs).map(async ([key, ref]) => {
          if (ref.current) {
            const cal = new CalHeatmap();
            await cal.paint({
              itemSelector: ref.current,
              domain: {
                type: 'month',
                gutter: isMobile ? -4 : -7,
                label: { text: 'MMM' }
              },
              subDomain: {
                type: 'day',
                width: isMobile ? 4 : 7,
                height: isMobile ? 4 : 7,
                gutter: 2,
                label: (t, v) => key == 'topPosts' && v > (linkedinData.stats.topPosts?.[3]?.engagement || 0) ? '🟡' : '',
              },
              date: {
                start: new Date(Date.UTC(UNWRAPPED_YEAR, 0, 1)),
                min: new Date(Date.UTC(UNWRAPPED_YEAR, 0, 1)),
                max: new Date(Date.UTC(UNWRAPPED_YEAR, 11, 31)),
              },
              data: {
                source: linkedinData.stats.heatmapData,
                x: 'date',
                y: 'value',
                defaultValue: 0,
              },
              scale: {
                color: {
                  range: key == 'topPosts' ? ['#2a1a4d', '#E54100'] : ["#2a1a4d", "#6700E5", "#DD00E5", "#E54100", "#FFC21A", "#FFD86E", "#FFFFFF"],
                  interpolate: 'rgb',
                  type: 'linear',
                  domain: key == 'topPosts' ? [0, (linkedinData?.stats?.topPosts?.[3]?.engagement || linkedinData?.stats?.topPosts?.[0]?.engagement)] : [0, 10, 20, 50, 100, 500, 10000],
                },
              },
              theme: 'dark',
            },
            [
              [
                Tooltip,
                {
                  text: (date, value, dayjsDate) => {
                    const post = linkedinData.stats.heatmapData?.find(
                      post => post.date === dayjsDate.format('YYYY-MM-DD')
                    );
                    if (!post?.posts?.[0]) return '';
                    
                    return `
                      <div style="padding: 10px; max-width: 300px;">
                        <b>${dayjsDate.format('MMM D, YYYY')}</b>
                        <br/>⭐ ${value} engagement
                        <br/>"${post.posts[0].text?.substring(0, 100)}..."
                        <br/><small>Click to view full post</small>
                      </div>
                    `;
                  }
                }
              ],
              [CalendarLabel, {
                width: isMobile ? 18 : 30,
                text: () => ['', 'Mon', '', 'Wed', '', 'Fri', ''],
                textAlign: 'start',
              }],
              [
                LegendLite,
                {
                  itemSelector: `#legend-${key}`,
                  radius: 2,
                  width: 8,
                  height: 8,
                  gutter: 2,
                },
              ],
            ]);

            cal.on('click', (event, timestamp, value) => {
              const date = dayjs(timestamp).format('YYYY-MM-DD');
              const post = linkedinData.stats.heatmapData?.find(
                post => post.date === date
              );
              if (post?.posts?.[0]) {
                setSelectedPost(post.posts[0]);
              }
            });

            newCalendars[key] = cal;
          }
        });

        await Promise.all(calendarPromises);
        setCalendars(newCalendars);

        // After calendars are initialized, wait a bit and preprocess content
        if (linkedinData && !isPreprocessing && (!processedCanvases || Object.keys(processedCanvases).length == 0)) {
          setTimeout(() => {
            console.log('Preprocessing content after calendars initialized');
            if (process.env.NODE_ENV === 'development') {
              console.log('Skipping preprocessing in dev environment');
            } else {
              preprocessContent(cardRefs);
            }
          }, 2000);
        }
      }
    };

    initCalendars();

    return () => {
      Object.values(calendars).forEach(cal => {
        if (cal) cal.destroy();
      });
    };
  }, [linkedinData]);

  useEffect(() => {
    if (router.query.id) {
      const isCompany = router.query.id.includes('company-')
      const url = isCompany ? `https://www.linkedin.com/company/${router.query.id.replace('company-', '')}/` : 
        `https://www.linkedin.com/in/${router.query.id}/`
      setLinkedinUrl(url);
      // set to true if we want to only enable generation through button and not url
      fetchLinkedInData(url, '', false, false);
    }
  }, [router.query.id]);

  // Replace preprocessImage, preprocessPdf, and preprocessAllContent with this single function
  const preprocessContent = async (cardRefs) => {
    setIsPreprocessing(true);
    try {
      console.log('Preprocessing content...');
      const canvases = {};
      const images = {};

      // Create PDF
      const pdf = new jsPDF('p', 'px', [600, 750], true);
      let isFirst = true;
      
      // Process all canvases and convert to images
      for (const [key, ref] of Object.entries(cardRefs)) {
        if (ref.current) {
          ref.key = key
          console.log('Processing canvas for', key);
          try {
            const canvas = await html2canvas(ref.current, {
              scale: 2,
              useCORS: true,
              logging: false,
              backgroundColor: '#11042E',
            });
            canvases[key] = canvas;
            images[key] = canvas.toDataURL('image/png');

            if (!isFirst) {
              pdf.addPage();
            }
            isFirst = false;
            pdf.addImage(images[key], 'PNG', 0, 0, 600, 750);
          } catch (error) {
            console.error(`Error preprocessing canvas for ${key}:`, error);
          }
        }
      }

      // Store all processed content
      setProcessedContent({
        canvases,
        images,
        pdf
      });
      
      console.log('Preprocessing complete');
    } catch(error) {
      console.error('Error preprocessing content:', error);
      toast.error('Error preprocessing content. Please try again.');
    } finally {
      setIsPreprocessing(false);
      console.log('Preprocessing complete 2');
    }
  };

  // Simplified download functions that use preprocessed data
  const downloadCardAsImage = async (cardRef, filename) => {
    try {
      setDownloadingCard(filename);
      const imgData = processedContent.images[cardRef.key];
      
      if (imgData) {
        const link = document.createElement('a');
        link.download = `${filename}.png`;
        link.href = imgData;
        link.click();
      } else {
        throw new Error('Failed to process image');
      }
    } catch (error) {
      console.error('Error downloading image:', error);
      toast.error('Error downloading image. Please try again.');
    } finally {
      setDownloadingCard(null);
    }
  };

  const downloadAllImages = async () => {
    try {
      setDownloadingAll(prev => ({ ...prev, images: true }));
      analytics.track('Unwrapped image download', {
        linkedinUrl: linkedinUrl,
        email: email,
      });
      const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
      
      for (const [key, imgData] of Object.entries(processedContent.images)) {
        const link = document.createElement('a');
        link.download = `${IMAGE_FILENAMES_MAP[key]}.png`;
        link.href = imgData;
        link.click();
        await delay(300);
      }
    } catch (error) {
      console.error('Error downloading all images:', error);
      toast.error('Error downloading images. Please try again.');
    } finally {
      setDownloadingAll(prev => ({ ...prev, images: false }));
    }
  };

  const saveAllAsPDF = async () => {
    try {
      setDownloadingAll(prev => ({ ...prev, pdf: true }));
      if (processedContent.pdf) {
        processedContent.pdf.save('linkedin-unwrapped-by-cleve-ai.pdf');
        analytics.track('Unwrapped PDF download', {
          linkedinUrl: linkedinUrl,
          email: email,
        });
      } else {
        throw new Error('PDF not ready');
      }
    } catch (error) {
      console.error('Error saving PDF:', error);
      toast.error('Error saving PDF. Please try again.');
    } finally {
      setDownloadingAll(prev => ({ ...prev, pdf: false }));
    }
  };

  const validateLinkedInUrl = (url) => {
    // Check for linkedin.com/in/ followed by at least 1 character
    return (url.includes('linkedin.com/in/') || url.includes('linkedin.com/company/')) && getLinkedinIdFromUrl(url).length > 0;
  };

  const cleanLinkedInUrl = (url) => {
    // Remove any query params or sub paths after the profile name
    const isCompany = url.includes('linkedin.com/company/')
    const cleanedUrl = url.split(isCompany ? 'linkedin.com/company/' : 'linkedin.com/in/')[1].split('/')[0].split('?')[0];
    const normalizedUrl = isCompany ? `https://www.linkedin.com/company/${cleanedUrl}` : `https://linkedin.com/in/${cleanedUrl}`;
    return normalizedUrl;
  }

  const getLinkedinIdFromUrl = (url) => {
    const isCompany = url.includes('linkedin.com/company/')
    const cleanedUrl = cleanLinkedInUrl(url);
    const linkedinId = cleanedUrl.split(isCompany ? '/company/' : '/in/')[1];
    return linkedinId;
  }

  // Update the fetchLinkedInData function to include email validation and sending
  const fetchLinkedInData = async (linkedinUrl, email, cache = false, emailValidation = true) => {
    let loadingInterval;
    try {
      if (!linkedinUrl) {
        setError('Please enter a LinkedIn profile URL');
        toast.error('Please enter a LinkedIn profile URL');
        return;
      }

      if (!validateLinkedInUrl(linkedinUrl)) {
        setError('Please enter a valid LinkedIn profile URL (e.g., https://linkedin.com/in/username)');
        toast.error('Please enter a valid LinkedIn profile URL (e.g., https://linkedin.com/in/username)');
        setIsValidUrl(false);
        return;
      }

      // Uncomment this if we want to require email
      // commented to 
      if (emailValidation && !email) {
        setError('Please enter your email');
        toast.error('Please enter your email');
        return;
      }

      if (emailValidation && email && !validateEmail(email)) {
        setError('Please enter a valid email address');
        toast.error('Please enter a valid email address');
        return;
      }

      // Remove any query params or sub paths after the profile name
      const cleanedUrl = cleanLinkedInUrl(linkedinUrl);
      const linkedinId = getLinkedinIdFromUrl(linkedinUrl);
      setLinkedinUrl(cleanedUrl);

      setIsLoading(true);
      // Set up loading text that changes every 3 seconds
      

      let messageIndex = 0;
      loadingInterval = setInterval(() => {
        setLoadingText(LOADING_MESSAGES[messageIndex]);
        messageIndex = (messageIndex + 1) % LOADING_MESSAGES.length;
      }, 4000);
      setError(null);
      setIsValidUrl(true);

      console.log(cleanedUrl)
      console.log(`${window.location.href.split('?')[0]}/${linkedinId}`)
      
      const data = await apiRequest('linkedin-unwrapped', 'POST', {
        action: 'wrapped',
        user: auth.user,
        linkedinAccessToken: auth.user?.linkedinAccessToken,
        linkedinUrl: cleanedUrl,
        email: email,
        cache: cache,
      });
      analytics.track('Get linkedin unwrapped', {
        linkedinUrl: cleanedUrl,
        email: email,
        unwrappedUrl: `${window.location.href.split('?')[0]}/${linkedinId}`,
      });
      localStorage.setItem('linkedinUrl', cleanedUrl);
      localStorage.setItem('email', email);

      // console.log('LinkedIn API Response:', data);
      setLinkedinData(data);
    } catch (err) {
      console.error('Error fetching LinkedIn data:', err);
      setError(err?.message || 'Failed to fetch LinkedIn data');
      toast.error(err?.message || 'Failed to fetch LinkedIn data');
      
      // Set mock data for testing
      // setLinkedinData(LINKEDIN_WRAPPED_DATA);
    } finally {
      setIsLoading(false);
      clearInterval(loadingInterval)
    }
  };

  const createMockPost = async () => {
    try {
      setIsPosting(true);
      setError(null);
      
      const timestamp = new Date().toLocaleTimeString();
      const postContent = `🎉 Just got my LinkedIn Wrapped from Cleve.ai! (${timestamp})
        
📊 My 2024 stats:
• ${linkedinData?.stats.totalPosts || 0} posts
• ${linkedinData?.stats?.totalReactions || 0} total reactions
• ${linkedinData?.stats.totalComments || 0 } comments

Want to see yours? Check out cleve.ai/linkedin-wrapped

#LinkedInWrapped #ProfessionalGrowth #LinkedInEngagement`;

      // Make the actual API call
      await apiRequest('linkedin-unwrapped', 'POST', {
        action: 'createPost',
        user: auth.user,
        linkedinAccessToken: auth.user?.linkedinAccessToken,
        post: {
          commentary: postContent,
          visibility: "PUBLIC",
          distribution: {
            feedDistribution: "MAIN_FEED",
            targetEntities: [],
            thirdPartyDistributionChannels: []
          },
          lifecycleState: "PUBLISHED",
          isReshareDisabledByAuthor: false
        }
      });

      // Show success message
      toast.success('Post created successfully!');
      
    } catch (err) {
      toast.error(err?.message || 'Failed to create post');
    } finally {
      setIsPosting(false);
    }
  };

  const renderHeatmap = (id) => (
    <Box className={classes.heatmapContainer}>
      {id == 'main' && <Typography style={{ fontSize: '10px', color: '#FFFFFF', lineHeight: 1, marginBottom: '12px', textAlign: 'center' }}>
        2024 Content Snapshot
      </Typography>}
      <div ref={calendarRefs[id]} style={{ margin: '0 auto', maxWidth: '100%', color: '#fff' }}></div>

      {id == 'main' && <div style={{ float: 'right', fontSize: 7, padding: 10, display: 'flex', alignItems: 'center' }}>
        <span style={{ color: '#768390' }}>less</span>
        <div
          id={`legend-${id}`}
          style={{ display: 'inline-block', margin: '0 4px' }}
        ></div>
        <span style={{ color: '#768390', fontSize: 7 }}>more</span>
      </div>}
    </Box>
  );

  const renderPostDialog = () => (
    <Dialog 
      open={!!selectedPost}
      onClose={() => setSelectedPost(null)}
      maxWidth="sm"
      fullWidth
      // className={classes.statCard}
    >
      <DialogTitle>
        {selectedPost?.postedDate && dayjs(selectedPost.postedDate).format('MMMM D, YYYY')}
      </DialogTitle>
      <DialogContent>
        <Typography style={{ whiteSpace: 'pre-wrap', marginBottom: '16px' }}>
          {selectedPost?.text}
        </Typography>
        {selectedPost?.image?.[0] && (
          <Box mt={2}>
            <img 
              src={selectedPost.image[0].url}
              alt="Post content"
              style={{ maxWidth: '100%', height: 'auto' }}
            />
          </Box>
        )}
        <Box mt={2}>
          <Typography variant="body2" color="textSecondary">
            ⭐ {formatNumber(selectedPost?.totalReactionCount || 0)} reactions
            • 💬 {formatNumber(selectedPost?.commentsCount || 0)} comments  
            • 🔄 {formatNumber(selectedPost?.repostsCount || 0)} reposts
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        {selectedPost?.postUrl && (
          <Button 
            href={selectedPost.postUrl}
            target="_blank"
            color="primary"
          >
            View on LinkedIn
          </Button>
        )}
        <Button onClick={() => setSelectedPost(null)} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );

  const PostingPatternStreaksCard = ({ data }) => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
    const [downloadingCard, setDownloadingCard] = useState(false);

    const handleDownload = async () => {
      try {
        setDownloadingCard(true);
        await downloadCardAsImage(cardRefs.streaks, IMAGE_FILENAMES[8]);
      } finally {
        setDownloadingCard(false);
      }
    };

    // Prepare data for month chart
    const monthLabels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const monthData = monthLabels.map((month, index) => ({
      month,
      posts: data.stats.byMonth[`2024-${index + 1}`]?.posts || 0,
      avgEngagement: data.stats.byMonth[`2024-${index + 1}`]?.posts && data.stats.byMonth[`2024-${index + 1}`]?.posts > 3 ? 
        Math.round((data.stats.byMonth[`2024-${index + 1}`]?.engagement || 0) / data.stats.byMonth[`2024-${index + 1}`]?.posts) : 'Not enough data'
    }));

    const CustomTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <Box style={{
            backgroundColor: '#19083d',
            padding: '10px',
            border: '1px solid #462986',
            borderRadius: '4px',
          }}>
            <Typography style={{ color: '#fff' }}>
              {label}
            </Typography>
            <Typography style={{ color: '#fff' }}>
              {payload[0].value === 'Not enough data' ? 
                'Not enough data' : 
                `${payload[0].value} ${payload[0].dataKey === 'posts' ? 'posts' : 'engagement'}`}
            </Typography>
          </Box>
        );
      }
      return null;
    };

    const renderChart = (data, xKey, dataKey, color) => (
      <div style={{ width: '100%', height: isMobile ? 80 : 150 }}>
        <ResponsiveContainer>
          <BarChart data={data}>
            <CartesianGrid strokeDasharray="3 3" stroke="rgba(255,255,255,0.1)" />
            <XAxis dataKey={xKey} tick={{ fill: '#fff' }} />
            <YAxis tick={{ fill: '#fff' }} />
            <RechartsTooltip content={<CustomTooltip />} />
            <Bar dataKey={dataKey} fill={color} radius={[4, 4, 0, 0]} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );

    return (
      <Box className={classes.card} ref={cardRefs.streaks} style={{ marginBottom: '32px' }}>
        <Button
          className={classes.downloadButton}
          onClick={handleDownload}
          disabled={downloadingCard}
        >
          {downloadingCard ? 
            <CircularProgress size={24} color="inherit" /> : 
            <GetApp />
          }
        </Button>
        <img src="/wrapped/wrapped-header-3.webp" alt="header" style={{ width: '100%' }} />
        
        <Typography align="center" style={{ 
          fontSize: isMobile ? '20px' : '32px', 
          fontWeight: 'bold',
          marginTop: '15px',
          color: '#fff',
        }}>
          Your 2024 consistency stats
        </Typography>
        <Typography align="center" style={{ 
          fontSize: isMobile ? '12px' : '20px',
          color: '#fff',
          margin: '10px 0px',
        }}>
          You've done great! Let's aim higher in 2025 🥂 
        </Typography>

        <Grid container style={{ margin: '0px', padding: '0px 10px' }}>
          <Grid item xs={6} >
            <Box className={classes.streakBox}>
              <Typography style={{ color: '#fff', marginBottom: '10px', fontSize: isMobile ? '10px' : '16px' }}>
                Longest Weekly Streak
              </Typography>
              <Typography variant="h4" style={{ color: '#fff', fontWeight: 'bold', fontSize: isMobile ? '20px' : '24px' }}>
                🔥{data.stats.longestWeeklyStreak}
              </Typography>
              
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className={classes.streakBox}>
              <Typography style={{ color: '#fff', marginBottom: '10px', fontSize: isMobile ? '10px' : '16px' }}>
                Longest Daily Streak (Weekday)
              </Typography>
              <Typography variant="h4" style={{ color: '#fff', fontWeight: 'bold', fontSize: isMobile ? '20px' : '24px' }}>
                🤯{data.stats.longestDailyStreak}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Box className={classes.chartCard}>
          <Typography style={{ color: '#fff', marginBottom: '8px' }}>
            Number of Posts by Month
          </Typography>
          {renderChart(monthData, 'month', 'posts', '#FFD86E')}
          
          <Typography style={{ color: '#fff', marginBottom: '8px', marginTop: '4px' }}>
            Average Engagement per Post by Month
          </Typography>
          {renderChart(monthData, 'month', 'avgEngagement', '#DD00E5')}
        </Box>

        <CardFooter text={linkedinData?.profile?.full_name || auth.user?.name || ''} />
      </Box>
    );
  };

  const PostingPatternHourlyCard = ({ data }) => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
    const [downloadingCard, setDownloadingCard] = useState(false);
    const userTimezoneOffset = new Date().getTimezoneOffset() / 60;

    const handleDownload = async () => {
      try {
        setDownloadingCard(true);
        await downloadCardAsImage(cardRefs.hourly, IMAGE_FILENAMES[9]);
      } finally {
        setDownloadingCard(false);
      }
    };

    // Prepare data for hour chart
    const hourData = Array.from({length: 24}, (_, i) => {
      // Calculate the UTC hour that corresponds to this local hour
      const utcHour = (i + userTimezoneOffset + 24) % 24;
      
      return {
        hour: `${i}:00`,
        posts: data.stats.byHour[Math.floor(utcHour)]?.posts || 0,
        avgEngagement: data.stats.byHour[Math.floor(utcHour)]?.posts && 
          data.stats.byHour[Math.floor(utcHour)]?.posts > 3 ? 
          Math.round((data.stats.byHour[Math.floor(utcHour)]?.engagement || 0) / 
          data.stats.byHour[Math.floor(utcHour)]?.posts) : 
          'Not enough data'
      };
    });

    const CustomTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <Box style={{
            backgroundColor: '#19083d',
            padding: '10px',
            border: '1px solid #462986',
            borderRadius: '4px',
          }}>
            <Typography style={{ color: '#fff' }}>
              {label}
            </Typography>
            <Typography style={{ color: '#fff' }}>
              {payload[0].value === 'Not enough data' ? 
                'Not enough data' : 
                `${payload[0].value} ${payload[0].dataKey === 'posts' ? 'posts' : 'engagement'}`}
            </Typography>
          </Box>
        );
      }
      return null;
    };

    const renderChart = (data, xKey, dataKey, color, interval = 0) => (
      <div style={{ width: '100%', height: isMobile ? 80 : 150 }}>
        <ResponsiveContainer>
          <BarChart data={data}>
            <CartesianGrid strokeDasharray="3 3" stroke="rgba(255,255,255,0.1)" />
            <XAxis 
              dataKey={xKey} 
              tick={{ fill: '#fff' }}
              interval={interval}
            />
            <YAxis tick={{ fill: '#fff' }} />
            <RechartsTooltip content={<CustomTooltip />} />
            <Bar dataKey={dataKey} fill={color} radius={[4, 4, 0, 0]} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );

    return (
      <Box className={classes.card} ref={cardRefs.hourly} style={{ marginBottom: '32px' }}>
        <Button
          className={classes.downloadButton}
          onClick={handleDownload}
          disabled={downloadingCard}
        >
          {downloadingCard ? 
            <CircularProgress size={24} color="inherit" /> : 
            <GetApp />
          }
        </Button>
        <img src="/wrapped/wrapped-header-3.webp" alt="header" style={{ width: '100%' }} />
        
        <Typography align="center" style={{ 
          fontSize: isMobile ? '24px' : '32px', 
          fontWeight: 'bold',
          marginTop: '25px',
          color: '#fff',
        }}>
          Your 2024 posting timings
        </Typography>
        <Typography align="center" style={{ 
          fontSize: isMobile ? '16px' : '20px',
          color: '#fff',
          margin: '10px 0px',
        }}>
          And the respective engagement on the posts
        </Typography>

        <Box className={classes.chartCard}>
          <Typography style={{ color: '#fff', marginBottom: '8px' }}>
            Number of Posts by Hour {`(${new Date().toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2]})`}
          </Typography>
          {renderChart(hourData, 'hour', 'posts', '#FFD86E', 3)}
          
          <Typography style={{ color: '#fff', marginBottom: '8px', marginTop: '24px' }}>
            Average Engagement per Post by Hour {`(${new Date().toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2]})`}
          </Typography>
          {renderChart(hourData, 'hour', 'avgEngagement', '#DD00E5', 3)}
        </Box>

        {!isMobile && <img 
          src="/wrapped/wrapped-footer-3.webp" 
          alt="footer" 
          className={classes.cardFooter}
        />}

        <CardFooter text={linkedinData?.profile?.full_name || auth.user?.name || ''} />
      </Box>
    );
  };

  const PostingPatternWeeklyCard = ({ data }) => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
    const [downloadingCard, setDownloadingCard] = useState(false);

    const handleDownload = async () => {
      try {
        setDownloadingCard(true);
        await downloadCardAsImage(cardRefs.weekly, IMAGE_FILENAMES[10]);
      } finally {
        setDownloadingCard(false);
      }
    };

    // Prepare data for day chart
    const dayLabels = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const dayData = dayLabels.map((day, index) => ({
      day,
      posts: data.stats.byDay[index]?.posts || 0,
      avgEngagement: data.stats.byDay[index]?.posts && data.stats.byDay[index]?.posts > 3 ? 
        Math.round((data.stats.byDay[index]?.engagement || 0) / data.stats.byDay[index]?.posts) : 'Not enough data'
    }));

    const CustomTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <Box style={{
            backgroundColor: '#19083d',
            padding: '10px',
            border: '1px solid #462986',
            borderRadius: '4px',
          }}>
            <Typography style={{ color: '#fff' }}>
              {label}
            </Typography>
            <Typography style={{ color: '#fff' }}>
              {payload[0].value === 'Not enough data' ? 
                'Not enough data' : 
                `${payload[0].value} ${payload[0].dataKey === 'posts' ? 'posts' : 'engagement'}`}
            </Typography>
          </Box>
        );
      }
      return null;
    };

    const renderChart = (data, xKey, dataKey, color) => (
      <div style={{ width: '100%', height: isMobile ? 80 : 150 }}>
        <ResponsiveContainer>
          <BarChart data={data}>
            <CartesianGrid strokeDasharray="3 3" stroke="rgba(255,255,255,0.1)" />
            <XAxis dataKey={xKey} tick={{ fill: '#fff' }} />
            <YAxis tick={{ fill: '#fff' }} />
            <RechartsTooltip content={<CustomTooltip />} />
            <Bar dataKey={dataKey} fill={color} radius={[4, 4, 0, 0]} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );

    return (
      <Box className={classes.card} ref={cardRefs.weekly} style={{ marginBottom: '32px' }}>
        <Button
          className={classes.downloadButton}
          onClick={handleDownload}
          disabled={downloadingCard}
        >
          {downloadingCard ? 
            <CircularProgress size={24} color="inherit" /> : 
            <GetApp />
          }
        </Button>
        <img src="/wrapped/wrapped-header-3.webp" alt="header" style={{ width: '100%' }} />
        
        <Typography align="center" style={{ 
          fontSize: isMobile ? '24px' : '32px', 
          fontWeight: 'bold',
          marginTop: '25px',
          color: '#fff',
        }}>
          Your 2024 posting days
        </Typography>
        <Typography align="center" style={{ 
          fontSize: isMobile ? '16px' : '20px',
          color: '#fff',
          margin: '10px 0px',
        }}>
          And the respective engagement on the posts
        </Typography>
        

        <Box className={classes.chartCard}>
          <Typography style={{ color: '#fff', marginBottom: '8px' }}>
            Number of Posts by Day of Week
          </Typography>
          {renderChart(dayData, 'day', 'posts', '#FFD86E')}
          
          <Typography style={{ color: '#fff', marginBottom: '8px', marginTop: '24px' }}>
            Average Engagement per Post by Day
          </Typography>
          {renderChart(dayData, 'day', 'avgEngagement', '#DD00E5')}
        </Box>

        {!isMobile && <img 
          src="/wrapped/wrapped-footer-3.webp" 
          alt="footer" 
          className={classes.cardFooter}
        />}

        <CardFooter text={linkedinData?.profile?.full_name || auth.user?.name || ''} />
      </Box>
    );
  };

  // Then update handleCopyCaption to use this function
  const handleCopyCaption = () => {
    const caption = generateWrappedCaption(linkedinData);
    navigator.clipboard.writeText(caption);
    setCopiedState(true);
    setTimeout(() => {
      setCopiedState(false);
    }, 2000);
    //toast.success('Caption copied to clipboard!');
  };

  // Add this near the top of the file with other imports
  const { ref: ctaRef, inView: ctaInView } = useInView({
    threshold: 0.3, // Trigger when 30% of the element is visible
    triggerOnce: true // Only trigger once
  });

  // Add useEffect to track when CTA becomes visible
  useEffect(() => {
    if (ctaInView) {
      console.log('CTA in view');
      analytics.track('Unwrapped Scrolled to CTA', {
        linkedinUrl: linkedinUrl,
        email: email
      });
    }
  }, [ctaInView]);

  // Add this near the top of the file with other state declarations:
  const selectedCTAVariation = React.useMemo(() => 
    CTA_COMPONENTS[Math.floor(Math.random() * CTA_COMPONENTS.length)],
    //CTA_COMPONENTS.find(component => component.id === 'lite'),
  []);

  return (
    <Box>
      <Meta 
        key="linkedin-unwrapped"
        title="LinkedIn Unwrapped: Get Your Personalized 2024 Insights Free | Ultimate Social Media Recap Tool" 
        description="Discover your top LinkedIn moments of 2024 with our free LinkedIn Unwrapped tool! Uncover insights into your most engaging posts, standout trends, and key achievements. Perfect for professionals and entrepreneurs to reflect and grow. Get yours now free!"
        image="/wrapped/wrapped-social-sharing.webp"
      />
      {!auth?.user && <Box 
        component="button"
        onClick={() => {
          analytics.track('Unwrapped Banner Click', {
            linkedinUrl: linkedinUrl,
            email: email
          });
          if (linkedinData) {
            const couponUrl = timeLeft ? 'coupon=promo_1QhZ3CAZgRS3d3EZ7CCdt57d' : 'coupon=promo_1QZyh3AZgRS3d3EZcqxlyBhQ';
            const nextUrl = auth.user ? `/purchase/lite?${couponUrl}` : `/auth/signup?next=/purchase/lite?${couponUrl}`;
            window.open(`https://notes.cleve.ai${nextUrl}`, '_blank');
          } else {
            window.open('https://cleve.ai', '_blank');
          }
        }}
        style={{
          width: '100%',
          backgroundColor: '#FFD700',
          textAlign: 'center', 
          marginBottom: '24px',
          padding: '8px',
          textDecoration: 'none',
          cursor: 'pointer',
          border: 'none'
        }}
      >
        {linkedinData ? (
          <CountdownBanner timeLeft={timeLeft} />
        ) : (
          <Typography style={{
            color: '#000',
            fontSize: isMobile ? '0.75em' : '1em',
            fontWeight: 700,
          }}>
            Made with ❤️ by the Cleve.ai team. If you loved it, you might like our AI note-taking tool that helps you build your personal brand faster in 2025. <span style={{textDecoration: 'underline'}}>Learn more</span>
          </Typography>
        )}
      </Box>
      }
      <Box 
        style={{ 
          // backgroundColor: '#11042E',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: isMobile? '10px 20px' : '40px 20px'
        }}
      >
      
        
      {isLoading &&
          <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', maxWidth: '360px', marginTop: '40px'}}>
            {/* <Typography
              variant="body2"
              align="center"
              style={{
                color: '#FFFFFF',
                textAlign: 'center',
                opacity: 0.8,
                fontSize: isMobile ? '10px' : '12px'
              }}
            >
              This may take 1 - 2 min to complete.
            </Typography> */}
            <img src="/wrapped/working-cat2.gif" alt="Loading" style={{ width: '240px', marginBottom: '16px' }} />
            <Box style={{ width: '100%', marginBottom: 5 }}>
              <LinearProgress 
                variant="indeterminate" 
                style={{
                  height: 8,
                  borderRadius: 4,
                  backgroundColor: 'rgba(255,255,255,0.1)',
                }}
              />
            </Box>
            <Typography variant="h6" style={{ 
              marginTop: 8,
              textAlign: 'center',
              animation: 'fadeInOut 2s infinite'
            }}>
              {loadingText}
            </Typography>
            
          </Box>
      }

      {!linkedinData && !isLoading &&
      <>
      <Typography 
          variant="h3" 
          align="center" 
          style={{ 
            fontWeight: 'bold',
            marginBottom: '20px',
            fontSize: '32px'
          }}
        >
          Get Your 2024
          <br />
          LinkedIn Unwrapped
        </Typography>

        <Typography 
          align="center" 
          style={{ 
            marginBottom: '20px',
            maxWidth: '600px',
            fontSize: '15px'
          }}
        >
          See how you crushed it on LinkedIn this year!  Discover your top posts,
          key trends, biggest insights, and who your LinkedIn besties are 🤝
          100% personalized to you.
        </Typography>
      <Box style={{ width: '100%', maxWidth: '500px', margin: '20px 0' }}>
      <TextField
          fullWidth
          // label="LinkedIn Profile URL"
          placeholder="Linkedin profile URL (e.g. https://www.linkedin.com/in/john-doe)"
          value={linkedinUrl}
          onChange={(e) => {
            
            setLinkedinUrl(e.target.value);
            setIsValidUrl(true);
            setError(null);
          }}
          error={!isValidUrl}
          helperText={!isValidUrl ? "Please enter a valid LinkedIn profile URL" : ""}
          variant="outlined"
          InputProps={{
              style: { 
                backgroundColor: '#fff',
                borderRadius: '4px',
                padding: '0px',
                color: 'fieldtext'
              },
            }}
            onKeyDown={(ev) => {
              console.log(`Pressed keyCode ${ev.key}`);
              if (ev.key === 'Enter') {
                // Do code here
                ev.preventDefault();
                fetchLinkedInData(linkedinUrl, email)
              }
            }}
          />
          {/* Add the new email input field */}
          <TextField
            fullWidth
            placeholder="Email address"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setError(null);
            }}
            variant="outlined"
            style={{ marginTop: '10px' }}
            InputProps={{
              style: { 
                backgroundColor: '#fff',
                borderRadius: '4px',
                padding: '0px',
                color: 'fieldtext'
              },
            }}
            onKeyDown={(ev) => {
              if (ev.key === 'Enter') {
                ev.preventDefault();
                fetchLinkedInData(linkedinUrl, email)
              }
            }}
          />
          <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth={true}
            onClick={() => fetchLinkedInData(linkedinUrl, email)}
            disabled={isLoading}
                  style={{
                    backgroundColor: '#FFD700',
                    color: '#000000',
                    borderRadius: '4px',
                    marginTop: '10px',
                  }}
                >
                  Get your free unwrap
          </Button>
          
      </Box>

        <Typography 
          variant="body2" 
          align="center" 
          // onClick={() => window.open('https://linkedin.com', '_blank')}
        >
          Having challenges? <br/>Chat with us live at bottom right corner
          </Typography>

          <Typography 
          variant="body2" 
          align="center" 
          style={{ opacity: 0.7, marginTop: '20px', fontSize: isMobile? '10px' : '12px' }}
          // onClick={() => window.open('https://linkedin.com', '_blank')}
        >
          By using this tool, you agree to our <a href="/legal/terms-of-service" style={{ color: '#FFD700', textDecoration: 'underline' }}>Terms of Service</a> and <a href="/legal/privacy-policy" style={{ color: '#FFD700', textDecoration: 'underline' }}>Privacy Policy</a>
          </Typography>

          <img 
              src="/wrapped/wrapped-preview.webp" 
              alt="LinkedIn Wrapped Preview" 
              style={{ 
                maxWidth: '65%',
                height: 'auto',
                borderRadius: '12px',
                display: 'flex',
                margin: '0 auto',
                marginTop: '10px'
              }}
            />
      </>
      }
        
        {linkedinData &&
        <>
        <Typography 
        variant="h5" 
        align="center" 
        style={{ 
          // color: '#FFFFFF',
          fontWeight: 'bold',
          margin: isMobile ? '10px 10px' : '10px 300px'
        }}
      >
        🥳 Your LinkedIn Unwrapped is ready! Scroll to the bottom to download yours 🎊
      </Typography>
      <Typography
          variant="body2"
          align="center"
          style={{
            // color: '#FFFFFF',
            margin: isMobile ? '20px 20px 0px' : '10px 50px 0px',
            opacity: 0.8
          }}
        >
          This report is interactive. Hover on the cards to see what happens!
        </Typography>
      {(isPreprocessing || !processedContent.pdf) && 
      <Box>
        {/* <Typography
          variant="body2"
          align="center"
          style={{
            color: '#FFFFFF',
            margin: isMobile ? '0px 20px 10px' : '0px 300px 10px',
            opacity: 0.8
          }}
        >
          In ~30 seconds your shareable PDF will finish processing and the experience will be smoother. <br/> Scroll below the report to download the PDF.
        </Typography> */}
        {/* <Box style={{ width: '100%', maxWidth: '390px', margin: '20px auto' }}>
          <LinearProgress 
            variant="indeterminate" 
          style={{
            backgroundColor: 'rgba(255,255,255,0.1)',
            height: '4px',
              borderRadius: '10px'
            }}
          />
        </Box> */}
      </Box>
      }

      {/* Add the sharing instructions here - Add this code: */}
      {/* <Box className={classes.sharingSteps}>
        <Typography 
          variant="h6" 
          style={{ 
            color: '#fff', 
            marginBottom: theme.spacing(2),
            textAlign: 'left'
          }}
        >
          Be the first to share it with your friends!
        </Typography>
        {SHARING_STEPS.map((step) => (
          <Box key={step.step} className={classes.stepContainer}>
            <Typography className={classes.stepText}>
              {step.step}. {step.text}
            </Typography>
            {step.action && (
              <Button
                variant="contained"
                className={classes.stepButton}
                startIcon={downloadingAll.pdf ? <CircularProgress size={20} color="inherit" /> : step.icon}
                onClick={saveAllAsPDF}
                disabled={isPreprocessing || downloadingAll.pdf || downloadingAll.images || !processedContent.pdf}
              >
                {isPreprocessing || !processedContent.pdf ? 'Processing PDF...' : 
                  (downloadingAll.pdf ? 'Downloading...' : step.action)}
              </Button>
            )}
            {step.buttons && (
              <Box className={classes.stepButtons}>
                {step.buttons.map((button, index) => (
                  <Button
                    key={index}
                    variant="contained"
                    className={button.variant === 'linkedin' ? classes.linkedinButton : classes.defaultButton}
                    startIcon={button.variant === 'linkedin' ? 
                      <LinkedIn /> : 
                      (copiedState ? <CheckCircle /> : <PostAdd />)}
                    onClick={button.variant === 'linkedin' ? 
                      () => {
                        const caption = generateWrappedCaption(linkedinData);
                        window.open(getShareUrl(caption), '_blank');
                      } : 
                      handleCopyCaption}
                  >
                    {button.variant === 'linkedin' ? 
                      button.label : 
                      (copiedState ? "Copied!" : button.label)}
                  </Button>
                ))}
              </Box>
            )}
          </Box>
        ))}

        
      </Box>
      <Box className={classes.otherSharingOptions}>
          <Typography variant="h6" className={classes.otherSharingTitle}>
            Other sharing options
          </Typography>
          <Box className={classes.otherSharingButtons}>
            <Button
              variant="contained"
              color="secondary"
              startIcon={downloadingAll.images ? <CircularProgress size={20} color="inherit" /> : <GetApp />}
              onClick={downloadAllImages}
              disabled={isPreprocessing || downloadingAll.images || Object.keys(processedContent.images).length === 0}
            >
              {isPreprocessing || processedContent.images.length === 0 ? 'Processing images...' : 
                (downloadingAll.images ? 'Downloading...' : 'Save (images)')}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              startIcon={copiedLinkState ? <CheckCircle /> : <Link />}
              onClick={() => {
                const isCompany = linkedinUrl.includes('linkedin.com/company/')
                const publicIdentifier = linkedinUrl.split(isCompany ? '/company/' : '/in/')[1]?.replace(/\/$/, '') || '';
                const shareUrl = `https://notes.cleve.ai/unwrapped/${isCompany ? 'company-' : ''}${publicIdentifier}`;
                navigator.clipboard.writeText(shareUrl);
                setCopiedLinkState(true);
                setTimeout(() => {
                  setCopiedLinkState(false);
                }, 2000); // Reset after 2 seconds
                // toast.success('Link copied to clipboard!');
              }}
            >
              {copiedLinkState ? "Copied!" : "Copy link"}
            </Button>
          </Box>
        </Box> */}

      {/* Add the action buttons here */}
      {/* <Box className={classes.actionButtons}>
        <Button
          variant="contained"
          color="primary"
          className={classes.actionButton}
          startIcon={downloadingAll.pdf ? <CircularProgress size={20} color="inherit" /> : <GetApp />}
          onClick={() => saveAllAsPDF(cardRefs)}
          disabled={downloadingAll.pdf || downloadingAll.images}
        >
          {downloadingAll.pdf ? 'Saving...' : 'Save (PDF)'}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.actionButton}
          startIcon={downloadingAll.images ? <CircularProgress size={20} color="inherit" /> : <GetApp />}
          onClick={() => downloadAllImages(cardRefs)}
          disabled={downloadingAll.pdf || downloadingAll.images}
        >
          {downloadingAll.images ? 'Saving...' : 'Save (Images)'}
        </Button>
      </Box> */}

        <Box style={{ marginTop: '40px', 
          backgroundColor: !linkedinData ? '#11042E' : '#A47DFE', 
          padding: isMobile? '10px' : '40px', 
          borderRadius: '12px' }}>
        
              

              {/* First Card - Original Statistics */}
              <CardWrapper 
                cardRef={cardRefs.stats} 
                downloadFileName={IMAGE_FILENAMES[0]}
                onDownload={downloadCardAsImage}
                headerImage="/wrapped/wrapped-header.webp"
                hideFooterImage={true}
                footerRightText={linkedinData?.profile?.full_name || auth.user?.name || ''}
              >
                <Box className={classes.cardContent}>
                  <Typography align="center" style={{ 
                    fontSize: isMobile? '28px' : '56px', 
                    fontWeight: 800,
                    fontOpticalSizing: 'auto',
                    fontStyle: 'normal',
                    lineHeight: 1,
                    marginTop: '30px'
                  }}>
                    {UNWRAPPED_YEAR}
                  </Typography>
                  <Typography align="center" style={{ fontSize: isMobile? '20px' : '28px', fontWeight: 'bold', }}>  
                    LinkedIn Unwrapped
                  </Typography>

                  <img src="/wrapped/wrapped-button.webp" alt="button" style={{ width: isMobile? '120px' : '160px' }} />

                  {linkedinData?.profile?.profile_pic_url && <Avatar 
                    src={linkedinData?.profile?.profile_pic_url || `https://api.dicebear.com/9.x/fun-emoji/jpg?seed=${linkedinData?.profile?.first_name || linkedinData?.profile?.full_name}&radius=10&backgroundColor=059ff2,71cf62,d84be5,fcbc34&backgroundRotation=360,110,0&eyes=closed,closed2,cute,glasses,plain,sad,shades,sleepClose,stars,wink,wink2&mouth=cute,lilSmile,smileLol,smileTeeth,wideSmile`} 
                    style={{ 
                      width: isMobile? '80px' : '120px', 
                      height: isMobile? '80px' : '120px', 
                      margin: '10px auto 0px',
                      backgroundColor: '#19083d',
                      fontSize: isMobile? '32px' : '48px'
                    }}
                  >
                    {linkedinData?.profile?.first_name?.charAt(0) || linkedinData?.profile?.full_name?.charAt(0) || ''}
                  </Avatar>}

                  <Typography variant="subtitle1" align="center" style={{ color: '#FFFFFF', marginTop: '10px' }}>
                    {(linkedinData?.profile?.full_name || auth.user?.name || '').substring(0, 35)}
                  </Typography>
                  {/* <Box className={classes.statCard} style={{ width: '100%', padding: isMobile? '8px' : '16px', marginTop: '16px', marginBottom: '-8px'}}> */}
                    {renderHeatmap('main')}
                  {/* </Box> */}
                  {/* Engagement Stats */}
                  <Box style={{ margin: '0px 30px', width: '100%', padding: '8px 15px', maxWidth: '520px' }}>
                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <Box className={classes.statCard}>
                          <Typography className={classes.statValue}>
                            {formatNumber(linkedinData.stats.totalPosts)}
                          </Typography>
                          <Typography className={classes.statLabel}>
                            Posts
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box className={classes.statCard}>
                          <Typography className={classes.statValue}>
                            {formatNumber(linkedinData.stats.totalReactions)}
                          </Typography>
                          <Typography className={classes.statLabel}>
                            Reactions
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box className={classes.statCard}>
                          <Typography className={classes.statValue}>
                            {formatNumber(linkedinData.stats.totalComments)}
                          </Typography>
                          <Typography className={classes.statLabel}>
                            Comments
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </CardWrapper>

              {/* Second Card - Posting Activity */}
              <CardWrapper 
                cardRef={cardRefs.activity} 
                downloadFileName={IMAGE_FILENAMES[1]}
                onDownload={downloadCardAsImage}
                footerRightText={linkedinData?.profile?.full_name || auth.user?.name || ''}
              >
                <Box className={classes.cardContent}>
                  <CardTitle 
                    title={`You posted ${linkedinData.stats.totalPosts} times this year`}
                    subtitle={`You're amongst the top ${Number(linkedinData.stats.percentile || 0.1).toPrecision(2)}% of LinkedIn users globally!`}
                  />
                  {renderHeatmap('activity')}

                  <Box style={{ margin: isMobile? '5px' : '20px', marginBottom: '60px', textAlign: 'center' }}>
                    <Typography style={{ color: '#fff', marginBottom: theme.spacing(0.5), fontSize: isMobile? '10px' : '18px' }}>
                      and people love your writing:
                    </Typography>
                    <Box display="flex" justifyContent="center" style={{gap: isMobile? '12px' : '18px', fontSize: isMobile? '10px' : '18px'}}>
                      {linkedinData?.stats?.totalLikes > 0 && <span><img className={classes.linkedinReactionIcon} src="/linkedin/Like.png" alt="like" />{formatNumber(linkedinData.stats.totalLikes)}</span>}
                      {linkedinData?.stats?.totalAppreciations > 0 && <span><img className={classes.linkedinReactionIcon} src="/linkedin/Love.png" alt="appreciation" />{formatNumber(linkedinData.stats.totalAppreciations)}</span>}
                      {linkedinData?.stats?.totalEmpathy > 0 && <span><img className={classes.linkedinReactionIcon} src="/linkedin/Support.png" alt="empathy" />{formatNumber(linkedinData.stats.totalEmpathy)}</span>}
                      {linkedinData?.stats?.totalInterest > 0 && <span><img className={classes.linkedinReactionIcon} src="/linkedin/Insightful.png" alt="interest" />{formatNumber(linkedinData.stats.totalInterest)}</span>}
                      {linkedinData?.stats?.totalPraise > 0 && <span><img className={classes.linkedinReactionIcon} src="/linkedin/Celebrate.png" alt="praise" />{formatNumber(linkedinData.stats.totalPraise)}</span>}
                      {linkedinData?.stats?.totalFunny > 0 && <span><img className={classes.linkedinReactionIcon} src="/linkedin/Funny.png" alt="funny" />{formatNumber(linkedinData.stats.totalFunny)}</span>}
                    </Box>
                    <Box display="flex" justifyContent="center" style={{gap: isMobile? '12px' : '18px', marginTop: theme.spacing(0.5), fontSize: isMobile? '10px' : '18px'}}>
                      <span>💬{formatNumber(linkedinData.stats.totalComments || 0)}</span>
                      <span>🔄{formatNumber(linkedinData.stats.totalReposts || 0)}</span>
                    </Box>
                  </Box>
                </Box>
              </CardWrapper>

              {/* Third Card - Top Posts */}
              <CardWrapper 
                cardRef={cardRefs.topPosts} 
                downloadFileName={IMAGE_FILENAMES[2]}
                onDownload={downloadCardAsImage}
                hideFooterImage={isMobile}
                footerRightText={linkedinData?.profile?.full_name || auth.user?.name || ''}
              >
                <Box className={classes.cardContent}>
                  <Typography align="center" style={{ 
                    fontSize: isMobile? '24px' : '32px', 
                    fontWeight: 'bold',
                    marginBottom: '20px',
                    marginTop: isMobile? '50px' : '0px'
                  }}>
                    Your top posts in 2024
                  </Typography>
                  
                  {renderHeatmap('topPosts')}

                  <Box style={{margin: '0px 20px 20px'}}>
                    {linkedinData.stats.topPosts?.slice(0, 3).map((post, index) => (
                      <Box 
                        key={index} 
                        className={classes.topPostCard}
                        onClick={() => setSelectedPost(post)}
                        onMouseEnter={(e) => {
                          const tooltipContent = `
                            <div style="padding: 10px; max-width: 300px;">
                              <b>${dayjs(post.postedDate).format('MMM D, YYYY')}</b>
                              <br/>⭐ ${post.engagement} engagement
                              <br/>"${post.text?.substring(0, 100)}..."
                              <br/><small>Click to view full post</small>
                            </div>
                          `;
                          const tooltip = createTooltip(tooltipContent);
                          document.body.appendChild(tooltip);
                          updateTooltipPosition(tooltip, e);
                        }}
                        onMouseMove={(e) => updateTooltipPosition(document.getElementById('ch-tooltip2'), e)}
                        onMouseLeave={removeTooltip}
                        style={{ cursor: 'pointer' }}
                      >
                        <Box className={classes.medalEmoji}>
                          {index === 0 ? '🥇' : index === 1 ? '🥈' : '🥉'}
                        </Box>
                        <Box>
                        <Typography className={classes.topPostText}>
                          {post?.summary?.text} {post?.summary?.emoji}
                        </Typography>
                        <Typography className={classes.topPostSubtitle}>
                        "{post.text?.substring(0, 60)}..."
                        </Typography>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </CardWrapper>

              {/* Fourth Card - LinkedIn Buddies */}
              <CardWrapper 
                cardRef={cardRefs.buddies} 
                downloadFileName={IMAGE_FILENAMES[3]}
                onDownload={downloadCardAsImage}
                footerRightText={linkedinData?.profile?.full_name || auth.user?.name || ''}
              >
                <Box className={classes.cardContent}>
                  <Typography align="center" style={{ 
                    fontSize: isMobile? '24px' : '32px', 
                    fontWeight: 'bold',
                  }}>
                    Your linkedin buddies
                  </Typography>

                  <Typography align="center" style={{ 
                    fontSize: '16px',
                    color: '#fff',
                    margin: '20px 0'
                  }}>
                    You owe all of them a drink!
                  </Typography>

                  <Box style={{
                    margin: `0px 20px 20px`,
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)', // Responsive grid
                    gap: isMobile? '4px' : '8px'
                  }}>
                    {(linkedinData?.stats?.topCommenters || []).slice(0, 8).map((buddy, index) => (
                      <MUITooltip title={buddy?.profile?.title || ''}>
                        <Box 
                          key={index} 
                          className={classes.buddyCard}
                          onClick={() => buddy?.profile?.linkedinUrl && window.open(buddy.profile.linkedinUrl, '_blank')}
                          style={{ cursor: buddy?.profile?.linkedinUrl ? 'pointer' : 'default' }}
                        >
                          <Avatar className={classes.buddyAvatar} src={`https://api.dicebear.com/9.x/fun-emoji/jpg?seed=${buddy?.profile?.name}&radius=10&backgroundColor=059ff2,71cf62,d84be5,fcbc34&backgroundRotation=360,110,0&eyes=closed,closed2,cute,glasses,plain,sad,shades,sleepClose,stars,wink,wink2&mouth=cute,lilSmile,smileLol,smileTeeth,wideSmile`} alt={buddy.profile.name} />
                          <Typography style={{ color: '#fff', flex: 1, fontSize: isMobile? '12px' : '18px' }}>
                            {buddy?.profile?.name?.length > 20 ? `${buddy?.profile?.name.substring(0, 20)}...` : buddy?.profile?.name}
                          </Typography>
                        </Box>
                      </MUITooltip>
                    ))}
                  </Box>
                </Box>
              </CardWrapper>

              {/* Fifth Card - Creator Persona */}
              <CardWrapper 
                cardRef={cardRefs.persona} 
                downloadFileName={IMAGE_FILENAMES[4]}
                onDownload={downloadCardAsImage}
                footerRightText={linkedinData?.profile?.full_name || auth.user?.name || ''}
              >
                <Box className={classes.cardContent}>

                  <Typography align="center" style={{ 
                    fontSize: isMobile? '36px' : '50px', 
                    color: '#fff',
                    fontWeight: 'bold',
                    margin: '10px 0'
                  }}>
                    {linkedinData?.creatorPersona?.emoji}
                  </Typography>

                  <Typography align="center" style={{ 
                    fontSize: isMobile? '16px' : '24px', 
                    color: '#fff',
                    fontWeight: 'bold',
                  }}>
                    Your 2024 content persona is
                  </Typography>

                  <Typography align="center" style={{ 
                    fontSize: isMobile? '28px' : '40px', 
                    fontWeight: 'bold',
                    margin: '0px',
                  }}>
                    {linkedinData?.creatorPersona?.title}
                  </Typography>

                  <Typography className={classes.personaDescription}>
                    {linkedinData?.creatorPersona?.description}
                  </Typography>
                  <Typography className={classes.personaDescription}>
                    Other creators like you: <br/>
                    <strong> {linkedinData?.creatorPersona?.creators.join(', ')}</strong>
                  </Typography>
                </Box>
              </CardWrapper>

              {/* Timeline Card */}
              <CardWrapper 
                cardRef={cardRefs.timeline} 
                downloadFileName={IMAGE_FILENAMES[5]}
                onDownload={downloadCardAsImage}
                hideFooterImage={true}
                footerRightText={linkedinData?.profile?.full_name || auth.user?.name || ''}
              >
                <Box className={classes.cardContent}>
                  <Typography align="center" style={{ 
                    fontSize: isMobile? '16px' : '24px', 
                    fontWeight: 'bold',
                    marginTop: '30px',
                    marginBottom: '0px',
                    color: '#fff',
                    padding: '0 20px',
                  }}>
                    You've made a lot of memories this year
                  </Typography>

                  <Typography align="center" style={{ 
                    fontSize: isMobile? '12px' : '16px',
                    color: '#fff',
                    padding: '0 20px',

                  }}>
                    Here's a recap with your top posts each month
                  </Typography>

                  <Box style={{ 
                    margin: '10px 40px',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    gap: '8px',
                  }}>
                    {/* First Column */}
                    <Box style={{ 
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '8px',
                    }}>
                      {['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'].map((month, index) => (
                        <MonthlyTimelineItem 
                          key={index}
                          month={month}
                          summary={linkedinData?.monthlySummaries?.[index]}
                        />
                      ))}
                    </Box>

                    {/* Second Column */}
                    <Box style={{ 
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '8px',
                    }}>
                      {['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].map((month, index) => (
                        <MonthlyTimelineItem 
                          key={index + 6}
                          month={month}
                          summary={linkedinData?.monthlySummaries?.[index + 6]}
                        />
                      ))}
                    </Box>
                  </Box>
                </Box>
              </CardWrapper>

              {/* Lessons Card */}
              <CardWrapper 
                cardRef={cardRefs.lessons} 
                downloadFileName={IMAGE_FILENAMES[6]}
                onDownload={downloadCardAsImage}
                hideFooterImage={true}
                footerRightText={linkedinData?.profile?.full_name || auth.user?.name || ''}
              >
                <Box className={classes.cardContent}>
                  <Typography align="center" style={{ 
                    fontSize: isMobile? '16px' : '32px', 
                    fontWeight: 'bold',
                    marginTop: '40px',
                    color: '#fff',
                    padding: '0 20px',
                  }}>
                    Your top 10 lessons from 2024
                  </Typography>
                  <Typography align="center" style={{ 
                    fontSize: isMobile? '12px' : '16px',
                    color: '#fff',
                    padding: '0 20px',
                    marginBottom: '10px',
                  }}>
                    Save this for later 😉
                  </Typography>

                  <Box style={{ 
                    margin: isMobile? '5px' : '20px',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)', // Responsive grid
                    gap: isMobile? '8px' : '12px',
                  }}>
                    {(linkedinData?.lessons || []).map((lesson, index) => (
                      <Box 
                        key={index}
                        className={classes.statCard}
                        style={{ 
                          marginBottom: '0',
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px',
                          padding: isMobile? '6px' : '10px',
                          textAlign: 'left',
                          height: '100%',
                        }}
                      >
                        <Typography style={{ 
                          fontSize: '20px',
                          lineHeight: 1,
                          marginTop: '2px'
                        }}>
                          {lesson.emoji}
                        </Typography>
                        <Typography style={{ 
                          color: '#fff',
                          fontSize: isMobile? '8px' : '11px',
                          lineHeight: 1.4,
                        }}>
                          {lesson.text}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </CardWrapper>

              {/* Seventh Card - Cleve's Letter */}
              <CardWrapper 
                cardRef={cardRefs.letter} 
                downloadFileName={IMAGE_FILENAMES[7]}
                onDownload={downloadCardAsImage}
                hideFooterImage={true}
                footerRightText={linkedinData?.profile?.full_name || auth.user?.name || ''}
              >
                <Box className={classes.cardContent}>
                  <Typography align="center" style={{ 
                    fontSize: isMobile? '20px' : '32px', 
                    fontWeight: 'bold',
                    marginTop: '30px',
                    color: '#fff',
                    padding: '0 20px',
                  }}>
                    A letter for you
                  </Typography>

                  <Box style={{
                    margin: isMobile? '10px 10px' : '40px 20px',
                    padding: '24px',
                    backgroundColor: '#FFF5E0',
                    borderRadius: '12px',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  }}>
                    <Typography style={{
                      color: '#000',
                      whiteSpace: 'pre-wrap',
                      fontSize: isMobile? '8px' : '12px',
                      lineHeight: 1.5,
                    }}>
                      {linkedinData?.letter}
                    </Typography>
                  </Box>
                </Box>
              </CardWrapper>
              
              <PostingPatternStreaksCard data={linkedinData} />
              <PostingPatternHourlyCard data={linkedinData} />
              <PostingPatternWeeklyCard data={linkedinData} />

              <CardWrapper 
                cardRef={cardRefs.final} 
                downloadFileName={IMAGE_FILENAMES[11]}
                onDownload={downloadCardAsImage}
                footerRightText={linkedinData?.profile?.full_name || auth.user?.name || ''}
              >
                <Box className={classes.cardContent}>
                  <Typography align="center" style={{ 
                    fontSize: isMobile? '20px' : '40px', 
                    fontWeight: 'bold',
                    marginBottom: '16px',
                    marginTop: '-40px',
                    color: '#fff',
                    lineHeight: 1.2,
                  }}>
                    Seems like you've had
                    <br />
                    an incredible year :)
                  </Typography>

                  <Typography align="center" style={{ 
                    fontSize: isMobile? '14px' : '20px',
                    color: '#fff',
                    marginBottom: '40px',
                  }}>
                    Hope you enjoyed this recap!
                  </Typography>
                  {renderHeatmap('final')}

                  <Box style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '8px',
                  }}>
                    <Typography style={{ color: '#fff', fontSize: isMobile? '12px' : '16px' }}>
                      Get yours:
                    </Typography>
                    <Typography style={{ 
                      color: '#fff', 
                      fontSize: isMobile? '14px' : '20px',
                      fontWeight: 'bold',
                    }}>
                      cleve.ai/unwrapped
                    </Typography>
                  </Box>
                </Box>
              </CardWrapper>
              {/* <CoffeeWidget /> */}
        </Box>
        {(isPreprocessing || !processedContent.pdf) && 
      <Box>
        <Typography
          variant="body2"
          align="center"
          style={{
            // color: '#FFFFFF',
            margin: isMobile ? '15px 20px 10px' : '15px 300px 10px',
            opacity: 0.8
          }}
        >
          In ~30 seconds your shareable PDF will finish processing and the experience will be smoother.
        </Typography>
      </Box>
      }
        <Box className={classes.sharingSteps} style={{ marginTop: '30px' }}>
        <Typography 
          variant="h6" 
          style={{ 
            color: '#fff', 
            marginBottom: theme.spacing(2),
            textAlign: 'left'
          }}
        >
          Be the first to share it with your friends:
        </Typography>
        {SHARING_STEPS.map((step) => (
          <Box key={step.step} className={classes.stepContainer}>
            <Typography className={classes.stepText}>
              {step.step}. {step.text}
            </Typography>
            {step.action && (
              <Button
                variant="contained"
                className={classes.stepButton}
                startIcon={downloadingAll.pdf ? <CircularProgress size={20} color="inherit" /> : step.icon}
                onClick={saveAllAsPDF}
                disabled={isPreprocessing || downloadingAll.pdf || downloadingAll.images || !processedContent.pdf}
              >
                {isPreprocessing || !processedContent.pdf ? 'Processing PDF...' : 
                  (downloadingAll.pdf ? 'Downloading...' : step.action)}
              </Button>
            )}
            {step.buttons && (
              <Box className={classes.stepButtons}>
                {step.buttons.map((button, index) => (
                  <Button
                    key={index}
                    variant="contained"
                    className={button.variant === 'linkedin' ? classes.linkedinButton : classes.defaultButton}
                    startIcon={button.variant === 'linkedin' ? 
                      <LinkedIn /> : 
                      (copiedState ? <CheckCircle /> : <PostAdd />)}
                    onClick={button.variant === 'linkedin' ? 
                      () => {
                        const caption = generateWrappedCaption(linkedinData);
                        window.open(getShareUrl(caption), '_blank');
                      } : 
                      handleCopyCaption}
                  >
                    {button.variant === 'linkedin' ? 
                      button.label : 
                      (copiedState ? "Copied!" : button.label)}
                  </Button>
                ))}
              </Box>
            )}
          </Box>
        ))}

        
      </Box>
      <Box className={classes.otherSharingOptions}>
        <Typography variant="h6" className={classes.otherSharingTitle}>
          Other sharing options
        </Typography>
        <Box className={classes.otherSharingButtons}>
          <Button
            variant="contained"
            color="secondary"
            startIcon={downloadingAll.images ? <CircularProgress size={20} color="inherit" /> : <GetApp />}
            onClick={downloadAllImages}
            disabled={isPreprocessing || downloadingAll.images || Object.keys(processedContent.images).length === 0}
          >
            {isPreprocessing || processedContent.images.length === 0 ? 'Processing images...' : 
              (downloadingAll.images ? 'Downloading...' : 'Save (images)')}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            startIcon={copiedLinkState ? <CheckCircle /> : <Link />}
            onClick={() => {
              const publicIdentifier = linkedinUrl.split('/in/')[1]?.replace(/\/$/, '') || '';
              const shareUrl = `https://notes.cleve.ai/unwrapped/${publicIdentifier}`;
              navigator.clipboard.writeText(shareUrl);
              setCopiedLinkState(true);
              setTimeout(() => {
                setCopiedLinkState(false);
              }, 2000); // Reset after 2 seconds
              // toast.success('Link copied to clipboard!');
            }}
          >
            {copiedLinkState ? "Copied!" : "Copy link"}
          </Button>
        </Box>
      </Box>

      {/* Share on social media */}
      {/* <Box className={classes.otherSharingOptions} style={{marginTop: '50px'}}>
        <Typography variant="h6" className={classes.otherSharingTitle} style={{marginBottom: '20px', }}>
          Recommend this tool to your friends/boss/colleagues
        </Typography>
        <Box className={classes.otherSharingButtons}>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<WhatsApp />}
            onClick={() => window.open('https://api.whatsapp.com/send?phone=&text=I%20found%20this%20AI%20tool%20that%20studies%20my%20entire%20year%27s%20worth%20of%20LinkedIn%20posts%20and%20gives%20a%20summary%20of%20key%20insights.%20Thought%20you%20might%20find%20it%20interesting%20-%20It%27s%20fast%2C%20easy%20and%20free%3A%20www.cleve.ai%2Funwrapped', '_blank')}
          >
            Whatsapp
          </Button>

          <Button
            variant="contained" 
            color="secondary"
            startIcon={<Facebook />}
            onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent('https://www.cleve.ai/unwrapped')}&quote=${encodeURIComponent('I found this AI tool that studies my entire year\'s worth of LinkedIn posts and gives a summary of key insights. Thought you might find it interesting - It\'s fast, easy and free!')}`, '_blank')}
          >
            Facebook
          </Button>

          <Button
            variant="contained"
            color="secondary" 
            startIcon={<Twitter />}
            onClick={() => window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent('I found this AI tool that studies my entire year\'s worth of LinkedIn posts and gives a summary of key insights. Thought you might find it interesting - It\'s fast, easy and free!')} ${encodeURIComponent('https://www.cleve.ai/unwrapped')}`, '_blank')}
          >
            X
          </Button>
        </Box>
      </Box> */}

      {/* Add CTA before FAQ section */}
      <UnwrappedCTA 
        linkedinUrl={linkedinUrl}
        email={email}
        firstName={linkedinData?.profile?.first_name || linkedinData?.profile?.full_name || ''}
        className={classes.ctaSection}
        linkedinData={linkedinData}
        timeLeft={timeLeft}
        selectedVariation={selectedCTAVariation}
      />

      {/* FAQ Section */}
      <Box style={{ padding: '20px', maxWidth: '800px', margin: '40px auto' }}>
        <Typography variant="h1" style={{
          fontSize: isMobile ? '32px' : '48px',
          fontWeight: 'bold',
          marginBottom: '40px',
          textAlign: 'center'
        }}>
          FAQ
        </Typography>
        {FAQ_DATA.map((faq, index) => (
          <Accordion 
            key={index}
            style={{
              backgroundColor: '#2a1a4d',
              color: '#FFFFFF',
              marginBottom: '10px',
              borderRadius: '8px',
              '&:before': {
                display: 'none',
              }
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore style={{color: '#FFFFFF'}} />}
              style={{
                borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
              }}
            >
              <Typography 
                style={{ 
                  fontWeight: 'bold',
                  fontSize: isMobile? '14px' : '20px'
                }}
              >
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography 
                style={{ 
                  whiteSpace: 'pre-line',
                  fontSize: isMobile? '12px' : '16px',
                  lineHeight: 1.6
                }}
              >
                {faq.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
      </>
      }

        
        
      </Box>
      {error && (
        <Box mb={4} p={2} bgcolor="error.main" color="error.contrastText" borderRadius={1}>
          <Typography>{error}</Typography>
            </Box>
      )}
      
      {auth.user && (
        <NoteNav />
      )}
      {renderPostDialog()}
    </Box>
  );
};

export default LinkedInWrapped;